import Cookies from "js-cookie";


type APIParams = {
  route?: any;
  data?: any;
  id?: string | number;
  variableString?: string;

};
export default class GeneralAPI {
  URL: string = process.env.REACT_APP_API_BASE_URL || "";
  token: string | undefined = Cookies.get("facebloom-dashboard");


// Method to construct the base API URL based on the user role
getBaseURL(): string {
  const userRole = Cookies.get("facebloom-role");
  return userRole === 'Influencer' ? `${this.URL}/api/dashboard-influencer` : `${this.URL}/api/dashboard`;
}

  private async handleApiErrors(response: Response): Promise<Response> {
    if (!response.ok) {
      const errorData = await response.json();
      if (
        response.status === 403 &&
        ["User is not approved"].includes(
          errorData.message
        )
      ) {
        // removeTokens();
        window.location.href = "/";
      } else {
        throw new Error(
          errorData.message || "User has no access to this route."
        );
      }
    }
    return response;
  }

  private async sendRequest(url: string, options: RequestInit): Promise<any> {
    const response = await fetch(url, options);
    await this.handleApiErrors(response);
    return response.json();
  }

  public async create(params: APIParams): Promise<any> {
    const options: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${Cookies.get("facebloom-dashboard")}`,
      },
      body: JSON.stringify(params.data),
    };
    return this.sendRequest(
      `${this.getBaseURL()}/${params.route}`,
      options
    );
  }

  public async edit(params: APIParams): Promise<any> {
    const options: RequestInit = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${Cookies.get("facebloom-dashboard")}`,
      },
      body: JSON.stringify(params.data),
    };
    return this.sendRequest(
      `${this.getBaseURL()}/${params.route}`,
      options
    );
  }

  public async view(route: string, id: string): Promise<any> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${Cookies.get("facebloom-dashboard")}`,
      },
    };
    return this.sendRequest(
      `${this.getBaseURL()}/${route}/${id}`,
      options
    );
  }

  public async delete(params: APIParams): Promise<any> {
    const options: RequestInit = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${Cookies.get("facebloom-dashboard")}`,
      },
    };
    return this.sendRequest(
      `${this.getBaseURL()}/${params.route}/${params.id}`,
      options
    );
  }

  public async view_all(
    route: string,
    page: number,
    limit: number
  ): Promise<any> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${Cookies.get("facebloom-dashboard")}`,
      },
    };
    return this.sendRequest(
      `${this.getBaseURL()}/${route}?page=${page}&limit=${limit}`,
      options
    );
  }

  public async view_get(route: string, variableString?: string): Promise<any> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${Cookies.get("facebloom-dashboard")}`,
      },
    };
    return this.sendRequest(
      `${this.getBaseURL()}/${route}${
        variableString ? "?" + variableString : ""
      }`,
      options
    );
  }

  public async view_with_id_or_Params(
    route: string,
    id: string,
    variableString?: string
  ): Promise<any> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${Cookies.get("facebloom-dashboard")}`,
      },
    };
    return this.sendRequest(
      `${this.getBaseURL()}/${route}/${id}${
        variableString ? "?" + variableString : ""
      }`,
      options
    );
  }

  public async view_without_id(route: string): Promise<any> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${Cookies.get("facebloom-dashboard")}`,
      },
    };
    return this.sendRequest(`${this.getBaseURL()}/${route}`, options);
  }
}
