import React, { useRef, useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";

type ImageUploadProps = {
  name: string; // Name to register with react-hook-form
  setValue: any;
  watch: any;
  formEdit?: boolean;
  defaultImage?: string;
  errors?: any;
  clearErrors?: any;
};

const ImageUpload: React.FC<ImageUploadProps> = ({
  name,
  setValue,
  watch,
  formEdit,
  defaultImage,
  errors,
  clearErrors,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const imagePreviewRef = useRef<HTMLImageElement>(null);
  // const { setValue, watch } = useFormContext();

  // This will watch the specific form field for changes
  // const fileValue = watch(name);

  const triggerFileSelect = () => {
    fileInputRef.current?.click();
  };

  const fileValue = watch(name);

  useEffect(() => {
    if (formEdit && defaultImage && !fileValue) {
      if (imagePreviewRef.current) {
        imagePreviewRef.current.src = defaultImage;
        imagePreviewRef.current.style.display = "block";
      }
    } else if (fileValue && fileValue.length > 0) {
      const file = typeof fileValue === 'string' ? fileValue : fileValue[0];
      if (typeof file === 'string') {
        if (imagePreviewRef.current) {
          imagePreviewRef.current.src = file;
          imagePreviewRef.current.style.display = "block";
        }
      } else if (typeof file === 'object' && file instanceof Blob) {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (imagePreviewRef.current) {
            imagePreviewRef.current.src = reader.result as string;
            imagePreviewRef.current.style.display = "block";
          }
        };
        reader.readAsDataURL(file);
      }
    }
  }, [fileValue, formEdit, defaultImage]);



  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    setValue(name, files);
    clearErrors(name)
  };
// debugger
  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleChange}
        style={{ display: "none" }}
        accept=".jpg, .jpeg, .png, .gif, .bmp, .tiff, .tif, .webp, .svg, .ico"
      />
      <div
        className="p-2 w-fit mb-4 oulineGradiant cursor-pointer"
        onClick={triggerFileSelect}
      >
        <div className=" w-[203px] min-h-[174px] flex items-center justify-center relative">
        {(formEdit && (defaultImage !== '' || defaultImage !== null)) && (
          <img
            src={"/assets/images/basicIcons/imageEditPancel.svg"}
            alt="Edit"
            className="absolute right-0 bg-white top-0 p-2 rounded-lg"
            style={{ border: "0.5px solid #3C1B6D" }}
          />
          )}
          {(defaultImage === '' || defaultImage === null) ? (
            <span className="w-[190px] h-[170px] flex items-center justify-center rounded-lg bg-[#E1F0FF] font-roboto text-base leading-tight tracking-none text-left">
            + Upload Image
          </span>
          ):(

            <img
              ref={imagePreviewRef}
              alt="Upload Preview"
              className="max-w-[190px] h-auto w-auto mx-auto rounded-lg"
              style={{ display: formEdit && defaultImage ? "block" : "none" }}
            />
          )}
          {(!fileValue || fileValue.length === 0) && !formEdit && (
            <span className="w-[190px] h-[170px] flex items-center justify-center rounded-lg bg-[#E1F0FF] font-roboto text-base leading-tight tracking-none text-left">
              + Upload Image
            </span>
          )}
        </div>
        {/* {(!fileValue || fileValue.length === 0) && (
          <span className="w-[190px] h-[170px] flex items-center justify-center rounded-lg bg-[#E1F0FF] font-roboto text-base leading-tight tracking-none text-left">
            + Upload Image
          </span>
        )} */}
      </div>
      {errors?.display_picture && <p className="mt-1 text-sm text-red-500">{errors?.display_picture.message}</p>}
      {errors?.profile_image && <p className="mt-1 text-sm text-red-500">{errors?.profile_image.message}</p>}
    </>
  );
};

export default ImageUpload;
