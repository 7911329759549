import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import CelebFieldSection from "../../components/CelebManagmet/basicForm";
import { useImageUpload } from "../../services/Api/general";
import ImageUpload from "../../components/Common/ImageUpload";
import Cookies from "js-cookie";


type FormData = {
  name: string;
  display_picture: FileList;
  profession: string;
  min_score: number;
  max_score: number;
  gender: any;
};

const genderOptions = [
  { value: "female", label: "Female" },
  { value: "male", label: "Male" },
  { value: "other", label: "Other" },
];

export default function AddCelebrities() {
  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();
  const navigate = useNavigate();
  const { mutateAsync: uploadImage } = useImageUpload();

  const onSubmit = async (formData: FormData) => {
    if (!formData?.display_picture?.length) {
      setError("display_picture", {
        type: "manual",
        message: "Please select an image to upload.",
      });
      return;
    }

    const file = formData.display_picture[0];
    try {
      const uploadedImageUrl = await uploadImage({
        file,
        route: "celebrity/getPreSignedUrl",
      });

      const submissionData = {
        ...formData,
        display_picture: uploadedImageUrl, // Assuming the API returns the URL of the uploaded image
        min_score: parseFloat(formData.min_score.toString()),
        max_score: parseFloat(formData.max_score.toString()),
        gender: formData?.gender?.value,
      };
      debugger;
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/celebrity/`,
        submissionData,
        {
          headers: {
            Authorization: `${Cookies.get("facebloom-dashboard")}`
          }
        }
      );
      navigate("/celebrities");
    } catch (error) {
      console.error("Failed to submit form", error);
    }
  };

  return (
    <>
      <div className="p-4 bg-transparent">
        <GenericRowComponent heading="Add Celebrity" showBackButton />
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <div className="mb-4 mt-2">
            <ImageUpload
              setValue={setValue}
              watch={watch}
              name="display_picture"
              errors={errors}
              clearErrors={clearErrors}
            />
          </div>

          <CelebFieldSection
            register={register}
            errors={errors}
            control={control}
            genderOptions={genderOptions}
          />

          <button
            type="submit"
            className={`p-2 mt-6 min-h-10 text-black inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md ${
              isSubmitting ? "bg-blue-300" : "bg-blue-600 hover:bg-blue-700"
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
            disabled={isSubmitting}
            style={{
              background:
                "linear-gradient(268.95deg, #3FDFA1 5.53%, #FEE591 91.7%)",
            }}
          >
            {isSubmitting ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Processing...
              </>
            ) : (
              "Add Celebrity"
            )}
          </button>
        </form>
      </div>
    </>
  );
}
