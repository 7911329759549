import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { getIcon } from "../icons/get-icon";
import * as sidebarIcons from "../icons/sidebar/index";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/userSlice";
import { removeTokens } from "../../services/tokenHandler";
import { RootState } from "../../redux/store";
import Modal from "../Common/Modal";



const Sidebar: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  let navigation = [
    // { name: "Dashboard", href: "/dashboard", icon: "UserIcon", exact: true },
    {
      name: "User Management",
      href: "/user_management",
      icon: "UserIcon",
      exact: true,
    },
    { name: "Scan Results", href: "/result", icon: "BoxIcon", exact: false },
    { name: "Facematch Results", href: "/face_match_result", icon: "FaceMatchIcon", exact: false},
    {
      name: "Recommendations",
      href: "/recommendations",
      icon: "PersonsIcon",
      exact: false,
    },
    {
      name: "Celebrities",
      href: "/celebrities",
      icon: "FolderIcon",
      exact: false,
    },
  
    {
      name: "Coupon Code",
      href: "/coupon-code",
      icon: "PersonsIcon",
      exact: false,
    },
    {
      name: "Survey",
      href: "/survey",
      icon: "ShopingBagIcon",
      exact: false,
    },
    {
      name: "Influencer",
      href: "/influencer",
      icon: "PersonsIcon",
      exact: false,
    },
    // Add more navigation links here
  ];
  
  // Adding role specific navigation items
  if (user.data.role === "Influencer") {
    navigation = [
      {
        name: "Influencer",
        href: "/influencers/influencer",
        icon: "PersonsIcon",
        exact: false,
      },
      {
        name: "My Profile",
        href: "/influencers/my_profile",
        icon: "UserIcon",
        exact: false,
      },
    ];
  }

  const handleLogout = () => {
    console.log('hello');
    setShowLogoutModal(true);
  };

  const handleConfirmLogout = () => {
    dispatch(logoutUser());
    removeTokens();
    setShowLogoutModal(false);
  };

  return (
    <>
    <div className="h-screen overflow-x-none overflow-y-auto w-auto bg-[#FFFFFF1A] text-white flex flex-col justify-between">
      <div className="w-full">
        <div
          className="sticky "
          style={{
            borderBottom: "1px solid",
            borderBottomColor:
              "radial-gradient(56.67% 4474925.33% at 43.33% 50.06%, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%)",
          }}
        >
          <div className=" py-3">
            {/* LOGO */}
            <img
              src="/assets/images/logo.png"
              alt="logo"
              className="w-auto h-[90px] mx-auto"
            />
          </div>
        </div>
        <nav className=" mt-6 overflow-auto">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={({ isActive }) =>
                `flex items-center mx-3 rounded-md px-2 py-2 my-2 transition-colors duration-200 
              justify-start 
              text-md bg-transparent ${
                isActive ? "active text-black" : "text-white"
              }`
              }
              style={({ isActive }) => ({
                background: isActive
                  ? "linear-gradient(268.95deg, #3FDFA1 5.53%, #FEE591 91.7%)"
                  : "transparent",
              })}
              end={item.exact}
            >
              <span
                className={`inline-block align-middle material-icons`}
              >
                {/* {item.icon} */}
                {getIcon({
                  iconList: sidebarIcons,
                  iconName: item.icon,
                  className: "w-5 h-5 me-4",
                  // iconcolor: isActive ? 'black' :"#B8C0CC",
                  iconcolor: "#B8C0CC",
                })}
              </span>
              <span className="align-middle font-Urbanist text-base font-semibold leading-5 tracking-tight">
                {item.name}
              </span>
            </NavLink>
          ))}
        </nav>
      </div>
      <div className="mb-4">
       
        <button
          onClick={() => handleLogout()} // Add this onClick handler
          className="flex items-center px-3 py-2 my-2 mx-3  transition-colors duration-200 
          justify-start text-white hover:text-red-400 text-md border-0 hover:border-0 hover:outline-none w-full"
        >
          <span className="inline-block w-6 mr-3 align-middle material-icons">
            {getIcon({
              iconList: sidebarIcons,
              iconName: "LogOutIcon",
              className: "w-5 h-5 me-4",
              // iconcolor: (result || isLinkActive({ href })) ? "#282828" : "#757575",
              iconcolor: "#B8C0CC",
            })}
          </span>
          <span className="align-middle">Logout</span>
        </button>
      </div>
    </div>
    {showLogoutModal && (
      <>
        <Modal
          isOpen={showLogoutModal}
          title="Are you sure you want to Log Out?"
          // message="You won't be able to revert this!"
          onConfirm={handleConfirmLogout}
          onCancel={() => setShowLogoutModal(false)}
        />
        </>
      )}
    </>
  );
};

export default Sidebar;
