export const SubscriptionIcon = (props: { iconcolor: string }) => (
  <>
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5625 20.6562H2.5C1.41437 20.6562 0.53125 19.7731 0.53125 18.6875V4.6875C0.53125 3.60187 1.41437 2.71875 2.5 2.71875H5.5625C5.92502 2.71875 6.21875 3.01248 6.21875 3.375C6.21875 3.73752 5.92502 4.03125 5.5625 4.03125H2.5C2.13812 4.03125 1.84375 4.32562 1.84375 4.6875V18.6875C1.84375 19.0494 2.13812 19.3438 2.5 19.3438H5.5625C5.92502 19.3438 6.21875 19.6375 6.21875 20C6.21875 20.3625 5.92502 20.6562 5.5625 20.6562Z"
        fill={props.iconcolor}
      />
      <path
        d="M22.1875 15.6688C21.825 15.6688 21.5312 15.3751 21.5312 15.0125V4.6875C21.5312 4.32562 21.2369 4.03125 20.875 4.03125H17.8125C17.45 4.03125 17.1562 3.73752 17.1562 3.375C17.1562 3.01248 17.45 2.71875 17.8125 2.71875H20.875C21.9606 2.71875 22.8438 3.60187 22.8438 4.6875V15.0125C22.8438 15.3751 22.55 15.6688 22.1875 15.6688Z"
        fill={props.iconcolor}
      />
      <path
        d="M12.125 22.8438H6.875C5.78937 22.8438 4.90625 21.9606 4.90625 20.875V2.5C4.90625 1.41437 5.78937 0.53125 6.875 0.53125H16.5C17.5856 0.53125 18.4688 1.41437 18.4688 2.5V14.6143C18.4688 14.9769 18.175 15.2706 17.8125 15.2706C17.45 15.2706 17.1562 14.9769 17.1562 14.6143V2.5C17.1562 2.13812 16.8619 1.84375 16.5 1.84375H6.875C6.51312 1.84375 6.21875 2.13812 6.21875 2.5V20.875C6.21875 21.2369 6.51312 21.5312 6.875 21.5312H12.125C12.4875 21.5312 12.7812 21.825 12.7812 22.1875C12.7812 22.55 12.4875 22.8438 12.125 22.8438Z"
        fill={props.iconcolor}
      />
      <path
        d="M14.75 9.2812H8.625C8.26248 9.2812 7.96875 8.98747 7.96875 8.62495V4.24995C7.96875 4.00129 8.1091 3.77421 8.33148 3.66291C8.55386 3.55204 8.82004 3.57575 9.01871 3.72486L10.3133 4.69599L11.2229 3.78596C11.4792 3.52961 11.8947 3.52982 12.1511 3.78574L13.0615 4.69578L14.3563 3.72486C14.5554 3.57575 14.8213 3.55225 15.0435 3.66291C15.2659 3.77421 15.4062 4.00129 15.4062 4.24995V8.62495C15.4062 8.98747 15.1125 9.2812 14.75 9.2812ZM9.28125 7.9687H14.0938V5.56223L13.3937 6.08732C13.1329 6.28321 12.7671 6.25736 12.536 6.02644L11.6873 5.17793L10.8392 6.02644C10.6078 6.25736 10.2423 6.28343 9.98129 6.08754L9.28125 5.56245L9.28125 7.9687Z"
        fill={props.iconcolor}
      />
      <path
        d="M11.6875 18.8355C11.008 18.8355 10.3889 18.4354 10.1468 17.8396C10.0103 17.504 10.1721 17.1212 10.5079 16.9847C10.8428 16.8477 11.2263 17.0095 11.3628 17.3457C11.4047 17.4485 11.5412 17.523 11.6875 17.523C11.9543 17.523 12.1797 17.3504 12.1797 17.1462V17.0956C12.1797 16.8866 11.7302 16.7371 11.5753 16.7091C10.9494 16.6049 9.88281 16.1325 9.88281 15.0294V14.9788C9.88281 14.0474 10.6924 13.2894 11.6875 13.2894C12.367 13.2894 12.9861 13.6895 13.2281 14.2853C13.3647 14.6209 13.2029 15.0038 12.8671 15.1403C12.5319 15.2768 12.1485 15.1153 12.0122 14.7792C11.9703 14.6765 11.8338 14.6019 11.6875 14.6019C11.4207 14.6019 11.1953 14.7745 11.1953 14.9788V15.0294C11.1953 15.2383 11.6448 15.3878 11.7996 15.4158C12.4256 15.5201 13.4922 15.9924 13.4922 17.0956V17.1462C13.4922 18.0776 12.6826 18.8355 11.6875 18.8355Z"
        fill={props.iconcolor}
      />
      <path
        d="M11.6875 14.602C11.325 14.602 11.0312 14.3082 11.0312 13.9457V13C11.0312 12.6375 11.325 12.3438 11.6875 12.3438C12.05 12.3438 12.3438 12.6375 12.3438 13V13.9457C12.3438 14.3082 12.05 14.602 11.6875 14.602Z"
        fill={props.iconcolor}
      />
      <path
        d="M11.6875 19.7813C11.325 19.7813 11.0312 19.4875 11.0312 19.125V18.1793C11.0312 17.8168 11.325 17.5231 11.6875 17.5231C12.05 17.5231 12.3438 17.8168 12.3438 18.1793V19.125C12.3438 19.4875 12.05 19.7813 11.6875 19.7813Z"
        fill={props.iconcolor}
      />
      <path
        d="M19.5625 25.4688C16.3058 25.4688 13.6562 22.8192 13.6562 19.5625C13.6562 16.3058 16.3058 13.6562 19.5625 13.6562C22.8192 13.6562 25.4688 16.3058 25.4688 19.5625C25.4688 22.8192 22.8192 25.4688 19.5625 25.4688ZM19.5625 14.9688C17.0296 14.9688 14.9688 17.0296 14.9688 19.5625C14.9688 22.0954 17.0296 24.1562 19.5625 24.1562C22.0954 24.1562 24.1562 22.0954 24.1562 19.5625C24.1562 17.0296 22.0954 14.9688 19.5625 14.9688Z"
        fill={props.iconcolor}
      />
      <path
        d="M18.7969 21.9689C18.6228 21.9689 18.4559 21.8997 18.3329 21.7767L16.3641 19.8079C16.1078 19.5518 16.1078 19.1361 16.3641 18.8799C16.6205 18.6236 17.0358 18.6236 17.2921 18.8799L18.7969 20.3845L21.8329 17.3483C22.0888 17.0919 22.5045 17.0919 22.7609 17.3483C23.0172 17.6044 23.0172 18.0199 22.7609 18.2762L19.2609 21.7767C19.1378 21.8997 18.971 21.9689 18.7969 21.9689Z"
        fill={props.iconcolor}
      />
    </svg>
  </>
);
