import React, { forwardRef, TextareaHTMLAttributes } from 'react';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  label?: string;
  note?: string;
  name: string;
  error?: string;
  defaultValue?: string;
  variant?: 'normal';
  shadow?: boolean;
  inputClassName?: string;
  disabled?: boolean;
  showLabel?: boolean;
  register: any;
  minHeight?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className,
      label,
      note,
      name,
      error,
      defaultValue,
      variant = 'normal',
      shadow = false,
      inputClassName,
      disabled,
      showLabel = true,
      minHeight = '180px',
      ...rest
    },
    ref
  ) => {
    const rootClassName = `px-4 flex items-center w-full rounded-lg appearance-none transition duration-300 ease-in-out text-heading focus:outline-none focus:ring-0 border border-border-base h-12 ${inputClassName || ''} `;

    return (
      <div className={className}>
        {showLabel && (
          <label htmlFor={name} className="text-xl block leading-6 font-semibold tracking-tight font-Urbanist text-white pb-3">
            {label}
          </label>
        )}
        <textarea
          id={name}
          name={name}
          ref={ref}
          defaultValue={defaultValue}
          className={`${rootClassName} ${disabled ? 'cursor-not-allowed select-none' : ''} text-white font-medium text-lg tracking-tight font-Urbanist py-2`}
          style={{
            minHeight: minHeight,
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06))',
            border: '1px solid #FFFFFF4D',
          }}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          disabled={disabled}
          aria-invalid={error ? 'true' : 'false'}
          {...rest}
        />
        {note && <p className="mt-2 text-xs text-body">{note}</p>}
        {error && <p className="my-2 text-xs text-red-500 text-start">{error}</p>}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';

export default TextArea;
