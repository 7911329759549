import React, { useEffect, useRef, useState } from "react";
import TableComponent from "../../components/Dashboard/TableComponent";
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import { DropdownActions } from "../../components/Dashboard/dropDownTable";
import { TableColumn } from "react-data-table-component";
import { useDelete, useViewGet } from "../../services/Api/general";
import Modal from "../../components/Common/Modal";
import Loading from "../../components/Loading";

// Assuming this is the structure of your influencer data
interface Influencer {
  id: number;
  name: string;
  email: string;
  index: number;
  totalFreeScans: number;
  influencers: Array<{
    profile_image: string;
    tier: number;
  }>;
}

const InfluencerPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortedArray, setSortedArray] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedInfluencerId, setSelectedInfluencerId] = useState<
    number | null
  >(null);

  const rowsPerPage = 10;
  const influPageRef = useRef<HTMLDivElement>(null);


  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const {
    isLoading,
    data: AllInfluencers,
    refetch,
  } = useViewGet("AllInfluencers", `influencer/getInfluencers?page=${currentPage}&limit=${rowsPerPage}&search=${searchQuery}`, "");
  
  const { mutateAsync: deleteInfluencer } = useDelete();


  useEffect(() => {
    if (AllInfluencers) {
      const initialSort = AllInfluencers?.data?.Influencers
        ?.map((user: any, index: number) => ({ ...user, index: (currentPage-1) * 10 + index + 1 }));

      setSortedArray(initialSort);
    }
  }, [AllInfluencers]);

  useEffect(() => {
    refetch();
    if (influPageRef.current) {
      influPageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [searchQuery, currentPage]);

  const handleSearch = (value: string) => {
    setCurrentPage(1)
    setSearchQuery(value)
  }

  const handleDeleteInfluencer = async () => {
    if (selectedInfluencerId !== null) {
      try {
        const response = await deleteInfluencer({
          route: "influencer/deleteInfluencer",
          id: selectedInfluencerId,
        });

        if (response.error === false) {
          refetch();
        } else if (response.error) {
          console.error("Error posting data:", response);
        }
      } catch (error) {
        console.error("Error posting data:", error);
      }
      setShowDeleteModal(false);
      setSelectedInfluencerId(null);
    }
  };

  const confirmDelete = (userId: number) => {
    setSelectedInfluencerId(userId);
    setShowDeleteModal(true);
  };

  const columns: TableColumn<Influencer>[] = [
    {
      name: "No#",
      selector: (row) => row.index.toString(),
      sortable: true,
      width: "10%",
    },
    {
      name: "Name",
      width: "25%",
      cell: (row) => (
        <div className="flex items-center">
          <img
            src={
              row.influencers[0]?.profile_image ||
              "/assets/images/user-avatar.png"
            }
            alt={row.name}
            style={{ width: 30, height: 30, borderRadius: "50%" }}
          />
          <span className="ml-2">{row.name}</span>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "20%",
    },
    {
      name: "Tier",
      cell: (row) => (
        <div
          className={`flex flex-row justify-center items-center px-3 py-1 capitalize text-white font-Urbanist text-base font-normal leading-[18px] rounded-lg bg-[rgba(52,176,255,0.6)]`}
        >
          Tier {row.influencers[0]?.tier}
        </div>
      ),
      sortable: true,
      width: "15%",
    },

    {
      name: "Total Scans",
      cell: (row) => (
        <div
          className={`flex flex-row justify-center items-center px-3 py-1 capitalize text-white font-Urbanist text-base font-normal leading-[18px] rounded-lg `}
        >
          {row?.totalFreeScans}
        </div>
      ),
      sortable: true,
      width: "15%",
    },
    {
      name: "Action",
      width: "20%",
      cell: (row, rowIndex) => (
        <DropdownActions
          row={row}
          rowIndex={rowIndex}
          totalRows={10}
          actions={"view,edit,delete"}
          onConfirmDelete={confirmDelete}
        />
      ),
    },
  ];

  return (
    <div className="p-4 bg-transparent" ref={influPageRef}>
      <GenericRowComponent
        heading="Influencer Management"
        showInput
        buttonLabel="+ Add New"
        buttonLink="add"
        onInputChange={handleSearch}
      />
      <div className="w-full h-full">
        {isLoading ? (
          <div className=" absolute inset-0 flex items-center justify-center">
          <Loading />
          </div>
        ) : (
          <TableComponent
            columns={columns}
            data={sortedArray}
            totalRows={AllInfluencers?.data?.total}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            progressPending={isLoading}
          />
        )}
      </div>
      {showDeleteModal && selectedInfluencerId && (
        <>
          <Modal
            isOpen={showDeleteModal ? true : false}
            title="Are you sure that you want to delete?"
            // message="You won't be able to revert this!"
            onConfirm={handleDeleteInfluencer}
            onCancel={() => setShowDeleteModal(false)}
          />
        </>
      )}
    </div>
  );
};

export default InfluencerPage;
