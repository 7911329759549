import React from "react";
interface ResultAnalysisSectionProps {
  cheekboneShape: string;
  chinShape: string;
  eyeColor: string;
  eyeShape: string;
  faceShape: string;
  jawAngularity: string;
  lipShape: string;
  race: string;
  age: number;
  hairType: string;
  country: string;
}

// Define the props based on the interface
const ResultAnalysisSection: React.FC<ResultAnalysisSectionProps> = ({
  cheekboneShape,
  chinShape,
  eyeColor,
  eyeShape,
  faceShape,
  jawAngularity,
  lipShape,
  race,
  age,
  hairType,
  country,
}) => {
  return (
    <div className="my-3">
      {/* <div className="mt-8 flex flex-wrap justify-start gap-5 gap-y-4"> */}
      <div className="mt-8 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 gap-y-4">

        {/* Create a div for each feature */}
        <Feature title="Cheekbone Shape" value={cheekboneShape} />
        <Feature title="Chin Shape" value={chinShape} />
        <Feature title="Eye Color" value={eyeColor} />
        <Feature title="Eye Shape" value={eyeShape} />
        <Feature title="Face Shape" value={faceShape} />
        <Feature title="Jaw Angularity" value={jawAngularity} />
        <Feature title="Lip Shape" value={lipShape} />
        <Feature title="Race" value={race} />
        <Feature title="Age" value={age.toString()} />
        <Feature title="Hair Type" value={hairType} />
        <Feature title="Country" value={country} />
      </div>
    </div>
  );
};

// Separate component for displaying each feature
const Feature: React.FC<{ title: string; value: string }> = ({
  title,
  value,
}) => {
  return (
    <div className="max-w-[320px] text-center px-4 py-2 rounded-lg bg-[#FFFFFF1A]" style={{
      boxShadow: '0px 4px 10px 0px #486D9F inset'
    }}>
      <p className="font-Urbanist text-[24px] mb-2 font-semibold leading-custom text-white truncate">
        {title}
      </p>
      <p className="font-Urbanist text-base font-medium leading-6 text-white truncate">
        {value}
      </p>
    </div>
  );
};

export default ResultAnalysisSection;
