import { UseMutationResult, UseQueryResult, useMutation, useQuery } from "react-query";
import GeneralAPI from "./apiCall";

interface CreateParams {
  // Add the properties expected for creation
  // generalApi: any;
  route?: string;
  data?: any;
}

interface EditParams {
  // Add the properties expected for editing
  route?: string;
  data?: any;
}

interface DeleteParams {
  // Add the properties expected for deletion
  id: number;
  route: string;
}

interface ViewGetParams {
  purpose: string;
  route: string;
  variableSearch: string;
}

interface ViewWithIdORParamsParams {
  purpose: string;
  route: string;
  id: number;
  variableSearch: string;
}

interface ViewWithoutIdParams {
  route: string;
}

interface ViewGetWithEnabledParams extends ViewGetParams {
  enabled: boolean;
}

interface ViewWithEnabledParams {
  enabled: boolean;
  route: string;
  id: number;
}

interface ImageUploadParams {
  file: File;
  route: string; // Include route in the parameters
}

interface GeneralApiResponse {
  // Define the structure of the response you expect from your API
  status?: number;
  data?: any;
  error?: any;
  message?: string;
  json?: () => Promise<any>;
}

const generalApi = new GeneralAPI();

export function useCreate() {
  return useMutation<GeneralApiResponse, Error, CreateParams>(
    (params: CreateParams) => generalApi.create(params)
  );
}

export function useEdit() {
  
  return useMutation<GeneralApiResponse, Error, EditParams>(
    (params: EditParams) => generalApi.edit(params)
  );
}

export function useView(route: string, id?: any) {
  return useQuery<GeneralApiResponse, Error>({
    queryFn: async () => {
      const response = await generalApi.view(route, id);
      if (response?.status === 401) {
        return generalApi.view(route, id);
      }
      if (response?.error) {
        return response;
      }
      return response;
    },
  });
}

// Define other hooks in a similar manner...

export function useDelete(): UseMutationResult<any, Error, DeleteParams> {
  return useMutation((params: DeleteParams) => {
    return generalApi.delete(params);
  });
}


export function useViewGet(
  purpose: string,
  route: string,
  variableSearch: string
): UseQueryResult<GeneralApiResponse, Error> {
  return useQuery<GeneralApiResponse, Error>(
    [purpose, route, variableSearch],
    async () => {
      const response = await generalApi.view_get(route, variableSearch);
      if (response.status === 401) {
        // await refreshAccessToken();
        return generalApi.view_get(route, variableSearch);
      }

      return response;
    }
  );
}
export function useImageUpload() {
  return useMutation(async ({ file, route }: ImageUploadParams) => {
    // Step 1: Obtain the pre-signed URL from your backend using the provided route
    const presignedData = await generalApi.create({
      route, // Use the provided route
      data: {
        fileName: file.name.trim(),
        contentType: file.type,
      },
    });

    // Ensure the API response structure is correctly handled here
    // Step 2: Upload the file to the obtained pre-signed URL
    const response = await fetch(presignedData.data.url, {
      method: "PUT",
      headers: {
        "Content-Type": file.type, // Ensure the content type matches
      },
      body: file,
    });

    if (!response.ok) {
      throw new Error("Failed to upload the file to the pre-signed URL");
    }

    // Step 3: Return the URL where the image is stored
    // Assuming `presignedData.data.profileImage` is the URL to access the uploaded image
    return presignedData.data.profileImage;
  });
}
