import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import showToast from "../../../components/Common/toastNotifications";
import Input from "../../../components/Common/Input";
import LoginPageHeader from "../../../components/Auth/AuthLeftPage";
import GenericRowComponent from "../../../components/Dashboard/genericHeaderRow";
type ForgetEmailFormInputs = {
  email: string;
};

export const ForgetUserEmailinfluencer = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ForgetEmailFormInputs>();
  const navigate = useNavigate();

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const onSubmit = async (data: any) => {
    try {
      const response = await fetch(
        `${baseURL}/api/dashboard-influencer/auth/forgotPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();

      if (!result.error) {
        showToast(result?.message, "success");
        navigate(`/influencers/otp?email=${data?.email}`);
      } else {
        showToast(result.message || "User not found, please try again.", "error");
      }
    } catch (error: any) {
      showToast(error.message || "An unexpected error occurred.", "error");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-between gap-4 px-14 py-16 add_backgrounndAtBottom w-full">
      <LoginPageHeader
        leftImageUrl="/assets/images/authpageImages/forgetUserEmailpageImageLeft.png"
        rightImageUrl="/assets/images/authpageImages/forgetUserEmailpageImageRight.png"
      />
      <div className="md:w-1/2 py-6 px-10 md:px-24 w-full flex justify-center">
        <div className="max-w-[90%] w-full">
          <div className="ml-4 mb-12">
            <GenericRowComponent heading="Forgot Password" showBackButton />
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-6 bg-transparent ml-8 rounded-lg "
          >
            <div className="space-y-4">
              <Input
                // name='email'
                type="text"
                label="Email address:"
                autoComplete="off"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                error={errors?.email && errors?.email?.message}
                className="w-full lg:h-18 "
                placeholder="Email address:"
              />
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600 text-black p-3 rounded-lg  md:mt-5 font-bold text-lg"
            >
              Next
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
