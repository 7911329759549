import React, { useEffect, useState } from "react";
import GenericRowComponent from "../Dashboard/genericHeaderRow";
import Loading from "../Loading";
import ProfileView from "../Dashboard/profileSectionWithImage";
import { useViewGet } from "../../services/Api/general";

import TableComponent from "../Dashboard/TableComponent";
import { DropdownActions } from "../Dashboard/dropDownTable";
import moment from "moment";
import { Menu } from "@headlessui/react";
import SelectedFaceMatchResult from "../../pages/FaceMatch/SelectedFaceMatchResult";

// User data type definition
interface Profile {
  id: number;
  name: string;
  scan_count: number;
  age?: number;
  fcm_token?: string;
  avatar?: string;
  referral_code?: string;
  is_active: boolean;
  default_profile: boolean;
  incentive_task_streak: number;
  referrals_count: number;
  referral_incentive_streak: number;
  incentive_scans_earned: number;
  created_at: string;
  updated_at: string;
}

interface User {
  id: number;
  name: string;
  subscription_status: string;
  role: string;
  email: string;
  profiles: Profile[];
  // ... other user fields
}
// const SeclectedProfileRecord: React.FC = (SelectedProfile: Profile, email:string) => {
interface SelectedProfileRecordProps {
  SelectedProfile: Profile;
  setSelectedScanID: any;
  SelectedScanID: number | undefined;
}

const SelectedProfileRecord: React.FC<SelectedProfileRecordProps> = ({
  SelectedProfile,
  SelectedScanID,
  setSelectedScanID
}) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortedArray, setSortedArray] = useState([]);
  
  const rowsPerPage = 10;



  // Initialize SelectedProfile once UserData is available
  const {
    isLoading,
    data: SingleProfileFaceMatch,
    refetch,
  } = useViewGet(
    "SingleProfileFaceMatch",
    `faceMatch/getFaceMatchResults/${SelectedProfile.id}?page=${currentPage}&limit=${rowsPerPage}&search=${searchQuery}`,
    ""
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (SingleProfileFaceMatch) {
      const initialSort = SingleProfileFaceMatch?.data?.results
        ?.map((facematch: any, index: number) => ({ ...facematch, index: (currentPage - 1) * 10 + index + 1 }));

      setSortedArray(initialSort);
    }
  }, [SingleProfileFaceMatch]);

  useEffect(() => {
    refetch();

  }, [searchQuery, currentPage]);

  const handleSearch = (value: string) => {
    setCurrentPage(1)
    setSearchQuery(value)
  }


  const columns: any = [
    {
      name: "No#",
      selector: (row: any) => row.index,
      sortable: true,
      width: "10%",
    },
    {
      name: "Images",
      cell: (row: any) => (
        <div className="flex items-center space-x-4">
        <div className="relative">
          <img
            src={row.person1_image}
            alt="Person 1"
            className="w-12 h-12 rounded-full object-cover"
          />
          <img
            src={row.person2_image}
            alt="Person 2"
            className="w-12 h-12 rounded-full object-cover absolute top-0 left-8"
          />
        </div>
      </div>
      ),
      sortable: false,
      width: "20%",
    },
    {
      name: "Date",
      selector: (row: any) => moment(row.created_at).format("YYYY-MM-DD"),
      sortable: true,
      width: "20%",
    },
    {
      name: "Time",
      selector: (row: any) => moment(row.created_at).format("hh:mm A"),
      sortable: true,
      width: "15%",
    },
    {
      name: "Overall Score",
      cell: (row: any) => (
        <div className="bg-[#34B0FF4D] text-white py-1 px-3 min-w-[71px] rounded-full text-center">
          {`${row.original_total_score}%`}
        </div>
      ),
      sortable: true,
      width: "20%",
    },
    {
      name: "Action",
      width: "15%",
      cell: (row: any, rowIndex: number) => (
        // Modify DropdownActions component to fit your needs
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="inline-flex justify-center w-full px-1 py-2 text-sm font-medium rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <svg
              width="14"
              height="4"
              viewBox="0 0 14 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.5">
                <path
                  d="M13.4874 1.01257C12.804 0.329152 11.696 0.329152 11.0126 1.01257C10.3291 1.69598 10.3291 2.80402 11.0126 3.48744C11.696 4.17085 12.804 4.17085 13.4874 3.48744C14.1709 2.80405 14.1709 1.69601 13.4874 1.01257Z"
                  fill="white"
                />
                <path
                  d="M8.23744 1.01257C7.55402 0.329152 6.44598 0.329152 5.76257 1.01257C5.07915 1.69598 5.07915 2.80402 5.76257 3.48744C6.44598 4.17085 7.55402 4.17085 8.23744 3.48744C8.92085 2.80405 8.92085 1.69601 8.23744 1.01257Z"
                  fill="white"
                />
                <path
                  d="M2.98744 1.01257C2.30402 0.329152 1.19598 0.329152 0.512564 1.01257C-0.170852 1.69598 -0.170852 2.80402 0.512564 3.48744C1.19598 4.17085 2.30402 4.17085 2.98744 3.48744C3.67085 2.80405 3.67085 1.69601 2.98744 1.01257Z"
                  fill="white"
                />
              </g>
            </svg>
          </Menu.Button>
          <Menu.Items
            className={`absolute right-0 mt-2 w-36 z-10 origin-top-right bg-[#bdb5d2] p-2 divide-y divide-gray-100 rounded-lg focus:outline-none`}
          >
            <div className="px-1 flex flex-col gap-y-2 py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => setSelectedScanID(row.id)}
                    className={`bg-[#C03EFE] text-white cursor-pointer
              group flex rounded-md items-center justify-start text-xs leading-[1.2] tracking-none text-left font-medium font-Urbanist gap-2 w-full px-2 py-2`}
                  >
                    <img
                      src="/assets/images/tableIcons/Eye.svg"
                      className=" w-3 h-3 "
                    />
                    View
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Menu>
      ),
    },
  ];

  return (
    <>
      {SelectedProfile && (
        <>
          {SelectedScanID ? (
            <SelectedFaceMatchResult
            selectedScanID={SelectedScanID}
            selectedProfileID={SelectedProfile.id}
            setSelectedScanID={setSelectedScanID}
            />
          ) : (
            <div>
              <GenericRowComponent
                heading="Total Facematch"
                onInputChange={handleSearch}
                showInput
              />
              <div className="w-full h-full resultTable">
                {isLoading ? (
                  <div className=" absolute inset-0 flex items-center justify-center">
                  <Loading />
                  </div>
                ) : (
                  <TableComponent
                    columns={columns}
                    data={sortedArray}
                    totalRows={SingleProfileFaceMatch?.data?.total}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    progressPending={isLoading}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SelectedProfileRecord;
