import { Menu } from "@headlessui/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface Props {
  row: any; // Assuming Celebrity is defined elsewhere
  rowIndex: number;
  totalRows: number;
  actions: string;
  onConfirmDelete?: any;
  viewUrl?: string;
}
export const DropdownActions: React.FC<Props> = ({
  row,
  rowIndex,
  totalRows,
  actions,
  onConfirmDelete,
  viewUrl= `view/${row.id}`
}) => {
  const [isLastRow, setIsLastRow] = useState(false);
  const [enabledActions, setEnabledActions] = useState<string[]>([]);

  useEffect(() => {
    // Check if the current row index is the last row
    setIsLastRow(rowIndex >= 1);
    setEnabledActions(actions.split(",").map((action) => action.trim()));
  }, [rowIndex, totalRows, actions]);
  const isActionEnabled = (action: string) => enabledActions.includes(action);


  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="inline-flex justify-center w-full px-1 py-2 text-sm font-medium rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        <svg
          width="14"
          height="4"
          viewBox="0 0 14 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.5">
            <path
              d="M13.4874 1.01257C12.804 0.329152 11.696 0.329152 11.0126 1.01257C10.3291 1.69598 10.3291 2.80402 11.0126 3.48744C11.696 4.17085 12.804 4.17085 13.4874 3.48744C14.1709 2.80405 14.1709 1.69601 13.4874 1.01257Z"
              fill="white"
            />
            <path
              d="M8.23744 1.01257C7.55402 0.329152 6.44598 0.329152 5.76257 1.01257C5.07915 1.69598 5.07915 2.80402 5.76257 3.48744C6.44598 4.17085 7.55402 4.17085 8.23744 3.48744C8.92085 2.80405 8.92085 1.69601 8.23744 1.01257Z"
              fill="white"
            />
            <path
              d="M2.98744 1.01257C2.30402 0.329152 1.19598 0.329152 0.512564 1.01257C-0.170852 1.69598 -0.170852 2.80402 0.512564 3.48744C1.19598 4.17085 2.30402 4.17085 2.98744 3.48744C3.67085 2.80405 3.67085 1.69601 2.98744 1.01257Z"
              fill="white"
            />
          </g>
        </svg>
      </Menu.Button>
      <Menu.Items
        className={`absolute right-0 ${
          isLastRow ? "bottom-full mb-2" : "mt-2"
        } w-36 z-10 origin-top-right bg-[#bdb5d2] p-2 divide-y divide-gray-100 rounded-lg focus:outline-none`}
      >
        <div className="px-1 flex flex-col gap-y-2 py-1">
          {isActionEnabled("edit") && (
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={`edit/${row.id}`}
                  className={`bg-[#2E75FF] text-white
                  group flex rounded-md items-center justify-start text-xs leading-[1.2] tracking-none text-left font-medium font-Urbanist gap-2 w-full px-2 py-2`}
                >
                  <img
                    src="/assets/images/tableIcons/Edit.svg"
                    className=" w-3 h-3 "
                  />
                  Edit
                </Link>
              )}
            </Menu.Item>
          )}
          {isActionEnabled("view") && (
            <Menu.Item>
              {({ active }) => (
                <Link
                  // to={`view/${row.id}`}
                  to={viewUrl}
                  className={`bg-[#C03EFE] text-white
                group flex rounded-md items-center justify-start text-xs leading-[1.2] tracking-none text-left font-medium font-Urbanist gap-2 w-full px-2 py-2`}
                >
                  <img
                    src="/assets/images/tableIcons/Eye.svg"
                    className=" w-3 h-3 "
                  />
                  View
                </Link>
              )}
            </Menu.Item>
          )}
          {isActionEnabled("delete") && (
            <Menu.Item>
              {({ active }) => (
                <span
                  // to={`edit/${row.id}`}
                  onClick={() => onConfirmDelete(row.id)}
                  className={`bg-[#FF2742] text-white cursor-pointer
                group flex rounded-md items-center justify-start text-xs leading-[1.2] tracking-none text-left font-medium font-Urbanist gap-2 w-full px-2 py-2`}
                >
                  <img
                    src="/assets/images/tableIcons/Bin.svg"
                    className=" w-3 h-3 "
                  />
                  Delete
                </span>
              )}
            </Menu.Item>
          )}
        </div>
      </Menu.Items>
    </Menu>
  );
};
