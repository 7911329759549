import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import ProfileView from "../../components/Dashboard/profileSectionWithImage";
import { useView } from "../../services/Api/general";
import Loading from "../../components/Loading";

const ViewCelebrity = () => {
  const { id } = useParams(); // Get the celebrity ID from the URL

  const { isLoading, data: celebData } = useView("celebrity", id);

  return (
    <>
      <div className="p-4 bg-transparent">
        <GenericRowComponent heading="View Celebrities" showBackButton />
        <div className="mx-4">
          {isLoading ? (
            <div className=" absolute inset-0 flex items-center justify-center">
            <Loading />
            </div>
          ) : (
            <div className="mt-6">

            <ProfileView
              imageUrl={celebData?.data?.display_picture}
              name={celebData?.data?.name}
              profession={celebData?.data?.profession}
              gender={celebData?.data?.gender}
              min_score={celebData?.data?.min_score}
              max_score={celebData?.data?.max_score}
            />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewCelebrity;
