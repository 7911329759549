import React from 'react';
import ReactModal from 'react-modal';

// Define a type for the props that the Modal component will accept
type ModalProps = {
  isOpen: boolean;
  title: string;
  message?: string;
  onConfirm: () => void; // This function doesn't take any arguments and doesn't return anything
  onCancel: () => void; // Same here
};

// Custom styles for the modal
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // backgroundColor: 'linear-gradient(183.52deg, #2C1B47 -33.85%, #1C548D 73.36%)',
    // background: 'radial-gradient(75.8% 75.8% at 50% 50%, rgba(0, 0, 0, 0) 0%, #000000 100%)',
    background: [
      'linear-gradient(183.52deg, #2C1B47 -33.85%, #1C548D 73.36%)',
      'radial-gradient(75.8% 75.8% at 50% 50%, rgba(0, 0, 0, 0) 0%, #000000 100%)'
    ].join(', '),
    border: 'none',
    borderRadius: '10px',
    padding: '20px',
    color: 'white',
    minWidth: '550px',
    minHeight: '260px',
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // width: '90%', // You can use a width in percentage or fixed size
  },
  overlay: {
    backgroundColor: '#FFFFFF36',
  },
};

// Set up react-modal (do this in your root component, App.js or similar)
ReactModal.setAppElement('#root'); // Adjust to your app's root element ID

// Modal component
const Modal: React.FC<ModalProps> = ({ isOpen, title, message, onConfirm, onCancel }) => {
  return (
    <ReactModal isOpen={isOpen} style={customStyles} onRequestClose={onCancel}>
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-2xl font-semibold text-center mb-4">{title}</h2>
        <p className="mb-2">{message}</p>
        <div className="flex flex-row justify-between gap-4 w-full mt-12 px-8">
          <button
            type="button"
            // onClick={onConfirm}
            onClick={onCancel}

            className="px-3 py-2 font-Urbanist text-md font-normal leading-[22px] text-center w-full text-[#76e19c] rounded-md"
            style={{
              border: "1px solid #76e19c",

              borderColor:
                "linear-gradient(268.95deg, #3FDFA1 5.53%, #FEE591 91.7%)",
              color: "linear-gradient(268.95deg, #3FDFA1 5.53%, #FEE591 91.7%)",
            }}
          >
            No
          </button>
          <button
            type="button"
            // onClick={onCancel}
            onClick={onConfirm}

            className={`inline-flex justify-center items-center py-2 px-8 w-full font-Urbanist text-md font-normal leading-[22px] text-left border border-transparent shadow-sm  rounded-md text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
            
            style={{
              background:
                "linear-gradient(268.95deg, #3FDFA1 5.53%, #FEE591 91.7%)",
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal;
