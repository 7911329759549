import Cookies from "js-cookie";

const tokenKey = "facebloom-dashboard";
const refreshTokenKey = "facebloom-dashboard-refresh";

export const getAccessToken = (): string | undefined => Cookies.get(tokenKey);

export const getRefreshToken = (): string | undefined => Cookies.get(refreshTokenKey);

export const setAccessToken = (token: string) => Cookies?.set(tokenKey, token);

export const setRefreshToken = (token: string) => Cookies?.set(refreshTokenKey, token);

export const setUserRole = (role: string) => Cookies?.set("facebloom-role", role);

export const removeTokens = (): void => {
  Cookies.remove(tokenKey);
  Cookies.remove(refreshTokenKey);
  Cookies.remove("facebloom-role");
};

// export const handleApiErrors = async (response: Response): Promise<Response> => {
//   if (response.status === 403) {
//     const res_msg = await response.json();
//     if (res_msg.message === "Church is not approved" ||
//         res_msg.message === "Church is not active") {
//       removeTokens();
//       window.location.href = "/";
//     } else {
//       const resp_msg = await response.json();
//       throw new Error(resp_msg.message || "User has no access to this route.");
//     }
//   }
//   return response;
// };

// export const refreshAccessToken = async (): Promise<void> => {
//   const refreshToken = getRefreshToken();

//   if (refreshToken) {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/refreshToken`, // Adjusted for React environment variable standards
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ refreshToken }),
//         }
//       );

//       if (response.ok) {
//         const resp = await response.json();
//         setAccessToken(resp?.accessToken);
//         setRefreshToken(resp?.refreshToken);
//         window.location.reload(true);
//       } else if (response.status === 403) {
//         removeTokens();
//         // ShowNotification should be replaced or imported from its module.
//         ShowNotification("User has no access to this route.", "error");
//         throw new Error("User has no access to this route.");
//       } else {
//         removeTokens();
//         // ShowNotification should be replaced or imported from its module.
//         ShowNotification("User session has been expired", "error");
//         throw new Error("User session has been expired");
//       }
//     } catch (error) {
//       console.error("Failed to refresh access token:", error);
//       removeTokens();
//       window.location.href = "/";
//       throw new Error("Failed to refresh access token");
//     }
//   } else {
//     removeTokens();
//     window.location.href = "/";
//     throw new Error("Refresh token not available");
//   }
// };