// @ts-nocheck

import React, { useState, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import ImageUpload from "../../components/Common/ImageUpload";
import Input from "../../components/Common/Input";
import TextArea from "../../components/Common/Textarea";
import TextEditor from "../../components/Common/TextEditor";
import MultipleImageUpload from "../../components/Common/MultiImageUpload";
import { useImageUpload } from "../../services/Api/general";
import CustomSelect from "../../components/Common/customSelect";
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import Cookies from "js-cookie";

const EditForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  // const [imageUrls, setImageUrls] = useState([]);
  const [recommendations, setRecommendations] = useState(false);
  const [races, setRaces] = useState([]);
  const [genders, setGenders] = useState([]);
  const [ratios, setRatios] = useState([]);
  const token = Cookies.get("facebloom-dashboard");


  const { mutateAsync: uploadImage } = useImageUpload();

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    resetField,
    reset,
  } = useForm({
    defaultValues: {
      images: [],
      criterias: [
        {
          race: [],
          gender: [],
          ratioType: "",
          ratioValue: [],
          min_value: "",
          max_value: "",
        },
      ],
    },
  });

  const {
    fields: criteriaFields,
    append: appendCriteria,
    remove: removeCriteria,
  } = useFieldArray({
    control,
    keyName: "customCriteriaId",
    name: "criterias",
  });

  const {
    fields: recommendationFields,
    append: appendRecommendation,
    remove: removeRecommendation,
  } = useFieldArray({
    control,
    keyName: "customChildId",
    name: "child_recommendations",
  });

  const watchedCriterias = watch("criterias");

  const baseURL = process.env.REACT_APP_API_BASE_URL; // Ensure your API base URL is correctly set in your environment variables

  async function uploadImagesSequentially(files: File[]): Promise<string[]> {
    const urls: string[] = [];

    for (const file of files) {
      if (file instanceof File) {
        // Ensure the object is a File

        try {
          // Assuming uploadImage is an async function that uploads a file and returns a URL
          const url = await uploadImage({
            file,
            route: "recommendations/getPreSignedUrl",
          });
          if (url) {
            urls.push(url); // Add the URL to the array if the upload was successful
          }
        } catch (error) {
          console.error("Upload failed for", file.name, error);
          // Optionally, handle the error, e.g., by adding an error marker or continuing with the next file
        }
      } else {
        urls.push(file);
      }
    }

    return urls?.join(","); // Return the array of URLs after all files have been processed
  }

  // handleRatioChange should update the form state
  const handleRatioChange = (value, criteriaIndex) => {
    const selectedRatio = ratios.find((ratio) => ratio.name === value);

    // Check if the selected ratio type is numeric
    if (selectedRatio?.is_numeric) {
      // Set numeric value fields
      setValue(`criterias.${criteriaIndex}.ratioValue`, null);
      setValue(`criterias.${criteriaIndex}.min_value`, "");
      setValue(`criterias.${criteriaIndex}.max_value`, "");
    } else {
      // Set non-numeric select field
      setValue(`criterias.${criteriaIndex}.ratioValue`, []);
      setValue(`criterias.${criteriaIndex}.min_value`, null);
      setValue(`criterias.${criteriaIndex}.max_value`, null);
    }
  };

  // renderDependentFields should render fields based on the form state
  const renderDependentFields = (index) => {
    const ratioValueOption = getValues(`criterias.${index}.ratio`);
    console.log(ratioValueOption, "ratioValueOption");
    const selectedRatio = ratios?.find(
      (ratio) => ratio.name === ratioValueOption?.value
    );
    const ratioValue = selectedRatio?.data;

    const min_value = getValues(`criterias.${index}.min_value`);
    const max_value = getValues(`criterias.${index}.max_value`);

    console.log(getValues(`criterias.${index}`));
    // if (min_value !== null || '' && max_value !== null || '') {
    if (min_value !== null && max_value !== null && ratioValue === null) {
      // Render numeric input fields
      console.log("HIT");
      return (
        <div className="flex flex-row justify-between gap-2 lg:w-[70%] w-full mt-2">
          <Input
            label="Min Value:"
            name={`criterias.${index}.min_value`}
            placeholder="Min Value"
            {...register(`criterias.${index}.min_value`)}
            type="number"
            step="0.01"
            // error={errors.title && "Title is required."}
            className="w-full"
          />

          <Input
            label="Max Value:"
            name={`criterias.${index}.max_value`}
            placeholder="Max Value"
            {...register(`criterias.${index}.max_value`)}
            type="number"
            step="0.01"
            // error={errors.title && "Title is required."}
            className="w-full"
          />
        </div>
      );
    } else if (ratioValue && ratioValue.length > 0) {
      // Render non-numeric select
      return (
        <div className="max-w-[355px]">
          <CustomSelect
            control={control}
            name={`criterias.${index}.ratioValue`}
            options={ratioValue.map((option) => ({
              value: option,
              label: option,
            }))}
            label="Select Value:"
            className={"w-full"}
            isMulti
          />
        </div>
      );
    }

    return null;
  };

  const [selectedRatio, setSelectedRatio] = useState(null);

  // Watch the value of the ratio select to determine how to render the dependent fields
  const selectedRatioValue = watch("ratio");

  useEffect(() => {
    const matchedRatio = ratios?.find(
      (ratio) => ratio.name === selectedRatioValue
    );
    setSelectedRatio(matchedRatio);
  }, [selectedRatioValue]);

  const transformCriterias = (criterias) => {
    return criterias.map((criteria) => {
      return {
        id: criteria?.id,
        races: criteria?.race?.map((r) => r.value).join(",") || "",
        genders: criteria?.gender?.map((g) => g.value).join(",") || "",
        is_ratio_numeric:
          criteria.min_value !== null && criteria.max_value !== null,
        ratio_name: criteria?.ratio?.value || null,
        ratio_min_value:
          criteria.min_value === "0"
            ? 0
            : parseFloat(criteria.min_value) || undefined,
        ratio_max_value: parseFloat(criteria.max_value) || undefined,
        ratio_value:
          (criteria &&
            criteria?.ratioValue &&
            criteria?.ratioValue?.length &&
            criteria?.ratioValue?.map((r) => r.value).join(",")) ||
          "",
      };
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      let titleImage;
      if (data.title_image !== null && data.title_image !== undefined) {
        if (typeof data.title_image === "string") {
          titleImage = data.title_image;
        } else {
          titleImage = await uploadImage({
            file: data?.title_image[0],
            route: "recommendations/getPreSignedUrl",
          });
        }
      } else {
        titleImage = null; // Example: Set a default image or handle accordingly
      }
      let iconImage;

      // Check if data.icon_image is not null and not undefined first
      if (data.icon_image !== null && data.icon_image !== undefined) {
        if (typeof data.icon_image === "string") {
          iconImage = data.icon_image;
        } else {
          iconImage = await uploadImage({
            file: data?.icon_image[0],
            route: "recommendations/getPreSignedUrl",
          });
        }
      } else {
        iconImage = null; // Example: Set a default icon or handle accordingly
      }

      const filteredRecommendations = data.child_recommendations.filter(
        (recommendation) => recommendation.title.trim() !== ""
      );
      const transformedCriterias = transformCriterias(data.criterias);
      const submissionData = {
        ...data,
        criterias: transformedCriterias,
        title_image: titleImage,
        icon_image: iconImage,
        parent_id: data?.parent_id,
        // details: `<span style=\"color: rgb(255, 255, 255);\">${
        //   data?.details ? data?.details : ""
        // }</span>`,
        details: data?.details?.startsWith(
          '<p><span style="color: rgb(255, 255, 255);">'
        )
          ? data.details
          : `<span style="color: rgb(255, 255, 255);">${
              data?.details ? data.details : ""
            }</span>`,

        images: await uploadImagesSequentially(data?.images),
        is_personalised_tip: data.is_personalised_tip || false,
        is_general: data.is_general || false,
        has_criterias: true || false,
        child_recommendations: filteredRecommendations,
      };
      debugger;
      await axios.put(
        `${baseURL}/api/dashboard/recommendations/${id}`,
        submissionData,
        {
          headers: {
            Authorization: `${token}`
          }
        }
      );
      console.log("Form submitted successfully");
      console.log(submissionData);
      navigate("/recommendations");
    } catch (error) {
      console.error("Failed to submit form", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchRatioTypes = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/dashboard/recommendations/lookUp/getRatioTypes`,
          {
            headers: {
              Authorization: `${token}`
            }
          }
        );
        setRatios(response.data.data);

        const raceIndex = response.data.data.findIndex(
          (ratio) => ratio.name === "race"
        );

        const genderIndex = response.data.data.findIndex(
          (ratio) => ratio.name === "gender"
        );

        setRaces(response.data.data[raceIndex]);
        setGenders(response.data.data[genderIndex]);

        const filteredRatios = response.data.data.filter(
          (ratio) => ratio.name !== "gender" && ratio.name !== "race"
        );

        setRatios(filteredRatios);
      } catch (error) {
        console.error("Failed to fetch ratio types", error);
      }
    };

    fetchRatioTypes();
  }, []);

  useEffect(() => {
    console.log(id, "pageID");
    const fetchRecommendationData = async () => {
      try {
        setLoading(true);

        reset({
          criterias: [],
          images: [],
          child_recommendations: [],
          // other fields reset to default or empty values
        });

        const response = await axios.get(
          `${baseURL}/api/dashboard/recommendations/${id}`,
          {
            headers: {
              Authorization: `${token}`
            }
          }
        );
        const { data } = response.data;
        console.log("Fetched data:", data);
        // Assuming your form structure matches the fetched data structure
        // Populate general information
        setValue("is_general", data.is_general);
        setValue("title_image", data.title_image);
        setValue("icon_image", data.icon_image);
        setValue("title", data.title);
        setValue("intro", data.intro);
        setValue("parent_id", data.parent_id);
        setValue("description", data.description);
        setValue("details", data.details);

        setValue(
          "images",
          (data?.images &&
            data?.images !== "" &&
            data?.images?.split(",")?.map((url: string) => url.trim())) ||
            []
        ); // Assuming 'images' is a comma-separated string
        setValue("is_personalised_tip", data.is_personalised_tip);
        // setupCriterias(data.criterias);

        // Populate criterias
        data.criterias.forEach((criteria, index) => {
          console.log("Criteria:", criteria);

          const {
            genders,
            races,
            ratio_value,
            ratio_name,
            ratio_min_value,
            ratio_max_value,
            id,
          } = criteria;

          // genders races and ratio_values are CSVs. Turn them into arrays
          const genderArray = (genders && genders?.split(",")) || [];
          const raceArray = (races && races?.split(",")) || [];
          const ratioValueArray =
            (ratio_value && ratio_value?.split(",")) || [];

          const formattedGenderArray = genderArray.map((g) => ({
            value: g,
            label: g,
          }));
          const formattedRaceArray = raceArray.map((g) => ({
            value: g,
            label: g,
          }));

          // nose
          const formattedRatioName = {
            value: ratio_name,
            label: ratio_name,
          };

          const formattedRatioValueArray = ratioValueArray.map((g) => ({
            value: g,
            label: g,
          }));

          const isRatioNumeric = ratios.find(
            (ratio) => ratio.name === ratio_name
          )?.is_numeric;

          console.log(criteria, "criteria", "ZAZA");
          appendCriteria({
            // ...criteria,
            id: id,
            race: formattedRaceArray,
            gender: formattedGenderArray,
            ratio: formattedRatioName,
            ratioValue: formattedRatioValueArray,
            min_value: criteria.is_ratio_numeric
              ? criteria.ratio_min_value
              : null,
            max_value: criteria.is_ratio_numeric
              ? criteria.ratio_max_value
              : null,
          });
        });

        data.children.forEach((child) => {
          appendRecommendation({
            ...child,
            id: child.id,
            title: child.title,
          });
        });
      } catch (error) {
        console.error("Failed to fetch recommendation data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchRecommendationData();
  }, [id]);

  useEffect(() => {}, [watchedCriterias]);

  const titleImage = watch("title_image");
  const iconImage = watch("icon_image");
  const images = watch("images");
  console.log(images);

  return (
    <div>
      <GenericRowComponent heading="Edit Recommendation" showBackButton />
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 my-2 mr-4 mb-7">
        <div className=" bg-[#FFFFFF1A] rounded-xl mt-4 p-4">
          <div className="flex flex-row items-center justify-start gap-3">
            <label
              htmlFor="is_general"
              className="block  font-Urbanist text-4xl font-semibold leading-[32px] text-left text-white"
            >
              General
            </label>
            
            <label className="switch">
              <input
                type="checkbox"
                {...register("is_general")}
                // onChange={(e)=>setChat(e.target.checked)}
              />
              <span className="slider"></span>
            </label>
          </div>

          <div className="flex flex-row justify-start gap-5 pt-5">
            <div className="flex flex-col gap-y-1 justify-center">
              <ImageUpload
                setValue={setValue}
                watch={watch}
                name="title_image"
                defaultImage={titleImage}
                formEdit
              />
              <span className="text-white font-Urbanist text-2xl font-medium leading-6 tracking-normal text-center">
                Title Image
              </span>
            </div>
            <div className="flex flex-col gap-y-1 justify-center">
              <ImageUpload
                setValue={setValue}
                watch={watch}
                name="icon_image"
                defaultImage={iconImage}
                formEdit
              />
              <span className="text-white font-Urbanist text-2xl font-medium leading-6 tracking-normal text-center">
                Icon Image
              </span>
            </div>
          </div>
          <div className="flex flex-row justify-between gap-4 w-[80%] pt-5">
            <Input
              label="Title:"
              name="title"
              placeholder="Add title"
              {...register("title", { required: true })}
              error={errors.title && "Title is required."}
              className="w-full"
            />
            <Input
              label="Intro:"
              name="intro"
              placeholder="Add intro"
              {...register("intro")}
              error={errors.intro?.message}
              className="w-full"
            />
          </div>

          <div className="pt-5">
            <TextArea
              label="Description:"
              name="description"
              placeholder="Add description"
              {...register("description")}
              error={errors.description?.message}
              className="w-full"
            />
          </div>

          <div className="mt-5">
            <MultipleImageUpload
              label="Images:"
              setValue={setValue}
              watch={watch}
              name="images"
              register={register}
              defaultImages={images}
            />
          </div>
          <div className="mt-5">
            <TextEditor name="details" label="Details" control={control} />
            {errors.details && (
              <p className="text-red-500 text-xs mt-2">Details are required.</p>
            )}
          </div>

          <div className="flex flex-row justify-start items-center gap-5 my-5">
            <label
              htmlFor="is_personalised_tip"
              className="block text-white font-Urbanist text-2xl font-semibold leading-9 tracking-normal"
            >
              Is Personalized:
            </label>
            <label className="switch">
              <input
                type="checkbox"
                {...register("is_personalised_tip")}
              />
              <span className="slider"></span>
            </label>
            
          </div>
          {criteriaFields.map((field, index) => (
            <div
              key={`${field.id}/${index}`}
              className="bg-[#FFFFFF1A] p-3 pb-6 rounded-lg mt-1 relative"
            >
              {" "}
              {/* Make sure each field has a unique key */}
              <h1 className=" text-white font-Urbanist text-xl font-semibold leading-9 tracking-normal">
                Criteria: {index + 1}
              </h1>
              <div className="mt-2 flex flex-row justify-between gap-3">
                <CustomSelect
                  control={control}
                  name={`criterias.${index}.race`}
                  options={races?.data?.map((race) => ({
                    value: race,
                    label: race,
                  }))}
                  label="Races:"
                  className={"w-full"}
                  isMulti
                />

                <CustomSelect
                  control={control}
                  name={`criterias.${index}.gender`}
                  options={genders?.data?.map((gender) => ({
                    value: gender,
                    label: gender,
                  }))}
                  label="Gender:"
                  className={"w-full"}
                  isMulti
                />

                <CustomSelect
                  control={control}
                  name={`criterias.${index}.ratio`}
                  options={ratios?.map((ratio) => ({
                    value: ratio.name,
                    label: ratio.value,
                  }))}
                  label="Select Ratio:"
                  className={"w-full"}
                  handleRatioChange={handleRatioChange}
                  fromRecommendationCriteria
                  recommendationCriteriaIndex={index}
                />
              </div>
              {renderDependentFields(index)}
              <button
                type="button"
                onClick={() => removeCriteria(index)}
                className="remove-criteria-button absolute top-0 right-0"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="5.5" cy="5.49976" r="5.5" fill="#798BA7" />
                  <path
                    d="M7.99361 3.02095C6.62864 1.65936 4.38869 1.65936 3.02373 3.02095C1.65876 4.38255 1.65876 6.61696 3.02373 7.97856C4.38869 9.34015 6.59364 9.34015 7.95861 7.97856C9.32357 6.61696 9.35857 4.38255 7.99361 3.02095ZM6.48864 6.96609L5.50867 5.98853L4.52869 6.96609L4.0387 6.47731L5.01868 5.49976L4.0387 4.5222L4.52869 4.03342L5.50867 5.01098L6.48864 4.03342L6.97863 4.5222L5.99865 5.49976L6.97863 6.47731L6.48864 6.96609Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          ))}
          <button
            type="button"
            className="bg-[#75179C] mt-2 px-4 py-2 rounded-md text-white font-Urbanist text-base font-medium leading-5 tracking-normal "
            onClick={() =>
              appendCriteria({
                race: [],
                gender: [],
                ratioType: "",
                ratioValue: [],
                min_value: "",
                max_value: "",
              })
            }
          >
            + Add Criteria
          </button>
        </div>
        <div className="mt-4 flex flex-row justify-start gap-2 flex-wrap gap-y-3">
          {recommendationFields.map((field, index) => {
            return (
              <div
                key={`${field.id}/${index}`}
                className={`flex justify-center items-center gap-3 rounded-md space-x-2 bg-[#FFFFFF0F] ${
                  field.id && "px-3 py-2"
                }  font-Urbanist text-lg font-medium leading-6 text-white align-text-left`}
              >
                {field.title}
                {field.id ? (
                  <button
                    type="button"
                    onClick={() =>
                      navigate(`/recommendations/edit/${field.id}`)
                    }
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="32" height="32" rx="4" fill="white" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.668 23.1362L21.4783 13.036C21.9028 12.4914 22.0537 11.8617 21.9122 11.2205C21.7896 10.6376 21.4311 10.0834 20.8935 9.66295L19.5823 8.6214C18.441 7.71363 17.0261 7.80918 16.2148 8.85073L15.3376 9.9888C15.2244 10.1312 15.2527 10.3414 15.3942 10.4561C15.3942 10.4561 17.6109 12.2334 17.6581 12.2716C17.809 12.4149 17.9222 12.606 17.9505 12.8354C17.9976 13.2845 17.6863 13.7049 17.2241 13.7623C17.0072 13.7909 16.7997 13.724 16.6488 13.5998L14.3189 11.7461C14.2057 11.661 14.0359 11.6792 13.9416 11.7938L8.40458 18.9605C8.04613 19.4096 7.92351 19.9925 8.04613 20.5562L8.75359 23.6235C8.79132 23.786 8.93281 23.9007 9.1026 23.9007L12.2154 23.8624C12.7813 23.8529 13.3096 23.5949 13.668 23.1362ZM18.025 22.1825H23.1007C23.596 22.1825 23.9987 22.5905 23.9987 23.0921C23.9987 23.5948 23.596 24.0018 23.1007 24.0018H18.025C17.5298 24.0018 17.127 23.5948 17.127 23.0921C17.127 22.5905 17.5298 22.1825 18.025 22.1825Z"
                        fill="url(#paint0_linear_1776_6080)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_1776_6080"
                          x1="6.09106"
                          y1="8"
                          x2="26.8197"
                          y2="23.998"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#73ACF9" />
                          <stop offset="1" stopColor="#AD85F0" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </button>
                ) : (
                  <div className="relative">
                    {/* <input
                      {...register(`child_recommendations.${index}.title`)}
                      placeholder="Recommendation Title"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm leading-tight focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    /> */}
                    <Input
                      name="`child_recommendations.${index}.title`"
                      placeholder="Recommendation Title"
                      {...register(`child_recommendations.${index}.title`)}
                      error={errors.intro?.message}
                      className="w-full !border-none"
                      showLabel={false}
                    />
                    <button
                      type="button"
                      onClick={() => removeRecommendation(index)}
                      className="remove-criteria-button absolute top-0 right-0"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="5.5" cy="5.49976" r="5.5" fill="#798BA7" />
                        <path
                          d="M7.99361 3.02095C6.62864 1.65936 4.38869 1.65936 3.02373 3.02095C1.65876 4.38255 1.65876 6.61696 3.02373 7.97856C4.38869 9.34015 6.59364 9.34015 7.95861 7.97856C9.32357 6.61696 9.35857 4.38255 7.99361 3.02095ZM6.48864 6.96609L5.50867 5.98853L4.52869 6.96609L4.0387 6.47731L5.01868 5.49976L4.0387 4.5222L4.52869 4.03342L5.50867 5.01098L6.48864 4.03342L6.97863 4.5222L5.99865 5.49976L6.97863 6.47731L6.48864 6.96609Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            );
          })}
          <button
            type="button"
            onClick={() => appendRecommendation({ title: "" })}
            className=" inline-flex items-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#75179C] focus:outline-none focus:ring-offset-2 focus:ring-0"
          >
            + Add New Recommendation
          </button>
        </div>
        <div className=" flex justify-end items-center w-full ">
          <div className="flex flex-row justify-between gap-4 pr-3">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="px-3 py-2 font-Urbanist text-md font-normal leading-[22px] text-left text-[#76e19c] rounded-md"
              style={{
                border: "1px solid #76e19c",

                borderColor:
                  "linear-gradient(268.95deg, #3FDFA1 5.53%, #FEE591 91.7%)",
                color:
                  "linear-gradient(268.95deg, #3FDFA1 5.53%, #FEE591 91.7%)",
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`inline-flex justify-center items-center py-2 px-4 font-Urbanist text-md font-normal leading-[22px] text-left border border-transparent shadow-sm  rounded-md text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
              disabled={isSubmitting}
              style={{
                background:
                  "linear-gradient(268.95deg, #3FDFA1 5.53%, #FEE591 91.7%)",
              }}
            >
              {isSubmitting ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditForm;
