import React from 'react';

interface ProfileProps {
  name: string;
  email?: string;
  imageUrl?: string;
  onClick: () => void;
  selectedUser?: boolean;
}

export const ProfileCard: React.FC<ProfileProps> = ({
  name,
  email,
  imageUrl,
  onClick,
  selectedUser
}) => {

    let user= false

  return (
    <div onClick={onClick} className="flex flex-row justify-start gap-4 items-center min-w-[243px] w-full max-w-[350px] rounded-lg px-4 py-2"
        style={{
            background : selectedUser ? 'linear-gradient(268.95deg, #3FDFA1 5.53%, #FEE591 91.7%)' : '#FFFFFF1A'
        }}
    >
      <img src={imageUrl || '/assets/images/square-user.png'} alt={name} className="profile-card-image border-2 w-[45px] h-[45px] rounded-full border-[#E6E5F9]" />
      <div className="profile-card-info">
        <h3 className={`font-Urbanist text-lg font-semibold leading-5 max-w-[260px] truncate text-left ${selectedUser  ? 'text-[#171717]' : 'text-white'}`}>{name}</h3>
        {email && (<p className={`font-Urbanist text-[12px] font-normal leading-[14px] text-left ${selectedUser  ? 'text-[#3f3f3f]' : 'text-[#CDCCCC]' }  `}>{email}</p>)}
      </div>
    </div>
  );
};