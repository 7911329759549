import React from "react";
type LoginPageHeaderProps = {
  leftImageUrl: string;
  rightImageUrl: string;
};

const LoginPageHeader: React.FC<LoginPageHeaderProps> = ({
  leftImageUrl,
  rightImageUrl,
}) => (
  <div
    className="lg:py-12 py-6 px-4 lg:px-6  rounded-3xl  items-center md:w-1/2 md:flex flex-col hidden"
    style={{
      background: 'url("/assets/images/loginpeoplebackground.png")',
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    }}
  >
    <h1
      className="font-Urbanist text-5xl font-bold leading-15 text-center"
      style={{
        background: "linear-gradient(180deg, #FEE591 0%, #4CDFA0 100%)",
        WebkitBackgroundClip: "text", // For Safari compatibility
        color: "transparent",
        WebkitTextFillColor: "transparent", // For Safari compatibility
      }}
    >
      Find{" "}
      <span
        className="font-light"
        style={{
          background: "white",
          WebkitBackgroundClip: "text", // For Safari compatibility
          color: "transparent",
          WebkitTextFillColor: "transparent", // For Safari compatibility
        }}
      >
        and add a <br />
      </span>{" "}
      Face Pic !
    </h1>
    <span className="flex w-[286px] h-[48px] justify-center items-start font-['Urbanist'] text-[16px] font-medium opacity-[0.699999988079071] leading-[24px] text-[#fff] relative text-center overflow-hidden mt-4">
      Begin your journey with a
      <br />
      face pic on facebloom
    </span>
    <div className="flex flex-row mx-auto">
      <div className="w-[418.004px] h-[447.016px] z-10">
        <img src={leftImageUrl} alt="Left Image" />
      </div>
      <div className="w-[358.63px] h-[353.575px]  rounded-[15px] mt-32 -ml-48">
        <img src={rightImageUrl} alt="Right Image" />
      </div>
    </div>
  </div>
);

export default LoginPageHeader;
