import { useEffect, useRef, useState } from "react";
import { TableColumn } from "react-data-table-component";
import TableComponent from "../../components/Dashboard/TableComponent";
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import { DropdownActions } from "../../components/Dashboard/dropDownTable";
import Loading from "../../components/Loading";
import { useDelete, useViewGet } from "../../services/Api/general";
import Modal from "../../components/Common/Modal";

interface User {
  id: number;
  name: string;
  email: string;
  age: number;
  subscription_type: string;
  gender: string;
  index: number;
}

const UserManagementPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortedArray, setSortedArray] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  const rowsPerPage = 10;
  const userPageRef = useRef<HTMLDivElement>(null);


  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const { mutateAsync: deleteService } = useDelete();

  const {
    isLoading,
    data: AllUserManagment,
    refetch,
  } = useViewGet("AllUserManagment", `user/getUsers?page=${currentPage}&limit=${rowsPerPage}&search=${searchQuery}`, "");

  useEffect(() => {
    if (AllUserManagment) {
      const initialSort = AllUserManagment?.data?.users
        ?.map((user: any, index: number) => ({ ...user, index: (currentPage - 1) * 10 + index + 1 }));

      setSortedArray(initialSort);
    }
  }, [AllUserManagment]);

  useEffect(() => {
    refetch();
    if (userPageRef.current) {
      userPageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [searchQuery, currentPage]);

  const handleSearch = (value: string) => {
    setCurrentPage(1)
    setSearchQuery(value)
  }

  const handleDeleteUser = async () => {
    if (selectedUserId !== null) {
      try {
        const response = await deleteService({
          route: "user/deleteUser",
          id: selectedUserId,
        });

        if (response.error === false) {
          refetch();
        } else if (response.error) {
          console.error("Error posting data:", response);
        } 
      } catch (error) {
        console.error("Error posting data:", error);
      }
      // After deleting the user, hide the modal and clear the selected user ID
      setShowDeleteModal(false);
      setSelectedUserId(null);
      // Refresh the user list or update the state accordingly
    }
  };

  const confirmDelete = (userId: number) => {
    setSelectedUserId(userId);
    setShowDeleteModal(true);
  };

  const columns: TableColumn<User>[] = [
    {
      name: "No#",
      selector: (row) => row.index,
      sortable: true,
      width: "15%",
    },
    {
      name: "Name",
      width: "20%",
      cell: (row) => (
        <div className="flex items-center">
          {/* Assuming you have a placeholder image for users */}
          <img
            src="/assets/images/user-avatar.png" // Replace with your image path
            alt={row.name}
            style={{ width: 30, height: 30, borderRadius: "50%" }}
          />
          <span className="ml-2">{row.name}</span>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "25%",
    },

    {
      name: "Subscription",
      selector: (row) => row?.subscription_type,
      sortable: true,
      width: "15%",
    },

    {
      name: "Action",
      width: "15%",
      cell: (row, rowIndex) => (
        // Modify DropdownActions component to fit your needs
        <DropdownActions
          row={row}
          rowIndex={rowIndex}
          totalRows={10}
          actions={"view,delete"}
          onConfirmDelete={confirmDelete}
        />
      ),
    },
  ];

  return (
    <div className="p-4 bg-transparent" ref={userPageRef}>
      <GenericRowComponent
        heading="Users Management"
        onInputChange={handleSearch}
        showInput
      />
      <div className="w-full h-full">
        {isLoading ? (
          <div className=" absolute inset-0 flex items-center justify-center">
          <Loading />
          </div>
        ) : (
          <TableComponent
            columns={columns}
            data={sortedArray}
            totalRows={AllUserManagment?.data?.total}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            progressPending={isLoading}
          />
        )}
      </div>

      {showDeleteModal && selectedUserId && (
        <>
          <Modal
            isOpen={showDeleteModal ? true : false}
            title="Are you sure that you want to delete?"
            // message="You won't be able to revert this!"
            onConfirm={handleDeleteUser}
            onCancel={() => setShowDeleteModal(false)}
          />
        </>
      )}
    </div>
  );
};

export default UserManagementPage;
