import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import showToast from "../../../components/Common/toastNotifications";
import Input from "../../../components/Common/Input";
import LoginPageHeader from "../../../components/Auth/AuthLeftPage";
import GenericRowComponent from "../../../components/Dashboard/genericHeaderRow";
import { useEffect } from "react";
import PasswordInput from "../../../components/Common/PasswordInput";
type ChangePasswordFormInputs = {
  newPassword: string;
  confirm_password: string;
};

export const ChangePasswordinfluencer = () => {
  const [searchParams] = useSearchParams();
  const otp = searchParams.get("otp");
  const email = searchParams.get("email");
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<ChangePasswordFormInputs>();
  const navigate = useNavigate();
  useEffect(() => {
    // If email is undefined or not valid, redirect
    if (!otp) {
      navigate("/influencers/forget_email");
    }
  }, [otp]); // Dependency array includes email and navigate to rerun the effect if these change


  // Watch the values of both password fields
  const newPassword = watch("newPassword");
  const confirmPassword = watch("confirm_password");
  // Check if passwords match and set an error if they do not
  useEffect(() => {
    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      setError("confirm_password", {
        type: "manual",
        message: "Passwords do not match.",
      });
    } else {
      clearErrors("confirm_password");
    }
  }, [newPassword, confirmPassword, setError, clearErrors]);

 

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const onSubmit = async (data: any) => {
    try {
      const data_form = {
        ...data,
        email: email,
        otp: otp,
      };
      const response = await fetch(
        `${baseURL}/api/dashboard-influencer/auth/passwordResetOtp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data_form),
        }
      );

      const result = await response.json();

      if (!result.error) {
        showToast(result?.message, "success");
        navigate(`/influencers/change_password_success`);
      } else {
        showToast(
          result.message || "User not found, please try again.",
          "error"
        );
      }
    } catch (error: any) {
      showToast(error.message || "An unexpected error occurred.", "error");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-between gap-4 px-14 py-16 add_backgrounndAtBottom w-full">
      <LoginPageHeader
        leftImageUrl="/assets/images/authpageImages/changePasswordpageImageLeft.png"
        rightImageUrl="/assets/images/authpageImages/changePasswordpageImageRight.png"
      />
      <div className="md:w-1/2 py-6 px-10 md:px-24 w-full flex justify-center">
        <div className=" w-full">
          <div className="ml-4 mb-12">
            <GenericRowComponent heading="Change Password" showBackButton />
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-6 bg-transparent ml-8 rounded-lg "
          >
            <div className="space-y-4">
              <PasswordInput
                label="Password:"
                {...register("newPassword", {
                  required: "Password is required",
                  pattern: {
                    // This regex checks for at least one uppercase letter, one lowercase letter, one digit, and a minimum length of 8
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                    message:
                      "Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, and 1 number.",
                  },
                })}
                error={errors?.newPassword && errors?.newPassword?.message}
                className="w-full lg:h-18 "
                placeholder="*********"
              />

              <PasswordInput
                label="Confirm Password:"
                {...register("confirm_password", {
                  required: "Confirm Password is required",
                  pattern: {
                    // This regex checks for at least one uppercase letter, one lowercase letter, one digit, and a minimum length of 8
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                    message:
                      "Password must be same as above.",
                  },
                })}
                error={
                  errors.confirm_password && errors.confirm_password.message
                }
                className="w-full lg:h-18 "
                placeholder="*********"
              />
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600 text-black p-3 rounded-lg  md:mt-5 font-bold text-lg"
            >
              Change Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
