// src/redux/slices/userSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  isLoggedIn: boolean; // Added isLoggedIn property
  data: any;
}

const initialState: UserState = {
  isLoggedIn: false, // Default value
  data: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.isLoggedIn = true; // Set isLoggedIn to true when setting a user
    },
    logoutUser: (state) => {
      state.data = null;
      state.isLoggedIn = false; // Set isLoggedIn to false on logout
    },
  },
});

export const { setUser, logoutUser } = userSlice.actions;

export default userSlice.reducer;
