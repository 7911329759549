import React, { useRef, useState, useEffect } from "react";

type MultipleImageUploadProps = {
  name: string;
  label?: string;
  defaultImages?: string[];
  register: any;
  setValue: any;
  watch: any;
};

const MultipleImageUpload: React.FC<MultipleImageUploadProps> = ({
  name,
  label,
  defaultImages = [],
  register,
  setValue,
  watch,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileValues = watch(name);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  // Local state to manage preview URLs separately from file state
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);

  useEffect(() => {
    register(name);

    // No need to initialize the field with defaultImages here since they are managed separately
  }, [register, name]);

  // Update form value with file previews on changes
  useEffect(() => {
    if (previewUrls?.length == 0 && defaultImages?.length > 0) {
      setPreviewUrls(defaultImages);
      setSelectedFiles(defaultImages);
    }
  }, [defaultImages]);

  useEffect(() => {
    setValue(name, selectedFiles, { shouldValidate: true });
  }, [selectedFiles, setValue, name]);

  useEffect(() => {
    if (
      fileValues?.length === 0 &&
      (previewUrls?.length !== 0 || selectedFiles?.length !== 0) &&
      defaultImages?.length === 0
    ) {
      setPreviewUrls([]);
      setSelectedFiles([]);
    }
  }, [defaultImages]);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);

      // Generate preview URLs for new files only
      const newPreviewUrls = newFiles.map((file) => URL.createObjectURL(file));
      setPreviewUrls((prevUrls) => [...prevUrls, ...newPreviewUrls]);
    }
  };

  const handleRemoveImage = (indexToRemove: number) => {
    setSelectedFiles((currentFiles) =>
      currentFiles.filter((_, index) => index !== indexToRemove)
    );
    setPreviewUrls((currentUrls) =>
      currentUrls.filter((_, index) => index !== indexToRemove)
    );
    // Revoke URL to free up resources
    URL.revokeObjectURL(previewUrls[indexToRemove]);
  };

  return (
    <div className="mb-4">
      {label && (
        <label
          htmlFor={name}
          className="text-xl block leading-6 font-semibold tracking-tight font-Urbanist text-white pb-3"
        >
          {label}
        </label>
      )}{" "}
      <input
        type="file"
        multiple
        ref={fileInputRef}
        onChange={handleFileSelect}
        style={{ display: "none" }}
      />
      <div className="flex flex-wrap gap-4">
        {previewUrls.map((url, index) => (
          <div
            key={index}
            className="w-[103px] h-[101px] relative rounded-lg overflow-hidden"
          >
            <img
              src={url}
              alt={`Preview ${index}`}
              className="w-full h-full object-cover"
            />
            <button
              type="button"
              onClick={() => handleRemoveImage(index)}
              className="absolute top-0 right-0"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="5.5" cy="5.49976" r="5.5" fill="#798BA7" />
                <path
                  d="M7.99361 3.02095C6.62864 1.65936 4.38869 1.65936 3.02373 3.02095C1.65876 4.38255 1.65876 6.61696 3.02373 7.97856C4.38869 9.34015 6.59364 9.34015 7.95861 7.97856C9.32357 6.61696 9.35857 4.38255 7.99361 3.02095ZM6.48864 6.96609L5.50867 5.98853L4.52869 6.96609L4.0387 6.47731L5.01868 5.49976L4.0387 4.5222L4.52869 4.03342L5.50867 5.01098L6.48864 4.03342L6.97863 4.5222L5.99865 5.49976L6.97863 6.47731L6.48864 6.96609Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        ))}

        <div className="oulineGradiant cursor-pointer p-2">
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            className="w-[101px] h-[85px] flex items-center justify-center rounded-lg bg-[#E1F0FF] font-roboto text-base leading-tight tracking-none"
          >
            + Add Images
          </button>
        </div>
      </div>
    </div>
  );
};

export default MultipleImageUpload;
