export const LogOutIcon = (props: { iconcolor: string }) => (
  <>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.1956 2.88C16.4976 2.88 20.7956 7.17807 20.7956 12.48C20.7956 17.7819 16.4976 22.08 11.1956 22.08M8.79557 16.32L4.95557 12.48M4.95557 12.48L8.79557 8.64001M4.95557 12.48H16.9556"
        // stroke="#B8C0CC"
        stroke={props.iconcolor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);
