import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

interface SelectProps {
  control: any; // Control object from react-hook-form
  name: string; // Name of the field
  options: { value: string | number; label: string }[]; // Options for the select
  label: string; // Label for the select field
  className?: string; // Additional className for styling
  fromRecommendationCriteria?: boolean;
  fromCelebGender?: boolean;
  handleRatioChange?: any;
  recommendationCriteriaIndex?: number;
  required?: boolean;
  errorMessage?: string;
}

interface OptionType {
  value: string | number;
  label: string;
}

const CustomSelect: React.FC<SelectProps> = ({
  control,
  name,
  options,
  label,
  className,
  fromRecommendationCriteria,
  handleRatioChange,
  fromCelebGender,
  recommendationCriteriaIndex,
  required,
  errorMessage,
  ...rest
}) => {
  // Define custom styles for react-select component here
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
      border: "1px solid #FFFFFF4D",
      minHeight: "48px",
      color: "white",
      backgroundColor: "transparent",
      ...provided.style,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "white",
      ...provided.style,
    }),
    multiValue: (provided: any) => ({
        ...provided,
        background: "transparent",
        border: '1px solid #FFFFFF4D',
        color: 'white !important',
        borderRadius: '8px',
        ...provided.style,
      }),
    MultiValueGeneric: (provided: any) => ({
        ...provided,
        color: "white",
        ...provided.style,
      }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#FFFFFF7A",
      ...provided.style,
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: "transparent",
      ...provided.style,
    }),
    option: (provided: any) => ({
      ...provided,
      backgroundColor: "#0f4581",
      color: "#ffffff",
      cursor: "pointer",
      ...provided.style,
    }),
  };


  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={`text-xl block leading-6 font-semibold tracking-tight font-Urbanist text-white pb-3`}
      >
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        rules={required ? { required: errorMessage } : {}}
        render={({ field, fieldState: { error } }) => (
          <>
          <Select
            {...field}
            options={options}
            onChange={(selectedOption) => {
              if (fromRecommendationCriteria) {
                field.onChange(selectedOption); // This informs react-hook-form of the change
                if (handleRatioChange) {
                  handleRatioChange(
                    selectedOption?.value,
                    recommendationCriteriaIndex
                  ); // This updates the local component state
                }
              } else {
                field.onChange(selectedOption);
              }
            }}
            styles={customStyles}
            classNamePrefix="custom-select"
            {...rest}
          />
       {error && <p className="mt-1 text-sm text-red-500">{error.message}</p>}
          </>
        )}
      />
    </div>
  );
};

export default CustomSelect;
