import React, { useEffect, useRef, useState } from "react";
import TableComponent from "../../components/Dashboard/TableComponent";
import Loading from "../../components/Loading";
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import { useDelete, useViewGet } from "../../services/Api/general";
import { DropdownActions } from "../../components/Dashboard/dropDownTable";
import moment from "moment";
import { TableColumn } from "react-data-table-component";
import Modal from "../../components/Common/Modal";

interface DataRow {
  id: number;
  title: string;
  category: string;
  created_at: string;
  row: any;
  is_general: boolean;
  index: number
}



const Recommendations: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedArray, setSortedArray] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRecomendationId, setSelectedRecomendationId] = useState<number | null>(null);

  const rowsPerPage = 10;
  const recoPageRef = useRef<HTMLDivElement>(null);


  const {
    isLoading,
    data: AllRecomendation,
    refetch,
  } = useViewGet("AllRecomendation", `recommendations?page=${currentPage}&limit=${rowsPerPage}`, "");
  
  const { mutateAsync: deleteService } = useDelete();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (AllRecomendation) {
      const initialSort = AllRecomendation?.data?.allRecommendations
        ?.map((recomendation: any, index: number) => ({ ...recomendation, index: (currentPage - 1) * 10 + index + 1 }));

      setSortedArray(initialSort);
    }
  }, [AllRecomendation]);

  useEffect(() => {
    refetch();
    if (recoPageRef.current) {
      recoPageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]);


  const handleDeleteRecomendation = async () => {
    if (selectedRecomendationId !== null) {
      try {
        const response = await deleteService({
          route: "recommendations",
          id: selectedRecomendationId,
        });

        if (response.error === false) {
          refetch();
        } else if (response.error) {
          console.error("Error posting data:", response);
        } 
      } catch (error) {
        console.error("Error posting data:", error);
      }
      // After deleting the user, hide the modal and clear the selected user ID
      setShowDeleteModal(false);
      setSelectedRecomendationId(null);
    }
  };

  const confirmDelete = (recomendationId: number) => {
    setSelectedRecomendationId(recomendationId);
    setShowDeleteModal(true);
  };

  const columns: TableColumn<DataRow>[] = [
    {
      name: "No#",
      selector: (row: DataRow) => `${row.index}`,
      sortable: true,
      width: "15%",
    },
    {
      name: "Title",
      selector: (row: DataRow) => row.title,
      sortable: true,
      width: "32%",
    },
    {
      name: "Category",
      cell: (row) => <div>{row.is_general ? "Articles" : "Tips"}</div>,
      sortable: true,
      width: "18%",
    },
    {
      name: "Date",
      selector: (row: DataRow) => moment(row.created_at).format("DD/MM/YYYY"),
      sortable: true,
      width: "20%",
    },
    {
      name: "Action",
      width: "15%",
      cell: (row, rowIndex) => (
        <DropdownActions row={row} rowIndex={rowIndex} totalRows={10} actions="view,edit,delete" onConfirmDelete={confirmDelete}/>
      ),
    },
  ];

  return (
    <>
    <div className="bg-transparent" ref={recoPageRef}>
      <GenericRowComponent
        heading="Recommendations"
        buttonLink="add"
        buttonLabel="+ Add New"
      />
      <div className="bg-medium-gray w-full rounded-default p-4 pt-0">
        {isLoading ? (
          <div className=" absolute inset-0 flex items-center justify-center">
          <Loading />
          </div>
        ) : (
          <TableComponent
            columns={columns}
            data={sortedArray}
            totalRows={AllRecomendation?.data?.total || 0}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            progressPending={isLoading}
          />
        )}
      </div>
    </div>
    {showDeleteModal && selectedRecomendationId && (
        <>
          <Modal
            isOpen={showDeleteModal ? true : false}
            title="Are you sure that you want to delete?"
            // message="You won't be able to revert this!"
            onConfirm={handleDeleteRecomendation}
            onCancel={() => setShowDeleteModal(false)}
          />
        </>
      )}
    </>
  );
};

export default Recommendations;
