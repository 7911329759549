import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/store'; // Adjust the path to your store file
import { getAccessToken, getRefreshToken, removeTokens } from '../../services/tokenHandler'; // Adjust the path to your token handling services

type AuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  // Check if the user is logged in (by checking user data or tokens), otherwise redirect or perform other actions
  if (user?.data && getAccessToken() && getRefreshToken()) {
    navigate('/');  // or perform a redirect to login page or another appropriate action
  }

  // If the checks pass, render the children components
  return <>{children}</>;
};

export default AuthLayout;
