import React from 'react';
import { Login } from '../../components/Auth/Login';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store';


const LoginPage: React.FC = () => {
  const user = useSelector((state: RootState) => state.user);
  if (user?.data) {
    return <Navigate to="/" replace />;
  }
  return (
    <div>
      <Login /> 
    </div>
  );
};

export default LoginPage;
