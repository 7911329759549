import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import ActionButtons from "../../components/Common/actionButtons";
import { useEdit, useImageUpload, useView } from "../../services/Api/general";
import ImageUpload from "../../components/Common/ImageUpload";
import InfluencerFieldSection from "../../components/influencerManagement/basicForm";
import InfluencerPlatefroms from "../../components/influencerManagement/plateformEntry";
import showToast from "../../components/Common/toastNotifications";
import moment from "moment";
import { useEffect } from "react";

const tireOptions = [
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
];

interface InfluencerData {
  name: string;
  email: string;
  description: string;
  profile_image: string;
  tier: number;
  platform_promo_code: Platform[];
}

type Platform = {
  name: "tiktok" | "youtube" | "facebook" | "instagram";
  promoCode?: string;
  expiryDate?: string;
  free_scans?: number;
  is_active?: boolean;
};

type FormData = {
  name: string;
  email: string;
  password: string;
  profile_image: string;
  description: string;
  tier: number | any;
  platforms: Platform[] | any;

};

type Params = {
  id: string;
};

export default function EditIfluencer() {
  const { id } = useParams(); // Get the celebrity ID from the URL

  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();
  const navigate = useNavigate();
  const { mutateAsync: uploadImage } = useImageUpload();
  const { mutateAsync: edit_influencer } = useEdit();

  const { isLoading, data: influencerData } = useView(
    "influencer/getInfluencer",
    id
  );
  useEffect(() => {
    if (influencerData) {
      const { data } = influencerData;

      const influencer = data.influencers[0]; // Assuming the first influencer is the one you want to edit
      const plateforms = data.platform_promo_code;
      // Set simple fields
      setValue("name", data?.name);
      setValue("email", data?.email);
      setValue("description", influencer?.description);
      setValue("profile_image", influencer?.profile_image);
      setValue(
        "tier",
        tireOptions?.find((option: any) => option?.value === influencer.tier)
      );

      plateforms.forEach((platform: any) => {
        const isActiveKey = `${platform.name}_is_active` as keyof FormData;
        const couponCodeKey = `${platform.name}_coupon_code` as keyof FormData;
        const freeScansKey = `${platform.name}_free_scans` as keyof FormData;
        const expiryDateKey = `${platform.name}_expiry_date` as keyof FormData;
      
        setValue(isActiveKey, platform.is_active);
        setValue(couponCodeKey, platform.promoCode);
        setValue(freeScansKey, platform.free_scans);
        setValue(expiryDateKey, moment(platform.expiryDate).format("YYYY-MM-DD"));
      });
        
    }
  }, [influencerData]);

  const transformFormData = async (formData: any) => {
    // Using any for simplification; ideally, you'd create a more specific type.
    const platforms = ["tiktok", "youtube", "facebook", "instagram"];
    const file = formData.profile_image[0];

    const apiData = {
      name: formData.name,
      email: formData.email,
      password: formData?.password,
      description: formData.description,
      profile_image: 
          typeof formData.profile_image === "string"
          ? formData.profile_image
          : formData.profile_image[0]
          ? await uploadImage({
            file,
            route: "influencer/getPreSignedUrl",
          })
          : formData.profile_image,
       // Handle image URL after upload
      tier: formData.tier.value, // Assuming you want the numeric value
      platforms: platforms.map((platform) => ({
        // Simplified for illustration
        name: platform,
        promoCode: formData[`${platform}_coupon_code`],
        expiryDate: formData[`${platform}_expiry_date`]
          ? moment(formData[`${platform}_expiry_date`]).format("DD-MM-YYYY")
          : moment().format("DD-MM-YYYY"),
        free_scans: formData[`${platform}_free_scans`]
          ? parseInt(formData[`${platform}_free_scans`])
          : 0,
        is_active: formData[`${platform}_is_active`],
      })), 
    };

    return apiData;
  };

  const onSubmit = async (formData: FormData) => {
    if (!formData?.profile_image?.length) {
      setError("profile_image", {
        type: "manual",
        message: "Please select an image to upload.",
      });
      return;
    }

    try {
      debugger
      const transformedData = await transformFormData(formData);
      debugger
      const response = await edit_influencer({
        route: `influencer/editInfluencer/${id}`,
        data: transformedData,
      });
      if (!response.error) {
        showToast(
          response?.message || "Influencer Created Successfully!",
          "success"
        );
        navigate("/influencer");
      } else {
        showToast(
          response?.message || "Influencer not Created, please try again.",
          "error"
        );
      }
    } catch (error: any) {
      console.error("Failed to submit form", error);
      showToast(
        error?.message || "Influencer not Created, please try again.",
        "error"
      );
    }
  };

  const profileImage = watch("profile_image");

  return (
    <>
      <div className="p-4 bg-transparent">
        <GenericRowComponent heading="Edit Influencer" showBackButton />
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <div className="mb-4 mt-2">
            <ImageUpload
              setValue={setValue}
              watch={watch}
              name="profile_image"
              errors={errors}
              clearErrors={clearErrors}
              defaultImage={profileImage}
              formEdit
            />
          </div>

          <InfluencerFieldSection
            register={register}
            errors={errors}
            control={control}
            tireOptions={tireOptions}
            fromEdit
          />
          <InfluencerPlatefroms
            register={register}
            errors={errors}
            watch={watch}
          />

          <ActionButtons
            isSubmitting={isSubmitting}
            submitButtonText={"Update"}
          />
        </form>
      </div>
    </>
  );
}
