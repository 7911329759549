import React from "react";
import Input from "../Common/Input";
import moment from "moment";

interface InputSectionProps {
  register: any;
  errors: any;
}

const CouponFieldSection: React.FC<InputSectionProps> = ({
  register,
  errors,
}) => {
  return (
    <div>
      <div className="flex flex-row justify-between gap-3">
        <Input
          label="Coupon Code:"
          name="code"
          {...register("coupon_code", { required: true })}
          error={errors.coupon_code && "Code is required."}
          className="w-full"
          placeholder="Add Coupon Code"
          errorString="!text-red-300"
        />
        <Input
          label="Free Scans:"
          name="scans"
          {...register("free_scans", { required: true,
            min: {
              value: 1,
              message: "Scans must be greater than 0"
            } })}
          error={errors.free_scans && (errors.free_scans?.message ? errors.free_scans?.message : "Scans is required.")}
          className="w-full"
          type="number"
          placeholder="Add Free scan"
          errorString="!text-red-300"
          // min={0}
        />

        <Input
          label="Expiry Date:"
          name="exp_name"
          {...register("expiry_date", {
            required: "Expire date is required.",
            min: {
              value: moment().format('YYYY-MM-DD'), // Generates today's date in YYYY-MM-DD format
              message: "Date cannot be before today"
            },
            max: {
              value: '3000-01-01',
              message: "Date cannot be after January 1, 3000"
            }
          })}
          error={errors.expiry_date && errors?.expiry_date?.message}
          type="date"
          placeholder="Add Date"
          className="w-full"
          errorString="!text-red-300"
        />
      </div>
    </div>
  );
};

export default CouponFieldSection;
