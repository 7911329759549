import React, { useEffect, useState } from "react";
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import Loading from "../../components/Loading";
import ProfileView from "../../components/Dashboard/profileSectionWithImage";
import { useView } from "../../services/Api/general";
import { useParams } from "react-router-dom";
import { ProfileCard } from "../../components/Common/profileCard";

import SeclectedProfileRecord from "../../components/Results/SelectedProfileRecord";

// User data type definition
interface Profile {
  id: number;
  name: string;
  scan_count: number;
  age?: number;
  fcm_token?: string;
  avatar?: string;
  referral_code?: string;
  is_active: boolean;
  default_profile: boolean;
  incentive_task_streak: number;
  referrals_count: number;
  referral_incentive_streak: number;
  incentive_scans_earned: number;
  created_at: string;
  updated_at: string;
}

interface User {
  id: number;
  name: string;
  subscription_status: string;
  role: string;
  email: string;
  profiles: Profile[];
  // ... other user fields
}

const ResultViewSingleUserScans: React.FC = () => {
  const { id } = useParams();
  const { isLoading, data: UserData } = useView("user/getUser", id);

  const [selectedProfile, setSelectedProfile] = useState<Profile | null>();
  const [SelectedScanID, setSelectedScanID] = useState<number | undefined>();


  useEffect(() => {
    if (UserData?.data?.profiles?.length > 0) {
      setSelectedProfile(UserData?.data?.profiles[0]);
    }
  }, [UserData]);

  const handleProfileClick = (profile: Profile) => {
    setSelectedProfile(profile);
    setSelectedScanID(undefined);
  };

  return (
    <div className="p-4 bg-transparent">
      <GenericRowComponent heading="View Results" showBackButton />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-row justify-start gap-4 mt-8">
            {UserData?.data?.profiles?.map((profile: any) => (
              <ProfileCard
                key={profile.id}
                name={profile.name}
                email={UserData?.data?.email}
                imageUrl={profile.avatar || "/assets/images/square-user.png"}
                onClick={() => handleProfileClick(profile)}
                selectedUser={
                  selectedProfile?.id === profile?.id ? true : false
                }
              />
            ))}
          </div>
          {selectedProfile && (
            <>
            <div className="selected-profile-details">
            {/* Render more details of the selected profile */}
            <div className={`mt-4 rounded-lg py-6`}>
              <ProfileView
                imageUrl={
                  selectedProfile?.avatar || "/assets/images/square-user.png"
                }
                name={selectedProfile?.name}
                email={UserData?.data?.email}
              />
            </div>
          </div>
            <SeclectedProfileRecord
              SelectedProfile={selectedProfile}
              setSelectedScanID={setSelectedScanID}
              SelectedScanID={SelectedScanID}
            />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ResultViewSingleUserScans;
