export const BellIcon = (props: { iconcolor: string }) => (
  <>
    
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.5 21H15.25V20.25H14.5V21ZM10.5 21V20.25H9.75V21H10.5ZM19 16H18.25V16.3107L18.4697 16.5303L19 16ZM14 4.18H13.25V4.77306L13.8271 4.9098L14 4.18ZM11 4.18L11.1729 4.9098L11.75 4.77306V4.18H11ZM6 16L6.53033 16.5303L6.75 16.3107V16H6ZM4 18L3.46967 17.4697L3.25 17.6893V18H4ZM4 19H3.25V19.75H4V19ZM21 19V19.75H21.75V19H21ZM21 18H21.75V17.6893L21.5303 17.4697L21 18ZM12.5 23.75C14.0142 23.75 15.25 22.5142 15.25 21H13.75C13.75 21.6858 13.1858 22.25 12.5 22.25V23.75ZM14.5 20.25H10.5V21.75H14.5V20.25ZM9.75 21C9.75 22.5142 10.9858 23.75 12.5 23.75V22.25C11.8142 22.25 11.25 21.6858 11.25 21H9.75ZM19.75 16V10.5H18.25V16H19.75ZM19.75 10.5C19.75 7.07364 17.3726 4.20831 14.1729 3.4502L13.8271 4.9098C16.3674 5.51169 18.25 7.78636 18.25 10.5H19.75ZM14.75 4.18V3.5H13.25V4.18H14.75ZM14.75 3.5C14.75 2.25579 13.7442 1.25 12.5 1.25V2.75C12.9158 2.75 13.25 3.08421 13.25 3.5H14.75ZM12.5 1.25C11.2558 1.25 10.25 2.25579 10.25 3.5H11.75C11.75 3.08421 12.0842 2.75 12.5 2.75V1.25ZM10.25 3.5V4.18H11.75V3.5H10.25ZM10.8271 3.4502C7.62744 4.20831 5.25 7.07364 5.25 10.5H6.75C6.75 7.78636 8.63256 5.51169 11.1729 4.9098L10.8271 3.4502ZM5.25 10.5V16H6.75V10.5H5.25ZM5.46967 15.4697L3.46967 17.4697L4.53033 18.5303L6.53033 16.5303L5.46967 15.4697ZM3.25 18V19H4.75V18H3.25ZM4 19.75H21V18.25H4V19.75ZM21.75 19V18H20.25V19H21.75ZM21.5303 17.4697L19.5303 15.4697L18.4697 16.5303L20.4697 18.5303L21.5303 17.4697Z"
        // fill="#B8C0CC"
        fill={props.iconcolor}
      />
    </svg>
  </>
);
