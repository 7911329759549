import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../redux/userSlice";
import showToast from "../Common/toastNotifications";
import {
  setAccessToken,
  setRefreshToken,
  setUserRole,
} from "../../services/tokenHandler";
import Input from "../Common/Input";
import PasswordInput from "../Common/PasswordInput";
import LoginPageHeader from "./AuthLeftPage";

type LoginFormInputs = {
  email: string;
  password: string;
  rememberMe?: boolean;
};

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormInputs>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const onSubmit = async (data: any) => {
    try {
      const response = await fetch(`${baseURL}/api/dashboard/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (!result.error) {
        showToast("Login successful!", "success");
        dispatch(setUser(result.data.user));
        setAccessToken(result.data.accessToken);
        setRefreshToken(result.data.refreshToken);
        setUserRole(result.data.user.role);
        navigate("/");
      } else {
        showToast(result.message || "Login failed, please try again.", "error");
      }
    } catch (error: any) {
      showToast(error.message || "An unexpected error occurred.", "error");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-between gap-4 px-14 py-16 add_backgrounndAtBottom w-full">
      <LoginPageHeader
        leftImageUrl="/assets/images/demoManwithRating.png"
        rightImageUrl="/assets/images/demoWomanwithRating.png"
      />
      <div className="md:w-1/2 py-6 px-10 md:px-24 w-full flex justify-center">
        <div className="max-w-[90%] w-full">

          <span className="flex w-[125px] h-[55px] justify-center items-start font-['Urbanist'] text-[46px] font-bold leading-[55px] text-[#fff] absolute top-[130px] left-[calc(50%--287px)] text-center whitespace-nowrap z-10">
            Log In
          </span>
          <span className="flex h-[24px] justify-start items-start font-['Urbanist'] text-[16px] font-light leading-[24px] text-[#fff] absolute top-[193px] left-[calc(50%--263px)] text-left whitespace-nowrap z-[18]">
            Please Login the account
          </span>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-6 bg-transparent ml-8 rounded-lg "
          >
            <div className="space-y-4">
              
              <Input
                // name='email'
                type="text"
                label="Email address:"
                autoComplete="off"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                error={errors?.email && errors?.email?.message}
                className="w-full lg:h-18 "
                placeholder="Email address:"
              />
              <PasswordInput
                label="Password:"
                {...register("password", {
                  required: "Password is required",
                  pattern: {
                    // This regex checks for at least one uppercase letter, one lowercase letter, one digit, and a minimum length of 8
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                    message:
                      "Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, and 1 number.",
                  },
                })}
                error={errors.password && errors.password.message}
                className="w-full lg:h-18 "
                placeholder="*********"
              />
              

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    type="checkbox"
                    {...register("rememberMe")}
                    className="h-4 w-4"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 font-['Urbanist'] text-[16px] font-medium leading-[19px] text-[#dfdfdf] "
                  >
                    Keep me logged in
                  </label>
                </div>

                {/* <a
                  href="#"
                  className="font-['Urbanist'] text-[16px] font-semibold leading-[19px] text-[#d3d3d3]"
                >
                  Forgot Password?
                </a> */}
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600 text-white p-3 rounded-lg  md:mt-5 font-medium"
            >
              Log In
            </button>

          
          </form>
        </div>
      </div>
    </div>
  );
};
