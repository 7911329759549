import React from "react";
import Input from "../Common/Input";
import { Controller } from "react-hook-form";
import Select from "react-select";
import CustomSelect from "../Common/customSelect";

interface InputSectionProps {
  register: any;
  errors: any;
  control: any;
  genderOptions: any;
}

const CelebFieldSection: React.FC<InputSectionProps> = ({
  register,
  errors,
  control,
  genderOptions,
}) => {
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
      border: "1px solid #FFFFFF4D",
      minHeight: '48px',
      color:'white',
      backgroundColor: 'transparent',
      ...provided.style,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'white',
      ...provided.style,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#FFFFFF7A',
      ...provided.style,
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: 'transparent',
      ...provided.style,
    }),
    option: (provided: any) => ({
      ...provided,
      backgroundColor: '#FFFFFF0F',
      cursor: 'pointer',
      ...provided.style,
    }),
  };

  return (
    <div>
      <div className="flex flex-row justify-between gap-3">
        <Input
          label="Name:"
          name="name"
          {...register("name", { required: true })}
          error={errors.name && "Name is required."}
          className="w-full"
          placeholder="Add name"
        />
        <Input
          label="Profession:"
          name="profession"
          {...register("profession", { required: true })}
          error={errors.profession && "Profession is required."}
          className="w-full"
          placeholder="Add profession"
        />


        <CustomSelect
              control={control}
              name={`gender`}
              options={genderOptions}
              label="Gender:"
              className={"w-full"}
              fromCelebGender
              required={true}
              errorMessage="Gender is required."
            />
      </div>

      <div className="flex mt-4 lg:w-[67%] w-full flex-row justify-start gap-x-5">
        <Input
          label="Min Score:"
          name="min_score"
          {...register("min_score", { required: true })}
          error={errors.min_score && "Min Score is required."}
          step={0.1}
          type="number"
          placeholder="Add min score"
          className="w-full"
        />
        <Input
          label="Max Score:"
          name="max_score"
          {...register("max_score", { required: true })}
          error={errors.max_score && "Max Score is required."}
          placeholder="Add max score"
          step={0.1}
          type="number"
          className="w-full"
        />
      </div>
    </div>
  );
};

export default CelebFieldSection;
