import React, { useEffect, useState } from "react";
import TableComponent from "../../../components/Dashboard/TableComponent";
import GenericRowComponent from "../../../components/Dashboard/genericHeaderRow";
import { DropdownActions } from "../../../components/Dashboard/dropDownTable";
import { TableColumn } from "react-data-table-component";
import { useViewGet } from "../../../services/Api/general";
import Loading from "../../../components/Loading";

// Assuming this is the structure of your influencer data
interface Influencer {
  id: number;
  name: string;
  email: string;
  index: number;
  totalFreeScans: string;
  influencers: Array<{
    profile_image: string;
    tier: number;
  }>;
}

const InfluencerTable: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortedArray, setSortedArray] = useState([]);

  const rowsPerPage = 10;
  const {
    isLoading,
    data: AllInfluencers,
    refetch,
  } = useViewGet("AllInfluencers", "/influencer/", "");

  // const {
  //   isLoading,
  //   data: AllInfluencers,
  //   refetch,

  // } = useViewGet("AllInfluencers", `/influencer?page=${currentPage}&limit=${rowsPerPage}&search=${searchQuery}`, "");

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };


  // Initial fetch and sort operation
  useEffect(() => {
    if (AllInfluencers) {
      const initialSort = AllInfluencers?.data
        // ?.sort((a: any, b: any) => b.id - a.id)
        ?.map((influ: any, index: number) => ({ ...influ, index: index + 1 }));

      setSortedArray(initialSort);
    }
  }, [AllInfluencers, currentPage]);

  const handleSearch = (value: string) => {
    const filteredArray = AllInfluencers?.data
      ?.filter((influencer: any) => {
        const influencerIndex = influencer?.index?.toString();
        const influencerName = influencer?.name?.toLowerCase();
        const influencerEmail = influencer?.email?.toLowerCase();
        const searchLower = value?.toLowerCase();

        return (
          influencerIndex?.includes(searchLower) ||
          influencerName?.includes(searchLower) ||
          influencerEmail?.includes(searchLower)
        );
      })
      ?.map((influ: any, index: number) => ({ ...influ, index: index + 1 }));

    setSortedArray(filteredArray);
  };

  const columns: TableColumn<Influencer>[] = [
    {
      name: "No#",
      selector: (row) => row.index.toString(),
      sortable: true,
      width: "10%",
    },
    {
      name: "Name",
      width: "25%",
      cell: (row) => (
        <div className="flex items-center">
          <img
            src={
              row.influencers[0]?.profile_image ||
              "/assets/images/user-avatar.png"
            }
            alt={row.name}
            style={{ width: 30, height: 30, borderRadius: "50%" }}
          />
          <span className="ml-2">{row.name}</span>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "20%",
    },
    {
      name: "Tier",
      // selector: (row) => row.influencers[0]?.tier,
      cell: (row) => (
        <div
          className={`flex flex-row justify-center items-center px-3 py-1 capitalize text-white font-Urbanist text-base font-normal leading-[18px] rounded-lg bg-[rgba(52,176,255,0.6)]`}
        >
          Tier {row.influencers[0]?.tier}
        </div>
      ),
      sortable: true,
      width: "15%",
    },

    {
      name: "Total Scans",
      // selector: (row) => row.influencers[0]?.tier,
      cell: (row) => (
        <div
          className={`flex flex-row justify-center items-center px-3 py-1 capitalize text-white font-Urbanist text-base font-normal leading-[18px] rounded-lg `}
        >
          {row?.totalFreeScans || 0}
        </div>
      ),
      sortable: true,
      width: "15%",
    },
    {
      name: "Action",
      width: "20%",
      cell: (row, rowIndex) => (
        <DropdownActions
          row={row}
          rowIndex={rowIndex}
          totalRows={10}
          actions={"view"}
        />
      ),
    },
  ];

  return (
    <div className="p-4 bg-transparent">
      <GenericRowComponent
        heading="Influencer"
        showInput
        onInputChange={handleSearch}
      />
      <div className="w-full h-full">
        {isLoading ? (
          <div className=" absolute inset-0 flex items-center justify-center">
          <Loading />
          </div>
        ) : (
          <TableComponent
            columns={columns}
            data={sortedArray}
            totalRows={sortedArray.length}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            progressPending={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default InfluencerTable;
