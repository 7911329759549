// Layout.tsx
import React from "react";
import Header from "./Dashboard/Header";
import Sidebar from "./Dashboard/Sidebar";

interface LayoutProps {
  children: React.ReactNode;
  user: { name: string } | null;
}

const Layout: React.FC<LayoutProps> = ({ children, user }) => {
  return (
    <div className="flex flex-row w-full h-screen min-h-screen max-h-screen add_backgrounndAtBottom">
      {user && (
        <div className="w-[18%] bg-transparent h-screen overflow-hidden">
          <Sidebar />
        </div>
      )}
      <div
        className={`${
          user ? "w-[82%]" : "w-full"
        } h-full min-h-screen max-h-screen bg-transparent relative`}
      >
        {user && <Header />}
        <main className={`relative bg-transparent w-[97%] mx-auto overflow-auto  ${user && 'h-[calc(100%-6rem)]'}`}>
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
