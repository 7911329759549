import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import ActionButtons from "../../components/Common/actionButtons";
import { useCreate, useImageUpload } from "../../services/Api/general";
import ImageUpload from "../../components/Common/ImageUpload";
import InfluencerFieldSection from "../../components/influencerManagement/basicForm";
import InfluencerPlatefroms from "../../components/influencerManagement/plateformEntry";
import showToast from "../../components/Common/toastNotifications";
import moment from "moment";

type Platform = {
  name: "tiktok" | "youtube" | "facebook" | "instagram"; // If these are the only options, otherwise use string
  promoCode: string;
  expiryDate: string; // Assuming dates are in 'DD-MM-YYYY' format as strings
  free_scans: number;
  is_active: boolean;
};

type FormData = {
  name: string;
  email: string;
  password: string;
  profile_image: string; // URL to the image
  description: string;
  tier: number;
  platforms: Platform[];
};

const tireOptions = [
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
];

export default function AddIfluencer() {
  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();
  const navigate = useNavigate();
  const { mutateAsync: uploadImage } = useImageUpload();
  const { mutateAsync: create } = useCreate();


  const transformFormData = async (formData: any) => {
    // Using any for simplification; ideally, you'd create a more specific type.
    const platforms = ["tiktok", "youtube", "facebook", "instagram"];
    const file = formData.profile_image[0];

    const apiData = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      description: formData.description,
      profile_image: await uploadImage({
        file,
        route: "influencer/getPreSignedUrl",
      }), // Handle image URL after upload
      tier: formData.tier.value, // Assuming you want the numeric value
      platforms: platforms.map((platform) => ({
        // Simplified for illustration
        name: platform,
        promoCode: formData[`${platform}_coupon_code`],
        expiryDate: formData[`${platform}_expiry_date`] ? moment(formData[`${platform}_expiry_date`]).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY"),
        free_scans: formData[`${platform}_free_scans`] ? parseInt(formData[`${platform}_free_scans`]) : 0,
        is_active: formData[`${platform}_is_active`],
      })), // Filter out inactive platforms
    };

    return apiData;
  };

  const onSubmit = async (formData: FormData) => {
    if (!formData?.profile_image?.length) {
      setError("profile_image", {
        type: "manual",
        message: "Please select an image to upload.",
      });
      return;
    }

    try {
      const transformedData = await transformFormData(formData);
      const response = await create({
        route: "influencer/createInfluencer",
        data: transformedData,
      });
      if (!response.error) {
        showToast(response?.message || 'Influencer Created Successfully!' , 'success');
        navigate("/influencer");
      } else {
        showToast(response.message || 'Influencer not Created, please try again.', 'error');
    }
} catch (error: any) {
    console.error("Failed to submit form", error);
    showToast(error?.message || 'Influencer not Created, please try again.', 'error');
    }
  };

  return (
    <>
      <div className="p-4 bg-transparent">
        <GenericRowComponent heading="Add Influencer" showBackButton />
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <div className="mb-4 mt-2">
            <ImageUpload
              setValue={setValue}
              watch={watch}
              name="profile_image"
              errors={errors}
              clearErrors={clearErrors}
            />
          </div>

          <InfluencerFieldSection
            register={register}
            errors={errors}
            control={control}
            tireOptions={tireOptions}
          />
          <InfluencerPlatefroms
            register={register}
            errors={errors}
            watch={watch}
          />

          <ActionButtons
            isSubmitting={isSubmitting}
            submitButtonText={"Add Now"}
          />
        </form>
      </div>
    </>
  );
}
