// @ts-nocheck

import React, { useState, useEffect } from "react";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import ImageUpload from "../../components/Common/ImageUpload";
import Input from "../../components/Common/Input";
import TextArea from "../../components/Common/Textarea";
import MultipleImageUpload from "../../components/Common/MultiImageUpload";
import { useImageUpload } from "../../services/Api/general";
import TextEditor from "../../components/Common/TextEditor";
import CustomSelect from "../../components/Common/customSelect";
import Cookies from "js-cookie";

const AddForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [imageUrls, setImageUrls] = useState([]);
  const [recommendations, setRecommendations] = useState(false);
  const [races, setRaces] = useState([]);
  const [genders, setGenders] = useState([]);
  const [ratios, setRatios] = useState([]);

  const token = Cookies.get("facebloom-dashboard");

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      criterias: [
        {
          race: [],
          gender: [],
          ratioType: "",
          ratioValue: [],
          min_value: "",
          max_value: "",
        },
      ],
    },
  });
  const { mutateAsync: uploadImage } = useImageUpload();

  const {
    fields: criteriaFields,
    append: appendCriteria,
    remove: removeCriteria,
  } = useFieldArray({
    control,
    name: "criterias",
  });

  const {
    fields: recommendationFields,
    append: appendRecommendation,
    remove: removeRecommendation,
  } = useFieldArray({
    control,
    name: "child_recommendations",
  });

  const watchedCriterias = watch("criterias");

  const baseURL = process.env.REACT_APP_API_BASE_URL; // Ensure your API base URL is correctly set in your environment variables

  async function uploadImagesSequentially(files: File[]): Promise<string[]> {
    const urls: string[] = [];
    if (files?.length > 0) {
      for (const file of files) {
        try {
          // Assuming uploadImage is an async function that uploads a file and returns a URL
          const url = await uploadImage({
            file,
            route: "recommendations/getPreSignedUrl",
          });
          if (url) {
            urls.push(url); // Add the URL to the array if the upload was successful
          }
        } catch (error) {
          console.error("Upload failed for", file.name, error);
          // Optionally, handle the error, e.g., by adding an error marker or continuing with the next file
        }
      }
    }

    return urls?.length > 0 ? urls.join(",") : undefined; // Return the array of URLs after all files have been processed
  }

  // handleRatioChange should update the form state
  const handleRatioChange = (value, criteriaIndex) => {
    const selectedRatio = ratios.find((ratio) => ratio.name === value);

    // Check if the selected ratio type is numeric
    if (selectedRatio?.is_numeric) {
      // Set numeric value fields
      setValue(`criterias.${criteriaIndex}.ratioValue`, null);
      setValue(`criterias.${criteriaIndex}.min_value`, "");
      setValue(`criterias.${criteriaIndex}.max_value`, "");
    } else {
      // Set non-numeric select field
      setValue(`criterias.${criteriaIndex}.ratioValue`, []);
      setValue(`criterias.${criteriaIndex}.min_value`, null);
      setValue(`criterias.${criteriaIndex}.max_value`, null);
    }
  };

  // renderDependentFields should render fields based on the form state
  // const renderDependentFields = (index) => {
  //   const ratioValueOption = getValues(`criterias.${index}.ratio`);
  //   const selectedRatio = ratios?.find(
  //     (ratio) => ratio.name === ratioValueOption?.value
  //   );
  //   const ratioValue = selectedRatio?.data;

  //   const min_value = getValues(`criterias.${index}.min_value`);
  //   const max_value = getValues(`criterias.${index}.max_value`);

  //   // if (min_value !== null || '' && max_value !== null || '') {
  //   if (min_value !== null && max_value !== null && ratioValue === null) {
  //     // Render numeric input fields
  //     return (
  //       <>
  //         <label
  //           htmlFor={`criterias.${index}.min_value`}
  //           className="block text-sm font-medium text-gray-700"
  //         >
  //           Min Value
  //         </label>
  //         <input
  //           {...register(`criterias.${index}.min_value`)}
  //           placeholder="Min Value"
  //           type="number"
  //           step="0.01"
  //         />
  //         <label
  //           htmlFor={`criterias.${index}.max_value`}
  //           className="block text-sm font-medium text-gray-700"
  //         >
  //           Max Value
  //         </label>
  //         <input
  //           {...register(`criterias.${index}.max_value`)}
  //           placeholder="Max Value"
  //           type="number"
  //           step="0.01"
  //         />
  //       </>
  //     );
  //   } else if (ratioValue && ratioValue.length > 0) {
  //     // Render non-numeric select
  //     return (
  //       <>
  //         <label
  //           htmlFor={`criterias.${index}.ratioValue`}
  //           className="block text-sm font-medium text-gray-700"
  //         >
  //           Ratio Value
  //         </label>
  //         <Controller
  //           name={`criterias.${index}.ratioValue`}
  //           control={control}
  //           render={({ field }) => (
  //             <Select
  //               {...field}
  //               options={ratioValue.map((option) => ({
  //                 value: option,
  //                 label: option,
  //               }))}
  //               isMulti
  //             />
  //           )}
  //         />
  //       </>
  //     );
  //   }

  //   return null;
  // };

  const renderDependentFields = (index) => {
    const ratioValueOption = getValues(`criterias.${index}.ratio`);
    const selectedRatio = ratios?.find(
      (ratio) => ratio.name === ratioValueOption?.value
    );
    const ratioValue = selectedRatio?.data;

    const min_value = getValues(`criterias.${index}.min_value`);
    const max_value = getValues(`criterias.${index}.max_value`);

    // if (min_value !== null || '' && max_value !== null || '') {
    if (min_value !== null && max_value !== null && ratioValue === null) {
      // Render numeric input fields
      return (
        <div className="flex flex-row justify-between gap-2 lg:w-[70%] w-full mt-2">
          <Input
            label="Min Value:"
            name={`criterias.${index}.min_value`}
            placeholder="Min Value"
            {...register(`criterias.${index}.min_value`)}
            type="number"
            step="0.01"
            // error={errors.title && "Title is required."}
            className="w-full"
          />

          <Input
            label="Max Value:"
            name={`criterias.${index}.max_value`}
            placeholder="Max Value"
            {...register(`criterias.${index}.max_value`)}
            type="number"
            step="0.01"
            // error={errors.title && "Title is required."}
            className="w-full"
          />
        </div>
      );
    } else if (ratioValue && ratioValue.length > 0) {
      // Render non-numeric select
      return (
        <div className="max-w-[355px]">
          <CustomSelect
            control={control}
            name={`criterias.${index}.ratioValue`}
            options={ratioValue.map((option) => ({
              value: option,
              label: option,
            }))}
            label="Select Value:"
            className={"w-full"}
            isMulti
          />
        </div>
      );
    }

    return null;
  };

  const [selectedRatio, setSelectedRatio] = useState(null);

  // Watch the value of the ratio select to determine how to render the dependent fields
  const selectedRatioValue = watch("ratio");

  useEffect(() => {
    const matchedRatio = ratios?.find(
      (ratio) => ratio.name === selectedRatioValue
    );
    setSelectedRatio(matchedRatio);
  }, [selectedRatioValue]);

  const transformCriterias = (criterias) => {
    console.log(criterias, "criterias");
    return criterias.map((criteria) => {
      return {
        races: criteria?.race?.map((r) => r.value).join(",") || "",
        genders: criteria?.gender?.map((g) => g.value).join(",") || "",
        is_ratio_numeric:
          criteria.min_value !== null && criteria.max_value !== null,
        ratio_name: criteria?.ratio?.value || null,
        ratio_min_value:
          criteria.min_value === "0"
            ? 0
            : parseFloat(criteria.min_value) || undefined,
        ratio_max_value: parseFloat(criteria.max_value) || undefined,
        ratio_value:
          (criteria &&
            criteria?.ratioValue &&
            criteria?.ratioValue?.length &&
            criteria?.ratioValue?.map((r) => r.value).join(",")) ||
          "",
      };
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const titleImage =
        (data?.title_image &&
          data?.title_image[0] &&
          (await uploadImage({
            file: data?.title_image[0],
            route: "recommendations/getPreSignedUrl",
          }))) ||
        "";
      const iconImage =
        (data?.icon_image &&
          data?.icon_image[0] &&
          (await uploadImage({
            file: data?.icon_image[0],
            route: "recommendations/getPreSignedUrl",
          }))) ||
        "";

      const filteredRecommendations =
        data.child_recommendations.filter(
          (recommendation) => recommendation.title.trim() !== ""
        ) || [];
      const transformedCriterias = transformCriterias(data.criterias);
      const submissionData = {
        ...data,
        criterias: transformedCriterias,
        title_image: titleImage,
        icon_image: iconImage,

        details: data?.details?.startsWith(
          '<p><span style="color: rgb(255, 255, 255);">'
        )
          ? data.details
          : `<span style="color: rgb(255, 255, 255);">${
              data?.details ? data.details : ""
            }</span>`,
        images: await uploadImagesSequentially(data?.images),
        is_personalised_tip: data.is_personalised_tip || false,
        is_general: data.is_general || false,
        child_recommendations: filteredRecommendations,
      };
      console.log(submissionData);
      debugger;
      const response = await axios.post(
        `${baseURL}/api/dashboard/recommendations/`,
        submissionData,
        {
          headers: {
            Authorization: `${token}`
          }
        }
      );
      console.log("Form submitted successfully");
      console.log({ submissionData, response });
      navigate("/recommendations");
    } catch (error) {
      console.error("Failed to submit form", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchRatioTypes = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/dashboard/recommendations/lookUp/getRatioTypes`,
          {
            headers: {
              Authorization: `${token}`
            }
          }
        );
        setRatios(response.data.data);

        const raceIndex = response.data.data.findIndex(
          (ratio) => ratio.name === "race"
        );

        const genderIndex = response.data.data.findIndex(
          (ratio) => ratio.name === "gender"
        );

        setRaces(response.data.data[raceIndex]);
        setGenders(response.data.data[genderIndex]);

        const filteredRatios = response.data.data.filter(
          (ratio) => ratio.name !== "gender" && ratio.name !== "race"
        );

        setRatios(filteredRatios);
      } catch (error) {
        console.error("Failed to fetch ratio types", error);
      }
    };

    fetchRatioTypes();
  }, []);

  useEffect(() => {}, [watchedCriterias]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 my-2 mb-7">
      <div className=" bg-[#FFFFFF1A] rounded-xl mt-4 p-4">
        <div className="flex flex-row items-center justify-start gap-3">
          <label
            htmlFor="is_general"
            className="block  font-Urbanist text-4xl font-semibold leading-[32px] text-left text-white"
          >
            General
          </label>

          <label className="switch">
            <input
              type="checkbox"
              {...register("is_general")}
              // onChange={(e)=>setChat(e.target.checked)}
            />
            <span className="slider"></span>
          </label>
        </div>

        <div className="flex flex-row justify-start gap-5 pt-5">
          <div className="flex flex-col gap-y-1 justify-center">
            <ImageUpload setValue={setValue} watch={watch} name="title_image" />
            <span className="text-white font-Urbanist text-2xl font-medium leading-6 tracking-normal text-center">
              Title Image
            </span>
          </div>
          <div className="flex flex-col gap-y-1 justify-center">
            <ImageUpload setValue={setValue} watch={watch} name="icon_image" />
            <span className="text-white font-Urbanist text-2xl font-medium leading-6 tracking-normal text-center">
              Icon Image
            </span>
          </div>
        </div>

        <div className="flex flex-row justify-between gap-4 w-[80%] pt-5">
          <Input
            label="Title:"
            name="title"
            placeholder="Add title"
            {...register("title", { required: true })}
            error={errors.title && "Title is required."}
            className="w-full"
          />
          <Input
            label="Intro:"
            name="intro"
            placeholder="Add intro"
            {...register("intro")}
            error={errors.intro?.message}
            className="w-full"
          />
        </div>

        <div className="pt-5">
          <TextArea
            label="Description:"
            name="description"
            placeholder="Add description"
            {...register("description")}
            error={errors.description?.message}
            className="w-full"
          />
        </div>

        <div className="mt-5">
          <MultipleImageUpload
            label="Images:"
            setValue={setValue}
            watch={watch}
            name="images"
            register={register}
          />
        </div>
        <div className="mt-5">
          <TextEditor name="details" label="Details" control={control} />
          {errors.details && (
            <p className="text-red-500 text-xs mt-2">Details are required.</p>
          )}
        </div>

        <div className="flex flex-row justify-start items-center gap-5 my-5">
          <label
            htmlFor="is_personalised_tip"
            className="block text-white font-Urbanist text-2xl font-semibold leading-9 tracking-normal"
          >
            Is Personalized:
          </label>
          <label className="switch">
            <input type="checkbox" {...register("is_personalised_tip")} />
            <span className="slider"></span>
          </label>
        </div>
        {criteriaFields.map((field, index) => (
          <div
            key={field.id}
            className="bg-[#FFFFFF1A] p-3 pb-6 rounded-lg mt-1 relative"
          >
            {" "}
            {/* Make sure each field has a unique key */}
            <h1 className=" text-white font-Urbanist text-xl font-semibold leading-9 tracking-normal">
              Criteria: {index + 1}
            </h1>
            <div className="mt-2 flex flex-row justify-between gap-3">
              <CustomSelect
                control={control}
                name={`criterias.${index}.race`}
                options={races?.data?.map((race) => ({
                  value: race,
                  label: race,
                }))}
                label="Races:"
                className={"w-full"}
                isMulti
              />

              <CustomSelect
                control={control}
                name={`criterias.${index}.gender`}
                options={genders?.data?.map((gender) => ({
                  value: gender,
                  label: gender,
                }))}
                label="Gender:"
                className={"w-full"}
                isMulti
              />

              <CustomSelect
                control={control}
                name={`criterias.${index}.ratio`}
                options={ratios?.map((ratio) => ({
                  value: ratio.name,
                  label: ratio.value,
                }))}
                label="Select Ratio:"
                className={"w-full"}
                handleRatioChange={handleRatioChange}
                fromRecommendationCriteria
                recommendationCriteriaIndex={index}
              />
            </div>
            {renderDependentFields(index)}
            <button
              type="button"
              onClick={() => removeCriteria(index)}
              className="remove-criteria-button absolute top-0 right-0"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="5.5" cy="5.49976" r="5.5" fill="#798BA7" />
                <path
                  d="M7.99361 3.02095C6.62864 1.65936 4.38869 1.65936 3.02373 3.02095C1.65876 4.38255 1.65876 6.61696 3.02373 7.97856C4.38869 9.34015 6.59364 9.34015 7.95861 7.97856C9.32357 6.61696 9.35857 4.38255 7.99361 3.02095ZM6.48864 6.96609L5.50867 5.98853L4.52869 6.96609L4.0387 6.47731L5.01868 5.49976L4.0387 4.5222L4.52869 4.03342L5.50867 5.01098L6.48864 4.03342L6.97863 4.5222L5.99865 5.49976L6.97863 6.47731L6.48864 6.96609Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        ))}
        <button
          type="button"
          className="bg-[#75179C] mt-2 px-4 py-2 rounded-md text-white font-Urbanist text-base font-medium leading-5 tracking-normal "
          onClick={() =>
            appendCriteria({
              race: [],
              gender: [],
              ratioType: "",
              ratioValue: [],
              min_value: "",
              max_value: "",
            })
          }
        >
          + Add Criteria
        </button>
      </div>
      <div className="mt-4 flex flex-row justify-start gap-2 flex-wrap gap-y-3">
        {recommendationFields.map((field, index) => (
          <div key={field.id} className="flex items-center space-x-2">
            
            <div className="relative">
              <Input
                name="`child_recommendations.${index}.title`"
                placeholder="Recommendation Title"
                {...register(`child_recommendations.${index}.title`)}
                error={errors.intro?.message}
                className="w-full !border-none"
                showLabel={false}
              />
              <button
                type="button"
                onClick={() => removeRecommendation(index)}
                className="remove-criteria-button absolute top-0 right-0"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="5.5" cy="5.49976" r="5.5" fill="#798BA7" />
                  <path
                    d="M7.99361 3.02095C6.62864 1.65936 4.38869 1.65936 3.02373 3.02095C1.65876 4.38255 1.65876 6.61696 3.02373 7.97856C4.38869 9.34015 6.59364 9.34015 7.95861 7.97856C9.32357 6.61696 9.35857 4.38255 7.99361 3.02095ZM6.48864 6.96609L5.50867 5.98853L4.52869 6.96609L4.0387 6.47731L5.01868 5.49976L4.0387 4.5222L4.52869 4.03342L5.50867 5.01098L6.48864 4.03342L6.97863 4.5222L5.99865 5.49976L6.97863 6.47731L6.48864 6.96609Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        ))}
        <button
          type="button"
          onClick={() => appendRecommendation({ title: "" })}
          className="items-center inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#75179C] focus:outline-none focus:ring-offset-2 focus:ring-0"
        >
          + Add New Recommendation
        </button>
      </div>
      <div className=" flex justify-end items-center w-full">
        <div className="flex flex-row justify-between gap-4 pr-3">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="px-3 py-2 font-Urbanist text-md font-normal leading-[22px] text-left text-[#76e19c] rounded-md"
            style={{
              border: "1px solid #76e19c",

              borderColor:
                "linear-gradient(268.95deg, #3FDFA1 5.53%, #FEE591 91.7%)",
              color: "linear-gradient(268.95deg, #3FDFA1 5.53%, #FEE591 91.7%)",
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`inline-flex justify-center items-center py-2 px-4 font-Urbanist text-md font-normal leading-[22px] text-left border border-transparent shadow-sm  rounded-md text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
            disabled={isSubmitting}
            style={{
              background:
                "linear-gradient(268.95deg, #3FDFA1 5.53%, #FEE591 91.7%)",
            }}
          >
            {isSubmitting ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Processing...
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddForm;
