import React from "react";
import RadialProgressChart from "../Common/chart/chartForResult";

interface GeneralInformationSectionProps {
  score: number;
  potential: number;
  gender: string;
}
// Define colors outside the component if they're static and won't change
const AppColors = {
  greenUpperColor: "rgba(125, 207, 59, 0.9)",
  greenLowerColor: "rgba(182, 202, 66, 0.9)",
  // Add other colors as needed
};
const GeneralInformationSection: React.FC<GeneralInformationSectionProps> = ({
  score,
  gender,
  potential,
}) => {
  return (
    <div className="my-3">
        
      <div className="flex flex-row items-center justify-between gap-2 bg-[#FFFFFF0F] px-5 py-4 rounded-xl">
        <div className=" w-2/5">
            <div className="flex flex-col gap-y-6">
                <div>
                    <p className="font-Urbanist text-xl font-semibold leading-5 text-white text-left">
                    Score
                    </p>
                    <div className="font-Urbanist text-xl font-semibold leading-5 text-white bg-[#FFFFFF1A] w-[142px] text-center px-6 py-5 mt-2 rounded-lg">
                    {score}
                    </div>
                </div>

                <div className="mt-3">
                    <p className="font-Urbanist text-xl font-semibold leading-5 text-white text-left">
                    Gender
                    </p>
                    <div className="flex flex-row justify-start gap-4">
                    <div className="font-Urbanist text-xl font-semibold leading-5 text-white bg-[#FFFFFF1A] w-[142px] text-center px-6 py-5 mt-2 rounded-lg"
                    style={{
                        boxShadow:
                          gender === 'male' ? "0px 4px 31px 0px #B6CA42E5 inset" : "none",
                        border: gender === 'male' ? "1px solid #B6CA42E5" : "none",
                      }}
                    >
                    Male
                    </div>
                    <div className="font-Urbanist text-xl font-semibold leading-5 text-white bg-[#FFFFFF1A] w-[142px] text-center px-6 py-5 mt-2 rounded-lg"
                    style={{
                        boxShadow:
                          gender === 'female' ? "0px 4px 31px 0px #B6CA42E5 inset" : "none",
                        border: gender === 'female' ? "1px solid #B6CA42E5" : "none",
                      }}
                    >
                    Female
                    </div>

                    </div>
                </div>

            </div>
        </div>
        <div className="flex-1 flex flex-row justify-between">
          <RadialProgressChart
            score={score}
            title="Overall"
            progressGradientColor={[
              AppColors.greenUpperColor,
              AppColors.greenLowerColor,
            ]}
          />

          <RadialProgressChart
            score={potential}
            title="Potential"
            progressGradientColor={[
              AppColors.greenUpperColor,
              AppColors.greenLowerColor,
            ]}
          />
        </div>
      </div>

      {/* Render score and gender selection */}
    </div>
  );
};

export default GeneralInformationSection;
