import React, { forwardRef, InputHTMLAttributes, useState } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  label?: string;
  note?: string;
  name: string;
  error?: string;
  defaultValue?: string;
  variant?: "normal";
  shadow?: boolean;
  type?: "text" | "number" | "password"; // Add more types if needed
  inputClassName?: string;
  disabled?: boolean;
  showLabel?: boolean;
  register?: any;
  step?: string;
  errorString?: string;
}

const classes = {
  root: `px-4  items-center w-full rounded-lg appearance-none transition duration-300 ease-in-out text-heading focus:outline-none focus:ring-0 border border-border-base focus:border-accent h-12 `,
};

const PasswordInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      onKeyPress,
      label,
      note,
      name,
      error,
      defaultValue,
      variant = "normal",
      shadow = false,
      type = "text",
      inputClassName,
      disabled,
      showLabel = true,
      errorString,
      ...rest
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    const rootClassName = `${classes.root} ${inputClassName || ""}`;

    let numberDisable = type === "number" && disabled ? "number-disable" : "";

    return (
      <div className={className}>
        {showLabel && (
          <label
            htmlFor={name}
            className={`${className}  text-xl block leading-6 font-semibold tracking-tight font-Urbanist  text-white pb-3`}
          >
            {label}
          </label>
        )}
        <div className="relative">
          <input
            id={name}
            name={name}
            type={showPassword ? "text" : "password"}
            ref={ref}
            defaultValue={defaultValue}
            className={`${rootClassName} ${
              disabled ? `cursor-not-allowed ${numberDisable}  select-none` : ""
            } text-white font-medium text-lg tracking-tight font-Urbanist !w-full py-2`}
            style={{
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
              border: "1px solid #FFFFFF4D",
            }}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            disabled={disabled}
            aria-invalid={error ? "true" : "false"}
            {...rest}
          />
          <div
            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <svg
                width="16"
                height="10"
                viewBox="0 0 16 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00034 7.41271C9.36074 7.41271 10.4636 6.3921 10.4636 5.13311C10.4636 3.87413 9.36074 2.85352 8.00034 2.85352C6.63993 2.85352 5.53711 3.87413 5.53711 5.13311C5.53711 6.3921 6.63993 7.41271 8.00034 7.41271Z"
                  fill="#979797"
                />
                <path
                  d="M15.7546 4.5019C13.8612 2.38444 10.995 0.346191 8.00003 0.346191C5.00444 0.346191 2.13764 2.38586 0.245476 4.5019C-0.0818254 4.86777 -0.0818254 5.39892 0.245476 5.7648C0.721187 6.2968 1.71849 7.32376 3.05048 8.22106C6.40506 10.4811 9.58761 10.486 12.9496 8.22106C14.2816 7.32376 15.2789 6.2968 15.7546 5.7648C16.0809 5.39963 16.0826 4.86897 15.7546 4.5019ZM8.00003 1.94191C9.90164 1.94191 11.4486 3.3735 11.4486 5.13335C11.4486 6.8932 9.90164 8.32479 8.00003 8.32479C6.09842 8.32479 4.55151 6.8932 4.55151 5.13335C4.55151 3.3735 6.09842 1.94191 8.00003 1.94191Z"
                  fill="#979797"
                />
                <rect
                  width="0.953125"
                  height="16.1899"
                  rx="0.476563"
                  transform="matrix(0.631412 0.775447 -0.820241 0.572018 13.7725 0)"
                  fill="#979797"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 8.8C8.90909 8.8 9.68194 8.47929 10.3185 7.83787C10.9547 7.19596 11.2727 6.41667 11.2727 5.5C11.2727 4.58333 10.9547 3.80404 10.3185 3.16213C9.68194 2.52071 8.90909 2.2 8 2.2C7.09091 2.2 6.31806 2.52071 5.68146 3.16213C5.04533 3.80404 4.72727 4.58333 4.72727 5.5C4.72727 6.41667 5.04533 7.19596 5.68146 7.83787C6.31806 8.47929 7.09091 8.8 8 8.8ZM8 7.48C7.45455 7.48 6.99103 7.28738 6.60946 6.90213C6.22739 6.51738 6.03636 6.05 6.03636 5.5C6.03636 4.95 6.22739 4.48238 6.60946 4.09713C6.99103 3.71238 7.45455 3.52 8 3.52C8.54545 3.52 9.00921 3.71238 9.39127 4.09713C9.77285 4.48238 9.96364 4.95 9.96364 5.5C9.96364 6.05 9.77285 6.51738 9.39127 6.90213C9.00921 7.28738 8.54545 7.48 8 7.48ZM8 11C6.2303 11 4.61818 10.5018 3.16364 9.50547C1.70909 8.5096 0.654545 7.17444 0 5.5C0.654545 3.82556 1.70909 2.49016 3.16364 1.4938C4.61818 0.497933 6.2303 0 8 0C9.7697 0 11.3818 0.497933 12.8364 1.4938C14.2909 2.49016 15.3455 3.82556 16 5.5C15.3455 7.17444 14.2909 8.5096 12.8364 9.50547C11.3818 10.5018 9.7697 11 8 11Z"
                  fill="#C4C4C5"
                />
              </svg>
            )}
          </div>
        </div>
        {note && <p className="mt-2 text-xs text-body">{note}</p>}
        {error && (
          <p className={`my-2 text-xs text-red-500 ${errorString} text-start`}>
            {error}
          </p>
        )}
      </div>
    );
  }
);

PasswordInput.displayName = "PasswordInput";

export default PasswordInput;
