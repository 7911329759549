

import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import AddForm from "./AddForm";

const AddRecommendationPage = () => {
  return (
    <div>
      <GenericRowComponent
        heading="Add Recommendations"
        showBackButton
      />
      <AddForm />
    </div>
  );
};

export default AddRecommendationPage;
