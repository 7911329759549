import React from "react";
import Input from "../Common/Input";
import CustomSelect from "../Common/customSelect";
import TextArea from "../Common/Textarea";
import PasswordInput from "../Common/PasswordInput";

interface InputSectionProps {
  register: any;
  errors: any;
  control: any;
  tireOptions?: any;
  fromEdit?: boolean;
  editInffluProfile?: boolean
}

const InfluencerFieldSection: React.FC<InputSectionProps> = ({
  register,
  errors,
  control,
  tireOptions,
  fromEdit,
  editInffluProfile= true
}) => {
  return (
    <div className="pt-5">
      <div className="flex flex-row justify-between gap-3">
        <Input
          label="Name:"
          name="name"
          {...register("name", { required: true })}
          error={errors.name && "Name is required."}
          className="w-full"
          placeholder="Add name"
        />
        <Input
          label="Email:"
          name="email"
          autoComplete="off"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          })}
          error={errors.email && errors.email.message}
          className="w-full"
          placeholder="Add email"
        />

        <CustomSelect
          control={control}
          name={`tier`}
          options={tireOptions}
          label="Tier:"
          className={"w-full"}
          fromCelebGender
          required={true}
          errorMessage="Tier is required."
        />
      </div>
      {editInffluProfile === true && (

      <div className="pt-4 md:max-w-[355px] w-full">
        
        <PasswordInput
          label="Password:"
          name="password"
          // {...register("password", { required: true })}
          {
            ...register("password", {
              required: fromEdit ? false : "Password is required",
              pattern: {
                // This regex checks for at least one uppercase letter, one lowercase letter, one digit, and a minimum length of 8
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                message: "Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, and 1 number."
              }
            })
          }
          error={errors.password && errors.password.message}
          className="w-full"
          placeholder="*********"
        />
      </div>
      )}

      <div className="pt-4">
        <TextArea
          label="Description:"
          name="description"
          placeholder="Add description"
          {...register("description")}
          error={errors.description?.message}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default InfluencerFieldSection;
