import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import CouponFieldSection from "../../components/CouponManagment/basicForm";
import ActionButtons from "../../components/Common/actionButtons";
import { useCreate } from "../../services/Api/general";
import moment from "moment";
import showToast from "../../components/Common/toastNotifications";

type FormData = {
  expiry_date: string;
  free_scans: string;
  coupon_code: string;
};

export default function AddCouponCodes() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();
  const navigate = useNavigate();
  const { mutateAsync: create } = useCreate();

  const onSubmit = async (formData: FormData) => {
    try {
      const submissionData = {
        ...formData,
        expiry_date: moment(formData.expiry_date).format('DD-MM-YYYY'),
        free_scans: parseInt(formData.free_scans)
      };

      const response = await create({
        route: "coupon/createCoupon",
        data: submissionData,
      });
      if (!response.error) {
        showToast(response?.message || 'Coupon Created Successfully!' , 'success');
        navigate("/coupon-code");
      } else {
        showToast(response.message || 'Coupon not Created, please try again.', 'error');
      }
  
     
    } catch (error) {
      console.error("Failed to submit form", error);
    }
  };

  return (
    <>
      <div className="p-4 bg-transparent">
        <GenericRowComponent heading="Add Coupon Code" showBackButton />
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <div
            className={`mt-4 rounded-lg p-6`}
            style={{
              border: "1px solid #FFFFFF85",
              background: "#FFFFFF36",
            }}
          >
            <CouponFieldSection register={register} errors={errors} />
          </div>
          <ActionButtons isSubmitting={isSubmitting} />
        </form>
      </div>
    </>
  );
}
