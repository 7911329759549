import { useEffect, useRef, useState } from "react";
import { TableColumn } from "react-data-table-component";
// import { Menu } from "@headlessui/react";
// import { Link } from "react-router-dom";
import { useDelete, useViewGet } from "../../services/Api/general";
import TableComponent from "../../components/Dashboard/TableComponent";
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import { DropdownActions } from "../../components/Dashboard/dropDownTable";
import Loading from "../../components/Loading";
import Modal from "../../components/Common/Modal";

interface Celebrity {
  id: number;
  name: string;
  display_picture: string;
  profession: string;
  gender: string;
  min_score: number;
  max_score: number;
  index: number;
  // Removed email and phoneNumber since they're not in the API data
}

const CelebritiesManagement = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortedArray, setSortedArray] = useState([]);
  const rowsPerPage = 10;
  const celebPageRef = useRef<HTMLDivElement>(null);


  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCelebId, setSelectedCelebId] = useState<number | null>(null);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const {
    isLoading,
    data: AllCelebrities,
    refetch,
  } = useViewGet(
    "AllCelebrities",
    `celebrity?page=${currentPage}&limit=${rowsPerPage}&search=${searchQuery}`,
    ""
  );

  const { mutateAsync: deleteCeleb } = useDelete();


  useEffect(() => {
    if (AllCelebrities) {
      const initialSort = AllCelebrities?.data?.filteredCelebrities?.map(
        (celeb: any, index: number) => ({ ...celeb, index: (currentPage - 1) * 10 + index + 1 })
      );

      setSortedArray(initialSort);
    }
  }, [AllCelebrities]);

  useEffect(() => {
    refetch();
    if (celebPageRef.current) {
      celebPageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [searchQuery, currentPage]);

  const handleSearch = (value: string) => {
    setCurrentPage(1);
    setSearchQuery(value);
  };

  const handleDeleteCeleb = async () => {
    if (selectedCelebId !== null) {
      try {
        const response = await deleteCeleb({
          route: "celebrity",
          id: selectedCelebId,
        });

        if (response.error === false) {
          refetch();
        } else if (response.error) {
          console.error("Error posting data:", response);
        }
      } catch (error) {
        console.error("Error posting data:", error);
      }
      setShowDeleteModal(false);
      setSelectedCelebId(null);
    }
  };

  const confirmDelete = (userId: number) => {
    setSelectedCelebId(userId);
    setShowDeleteModal(true);
  };

  const columns: TableColumn<Celebrity>[] = [
    {
      name: "No#",
      selector: (row) => row.index.toString(),
      sortable: true,
      width: "10%",
    },
    {
      name: "Name",
      width: "25%",
      cell: (row) => (
        <div className="flex items-center">
          <img
            src={row.display_picture}
            alt={row.name}
            style={{ width: 30, height: 30, borderRadius: "50%" }}
          />
          <span className="ml-2">{row.name}</span>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Profession",
      width: "20%",
      selector: (row) => row.profession,
      sortable: true,
    },
    {
      name: "Gender",
      width: "15%",
      cell: (row) => (
        <div
          className={`flex flex-row justify-center items-center px-3 py-1 capitalize text-white font-Urbanist text-base font-normal leading-[18px] rounded-lg ${
            row.gender === "Male" || row.gender === "male"
              ? "bg-[rgba(52,176,255,0.6)]"
              : "bg-[rgba(254,78,128,0.6)]"
          }`}
        >
          {row.gender}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Min Score",
      selector: (row) => row?.min_score?.toString(),
      sortable: true,
      width: "10%",
    },
    {
      name: "Max Score",
      selector: (row) => row?.max_score?.toString(),
      sortable: true,
      width: "10%",
    },
    {
      name: "Actions",
      // button: true,
      width: "10%",
      cell: (row, rowIndex) => (
        <DropdownActions
          row={row}
          rowIndex={rowIndex}
          totalRows={10}
          actions={"edit,view,delete"}
          onConfirmDelete={confirmDelete}
        />
      ),
    },
  ];

  return (
    <>
      <div className="p-4 bg-transparent" ref={celebPageRef}>
        <GenericRowComponent
          heading="Celebrities Management"
          buttonLink="add"
          buttonLabel="+ Add Celebrity"
          onInputChange={handleSearch}
          showInput
        />
        <div className="w-full h-full">
          {isLoading ? (
            <div className=" absolute inset-0 flex items-center justify-center">
              <Loading />
            </div>
          ) : (
            <TableComponent
              columns={columns}
              data={sortedArray}
              totalRows={AllCelebrities?.data?.total}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              progressPending={isLoading}
            />
          )}
        </div>
      </div>
      {showDeleteModal && selectedCelebId && (
        <>
          <Modal
            isOpen={showDeleteModal ? true : false}
            title="Are you sure that you want to delete?"
            // message="You won't be able to revert this!"
            onConfirm={handleDeleteCeleb}
            onCancel={() => setShowDeleteModal(false)}
          />
        </>
      )}
    </>
  );
};

export default CelebritiesManagement;
