// toastNotifications.ts
import { toast } from "react-toastify";

type ToastType = "info" | "success" | "warning" | "error";

const showToast = (message: string, type: ToastType = "info") => {
  toast[type](message, {
    autoClose: 2000, // Toast will auto-close after 2000 milliseconds
  });
};

export default showToast;
