import {  useState } from "react";
import { TableColumn } from "react-data-table-component";
// import { Menu } from "@headlessui/react";
// import { Link } from "react-router-dom";
// import { useViewGet } from "../../services/Api/general";
import TableComponent from "../../components/Dashboard/TableComponent";
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
// import { DropdownActions } from "../../components/Dashboard/dropDownTable";
import Loading from "../../components/Loading";

interface Subscription {
    id: number;
    title: string;
    price: string;
    scans: string;
    accounts: string;
    duration: string;
    subscribers: number;
  }

const SubscriptionPlan = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoadinng] = useState(false);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

//   const {
//     isLoading,
//     data: AllCelebrities,
//     refetch,
//   } = useViewGet("AllCelebrities", "celebrity", "");

const AllSubsription: Subscription[] = [
    { id: 1, title: 'Weekly', price: '2.99 $', scans: '3', accounts: 'Single', duration: '7 days', subscribers: 635 },
    { id: 2, title: 'Monthly', price: '8.99 $', scans: '14', accounts: 'Single', duration: '30 days', subscribers: 1025 },
    // ... other rows
  ];
  const columns: TableColumn<Subscription>[] = [
    {
      name: 'No#',
      selector: (row: Subscription) => row.id,
      sortable: true,
    },
    {
      name: 'Title',
      selector: (row: Subscription) => row.title,
      sortable: true,
    },
    {
      name: 'Price',
      selector: (row: Subscription) => row.price,
      sortable: true,
    },
    {
      name: 'Scans',
      selector: (row: Subscription) => row.scans,
      sortable: true,
    },
    {
      name: 'Accounts',
      selector: (row: Subscription) => row.accounts,
      sortable: true,
    },
    {
      name: 'Duration',
      selector: (row: Subscription) => row.duration,
      sortable: true,
    },
    
  ];

  return (
    <div className="p-4 bg-transparent">
      <GenericRowComponent
        heading="Subscription Plan"
      />
      <div className="w-full h-full">
      {isLoading ? (
          <Loading />
        ) : (
        <TableComponent
          columns={columns}
          data={AllSubsription}
          totalRows={AllSubsription?.length}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          progressPending={isLoading}
        />)}
      </div>
    </div>
  );
};

export default SubscriptionPlan;
