export const FaceMatchIcon = (props: { iconcolor: string }) => (
  <>
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6758_3212)">
        <path
          d="M2.47416 23.195H12.8575C13.123 23.195 13.3378 22.9802 13.3378 22.7147C13.3378 20.2834 11.7974 18.2107 9.64389 17.4055C10.6119 16.7627 11.2521 15.6637 11.2521 14.4171C11.2521 12.4398 9.64341 10.8311 7.66607 10.8311C5.68873 10.8311 4.08003 12.4398 4.08003 14.4171C4.08003 15.6637 4.72012 16.7626 5.68814 17.4055C3.53425 18.2106 1.9939 20.2834 1.9939 22.7147C1.9939 22.9802 2.2087 23.195 2.47416 23.195ZM5.04056 14.4171C5.04056 12.9693 6.21824 11.7916 7.66607 11.7916C9.11386 11.7916 10.2915 12.9693 10.2915 14.4171C10.2915 15.8649 9.11386 17.0426 7.66607 17.0426C6.21824 17.0426 5.04056 15.8649 5.04056 14.4171ZM7.66607 18.0031C10.1016 18.0031 12.1118 19.8613 12.3528 22.2345H2.97882C3.21988 19.8613 5.23004 18.0031 7.66607 18.0031Z"
          fill={props.iconcolor}
          stroke={props.iconcolor}
          stroke-width="0.3"
        />
        <path
          d="M26.0065 12.3803C26.0065 9.94896 24.4661 7.87627 22.3126 7.07103C23.2806 6.42818 23.9208 5.32924 23.9208 4.08262C23.9208 2.10528 22.3121 0.496582 20.3348 0.496582C18.3574 0.496582 16.7487 2.10528 16.7487 4.08262C16.7487 5.32921 17.3888 6.42814 18.3568 7.07096C16.203 7.87613 14.6626 9.94889 14.6626 12.3803C14.6626 12.6457 14.8774 12.8605 15.1429 12.8605H25.5262C25.7917 12.8605 26.0065 12.6457 26.0065 12.3803ZM17.7093 4.08262C17.7093 2.63479 18.8869 1.45711 20.3348 1.45711C21.7826 1.45711 22.9603 2.63479 22.9603 4.08262C22.9603 5.53042 21.7826 6.70809 20.3348 6.70809C18.8869 6.70809 17.7093 5.53042 17.7093 4.08262ZM15.6475 11.9C15.8886 9.52684 17.8987 7.66862 20.3348 7.66862C22.7703 7.66862 24.7805 9.52684 25.0215 11.9H15.6475Z"
          fill={props.iconcolor}
          stroke={props.iconcolor}
          stroke-width="0.3"
        />
        <path
          d="M22.4558 19.9748V15.2669C22.4558 15.0014 22.241 14.7866 21.9755 14.7866C21.7101 14.7866 21.4953 15.0014 21.4953 15.2669V19.4945H18.3384L18.6329 19.2C18.8205 19.0123 18.8205 18.7084 18.6329 18.5208C18.4453 18.3333 18.1414 18.3333 17.9538 18.5208L16.8401 19.6346C16.7958 19.6786 16.7604 19.7313 16.736 19.7899C16.7117 19.8479 16.6987 19.9106 16.6987 19.9748C16.6987 20.039 16.7117 20.1017 16.736 20.1597C16.7604 20.2183 16.7959 20.271 16.8402 20.3151L17.9538 21.4282C18.0476 21.522 18.1705 21.5689 18.2934 21.5689C18.4162 21.5689 18.5391 21.522 18.6329 21.4282C18.8205 21.2406 18.8205 20.9367 18.6329 20.7491L18.3387 20.455H21.9755C22.241 20.455 22.4558 20.2402 22.4558 19.9748Z"
          fill={props.iconcolor}
          stroke={props.iconcolor}
          stroke-width="0.3"
        />
        <path
          d="M12.5874 3.8116C12.8529 3.8116 13.0677 3.5968 13.0677 3.33134C13.0677 3.06588 12.8529 2.85107 12.5874 2.85107H7.87956C7.6141 2.85107 7.3993 3.06588 7.3993 3.33134V6.96852L7.10475 6.67397C6.91713 6.48635 6.61324 6.48635 6.42562 6.67397C6.23801 6.86154 6.23801 7.16548 6.42562 7.35306L7.53935 8.46678C7.58341 8.51107 7.63612 8.54645 7.69467 8.57089C7.75266 8.59514 7.81535 8.60813 7.87956 8.60813C7.94378 8.60813 8.00646 8.59514 8.06446 8.57089C8.12305 8.54645 8.17579 8.51092 8.21989 8.46663L9.33298 7.35306C9.5206 7.16548 9.5206 6.86154 9.33298 6.67397C9.14541 6.48635 8.84147 6.48635 8.65389 6.67397L8.35983 6.96822V3.8116H12.5874Z"
          fill={props.iconcolor}
          stroke={props.iconcolor}
          stroke-width="0.3"
        />
      </g>
      <defs>
        <clipPath id="clip0_6758_3212">
          <rect width="28" height="23.6923" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);
