import React from "react";

interface Celebrity {
  id: number;
  name: string;
  display_picture: string;
  profession: string;
  min_score: number;
  max_score: number;
  gender: string;
  created_at: string;
  updated_at: string;
}

interface CelebrityComparisonSectionProps {
  celebArray: Celebrity[];
}

const CelebrityComparisonSection: React.FC<CelebrityComparisonSectionProps> = ({
  celebArray,
}) => {
  return (
    <div className="my-3">
    
      <div className="w-full pt-2 flex flex-col gap-y-3">
        {celebArray?.length > 0 ? (
          celebArray.map((celebrity) => (
            <div
              key={celebrity.id}
              className="bg-[#FFFFFF1A] p-4 rounded-lg flex space-x-4"
            >
              <div className="flex-1 flex flex-row justify-start mt-4 gap-5">
                <div>
                  <p className="font-Urbanist text-xl font-semibold leading-5 text-white text-left">
                    Name
                  </p>
                  <div className="font-Urbanist text-xl font-semibold leading-5 text-white bg-[#FFFFFF1A] w-[251px] text-left px-6 py-5 mt-2 rounded-lg">
                    {celebrity.name}
                  </div>
                </div>
                <div>
                  <p className="font-Urbanist text-xl font-semibold leading-5 text-white text-left">
                    Title
                  </p>
                  <div className="font-Urbanist text-xl font-semibold leading-5 text-white bg-[#FFFFFF1A] w-[373px] text-left px-6 py-5 mt-2 rounded-lg">
                    {celebrity.profession}
                  </div>
                </div>
              </div>
              <img
                src={celebrity.display_picture}
                alt={celebrity.name}
                className="w-[250px] h-auto rounded-2xl object-cover border-[1px] border-[#ffffff1A]"
              />
            </div>
          ))
        ) : (
          <div className=" !bg-transparent w-full text-center text-white shadow-sm mx-auto p-6">
            No data found
          </div>
        )}
      </div>
    </div>
  );
};

export default CelebrityComparisonSection;
