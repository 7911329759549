import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Controller,  } from 'react-hook-form';

interface TextEditorProps {
  name: string;
  label?: string;
  control: any;
}

const TextEditor: React.FC<TextEditorProps> = ({ name, label, control }) => {
//   const { control } = useFormContext(); // Using useFormContext to access the form's control object

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      [{ color: [] }],
      [{ background: [] }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
  ];

  return (
    <div className="mb-4">
      {label && (
        <label htmlFor={name} className="text-xl block leading-6 font-semibold tracking-tight font-Urbanist text-white pb-3">
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <ReactQuill
            theme="snow"
            className="text-editor rounded-lg text-white"
            value={field.value}
            onChange={field.onChange} // Use the onChange from Controller
            modules={modules}
            formats={formats}
          />
        )}
      />
    </div>
  );
};

export default TextEditor;
