import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ReactModal from 'react-modal';
import showToast from '../Common/toastNotifications';
import PasswordInput from '../Common/PasswordInput';
import { useEdit } from '../../services/Api/general';

// Define a type for the props that the ModalChangePassword component will accept
type ModalProps = {
  isOpen: boolean;
  
  onCancel: () => void; // Same here
};

type ChangePasswordFormInputs = {
  newPassword: string;
  confirm_password: string;
  oldPassword: string;
};

// Custom styles for the modal
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // backgroundColor: 'linear-gradient(183.52deg, #2C1B47 -33.85%, #1C548D 73.36%)',
    // background: 'radial-gradient(75.8% 75.8% at 50% 50%, rgba(0, 0, 0, 0) 0%, #000000 100%)',
    background: [
      'linear-gradient(183.52deg, #2C1B47 -33.85%, #1C548D 73.36%)',
      'radial-gradient(75.8% 75.8% at 50% 50%, rgba(0, 0, 0, 0) 0%, #000000 100%)'
    ].join(', '),
    border: 'none',
    borderRadius: '10px',
    padding: '20px',
    color: 'white',
    minWidth: '639px',
    minHeight: '313px',
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // width: '90%', // You can use a width in percentage or fixed size
  },
  overlay: {
    backgroundColor: '#FFFFFF36',
  },
};

// Set up react-modal (do this in your root component, App.js or similar)
ReactModal.setAppElement('#root'); // Adjust to your app's root element ID

// ModalChangePassword component
const ModalChangePassword: React.FC<ModalProps> = ({ isOpen, onCancel }) => {

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<ChangePasswordFormInputs>();
  const { mutateAsync: edit_influencer_change_password } = useEdit();


  // Watch the values of both password fields
  const newPassword = watch("newPassword");
  const confirmPassword = watch("confirm_password");
  // Check if passwords match and set an error if they do not
  useEffect(() => {
    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      setError("confirm_password", {
        type: "manual",
        message: "Passwords do not match.",
      });
    } else {
      clearErrors("confirm_password");
    }
  }, [newPassword, confirmPassword, setError, clearErrors]);

 

  const onSubmit = async (data: any) => {
    try {
      const data_form = {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      };
      const result = await edit_influencer_change_password({
        route: `/profile/changePassword`,
        data: data_form,
      });


      if (!result.error) {
        showToast(result?.message || "Password is now changed", "success");
        onCancel();
      } else {
        showToast(
          result.message || "User not found, please try again.",
          "error"
        );
      }
    } catch (error: any) {
      showToast(error.message || "An unexpected error occurred.", "error");
    }
  };

  return (
    <ReactModal isOpen={isOpen} style={customStyles} onRequestClose={onCancel}>
      <div className="flex flex-col items-center justify-center w-full my-4">
      <div className=" mb-6 text-center w-full">
            <h1 className="text-4xl font-Urbanist font-semibold leading-7 text-white">
            Change Password
        </h1>
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-6 bg-transparent w-[80%] rounded-lg "
          >
            <div className="space-y-4">
            <PasswordInput
                label="Old Password:"
                {...register("oldPassword", {
                  required: "Old Password is required",
                  pattern: {
                    // This regex checks for at least one uppercase letter, one lowercase letter, one digit, and a minimum length of 8
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                    message:
                      "Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, and 1 number.",
                  },
                })}
                error={errors?.oldPassword && errors?.oldPassword?.message}
                className="w-full lg:h-18 "
                placeholder="*********"
              />

              <PasswordInput
                label="New Password:"
                {...register("newPassword", {
                  required: "Password is required",
                  pattern: {
                    // This regex checks for at least one uppercase letter, one lowercase letter, one digit, and a minimum length of 8
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                    message:
                      "Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, and 1 number.",
                  },
                })}
                error={errors?.newPassword && errors?.newPassword?.message}
                className="w-full lg:h-18 "
                placeholder="*********"
              />

              <PasswordInput
                label="Confirm Password:"
                {...register("confirm_password", {
                  required: "Confirm Password is required",
                  pattern: {
                    // This regex checks for at least one uppercase letter, one lowercase letter, one digit, and a minimum length of 8
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                    message:
                      "Password must be same as above.",
                  },
                })}
                error={
                  errors.confirm_password && errors.confirm_password.message
                }
                className="w-full lg:h-18 "
                placeholder="*********"
              />
            </div>
            <div className="flex flex-row justify-between gap-4">
        <button
          type="button"
          onClick={onCancel}
          className="w-full p-3 rounded-lg  md:mt-5 font-bold text-lg font-Urbanist text-md leading-[22px] text-[#76e19c] text-center"
          style={{
            border: "1px solid #76e19c",
          }}
        >
          Cancel
        </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600 text-black p-3 rounded-lg  md:mt-5 font-bold text-lg"
            >
              Save
            </button>
            </div>
          </form>
      </div>
    </ReactModal>
  );
};

export default ModalChangePassword;
