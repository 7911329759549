import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import OTPInput from "react-otp-input";
import Countdown from "react-countdown";
import { Controller, useForm } from "react-hook-form";
import LoginPageHeader from "../../../components/Auth/AuthLeftPage";
import GenericRowComponent from "../../../components/Dashboard/genericHeaderRow";
import showToast from "../../../components/Common/toastNotifications";

type CountdownProps = {
  minutes: number;
  seconds: number;
  completed: boolean;
};

export const OtpInfluencer = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  useEffect(() => {
    // If email is undefined or not valid, redirect
    if (!email || !isValidEmail(email)) {
      navigate('/influencers/forget_email');
    }
  }, [email]); // Dependency array includes email and navigate to rerun the effect if these change

  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useForm({ mode: "onChange" });
  const otp = watch("otp");

  const [countdownKey, setCountdownKey] = useState(0); // State to control countdown key
  const [isCounting, setIsCounting] = useState(true);
  const startTime = Date.now() + 1 * 60 * 1000 + 0 * 1000;

  const renderer = ({ minutes, seconds, completed }: CountdownProps) => {
    if (completed) {
      return <span className="text-red-500 ml-3">Time's up!</span>;
    } else {
      return (
        <span className="font-Urbanist text-white text-sm font-semibold leading-5 text-left ml-3">
          ({String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
          )
        </span>
      );
    }
  };

  const startCountdown = () => {
    setIsCounting(true);
    setCountdownKey((prevKey) => prevKey + 1);
  };

  const stopCountdown = () => {
    setIsCounting(false);
  };

  const memoizedCountdown = useMemo(
    () => (
      <Countdown
        date={startTime}
        onComplete={stopCountdown}
        key={countdownKey}
        renderer={renderer}
      />
    ),
    [countdownKey, isCounting] // Dependencies for memoization
  );
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const resendOtp = async () => {
    // Logic to resend OTP
    try {
      const response = await fetch(
        `${baseURL}/api/dashboard-influencer/auth/forgotPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        }
      );

      const result = await response.json();
      if (!result.error) {
        showToast(result?.message, "info");
      } else {
        showToast(
          result?.message || "User not found, please try again.",
          "error"
        );
      }
    } catch (error: any) {
      showToast(error.message || "An unexpected error occurred.", "error");
    }
    setCountdownKey((prev) => prev + 1); // Reset countdown
  };

  const onSubmit = async(data: any) => {
   
    try {
      const response = await fetch(
        `${baseURL}/api/dashboard-influencer/auth/verifyOTP`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email, otp: data.otp }),
        }
      );

      const result = await response.json();
      if (!result.error) {
        showToast(result?.message, "success");
        navigate(`/influencers/change_password?otp=${result?.data?.token}&email=${email}`)
      } else {
        showToast(
          result?.message || "User not found, please try again.",
          "error"
        );
      }
    } catch (error: any) {
      showToast(error.message || "An unexpected error occurred.", "error");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-between gap-4 px-14 py-16 w-full  add_backgrounndAtBottom">
      {/* Assuming LoginPageHeader and GenericRowComponent are properly styled with TailwindCSS */}
      <LoginPageHeader
        leftImageUrl="/assets/images/authpageImages/otpPageImageLeft.png"
        rightImageUrl="/assets/images/authpageImages/otpPageImageRight.png"
      />
      <div className="md:w-1/2 py-6 px-10 md:px-24 w-full flex justify-center">
        <div className="max-w-[90%] w-full">
          <div className="mb-8">
            <GenericRowComponent heading="OTP Verification" showBackButton />
          </div>
          <div className="ml-3 font-Urbanist text-sm font-semibold leading-5 text-left text-white">
            {/* Display the email from the URL */}
            Enter OTP Sent To... ({email})
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 ml-3">
            <Controller
              control={control} // control from useForm hook
              name="otp"
              // rules={{ required: 'OTP is required' }} // setting the required rule with a messag
              rules={{
                required: "OTP is required.",
                validate: (value) =>
                  value.length === 4 || "OTP must be exactly 4 digits.",
              }}
              render={({ field }) => (
                <OTPInput
                  {...field}
                  numInputs={4}
                  inputType="number"
                  shouldAutoFocus
                  inputStyle="bg-[#FFFFFF0F] text-18 font-semibold text-white leading-18 text-center border-[#FFFFFF4D] border-[1px] w-14 h-12 rounded" // your input styles
                  containerStyle="w-full gap-2 my-5" // your container styles
                  renderInput={(props, index) => {
                    // Ensure that all required props are passed to the input element
                    return (
                      <input
                        {...props}
                        value={props.value || ""}
                        autoComplete="off" // to prevent browser autocomplete
                        className="bg-[#FFFFFF0F] text-18 font-semibold text-white leading-18 text-center border-[#FFFFFF4D] border-[1px] w-14 h-12 min-w-[70px] min-h-[60px] rounded-lg otp_input" // TailwindCSS styles for inputs
                      />
                    );
                  }}
                />
              )}
            />
            {typeof errors?.otp?.message === "string" && (
              <p className="text-red-500">{errors?.otp.message}</p>
            )}

            <span className="font-Urbanist text-white text-sm font-semibold leading-5 text-left">
              Don't Receive The OTP ?&nbsp;&nbsp;
            </span>

            <button
              onClick={resendOtp}
              type="button"
              className="font-Urbanist text-[#3FDFA1] text-sm font-semibold leading-5 text-left"
            >
              Resend OTP Code
            </button>
            {memoizedCountdown}

            <button
              type="submit"
              // disabled={errors.otp}
              className="w-full bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600 text-white p-3 rounded-lg font-bold text-lg"
            >
              Verify
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OtpInfluencer;
