import React, { forwardRef, InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  label?: string;
  note?: string;
  name?: string;
  error?: string;
  defaultValue?: string;
  variant?: 'normal';
  shadow?: boolean;
  type?: 'text' | 'number' | 'password'; // Add more types if needed
  inputClassName?: string;
  disabled?: boolean;
  showLabel?: boolean;
  register?: any;
  step?: string;
  errorString?: string;
}

const classes = {
  root: `px-4  items-center w-full rounded-lg appearance-none transition duration-300 ease-in-out text-heading focus:outline-none focus:ring-0 border border-border-base focus:border-accent h-12 `,
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      onKeyPress,
      label,
      note,
      name,
      error,
      defaultValue,
      variant = 'normal',
      shadow = false,
      type = 'text',
      inputClassName,
      disabled,
      showLabel = true,
      errorString,
      ...rest
    },
    ref
  ) => {
    const rootClassName = `${classes.root} ${inputClassName || ''}`;

    let numberDisable = type === 'number' && disabled ? 'number-disable' : '';
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };

    return (
      <div className={className}>
        {showLabel && (
          <label htmlFor={name} className={`${className}  text-xl block leading-6 font-semibold tracking-tight font-Urbanist  text-white pb-3`}>
            {label}
          </label>
        )}
        <input
          id={name}
          name={name}
          type={type}
          ref={ref}
          defaultValue={defaultValue}
          className={`${rootClassName} ${
            disabled
              ? `cursor-not-allowed ${numberDisable}  select-none`
              : ''
          } text-white font-medium text-lg tracking-tight font-Urbanist !w-full py-2`}
          style={{
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
            border: '1px solid #FFFFFF4D'

          }}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck={false}
          disabled={disabled}
          aria-invalid={error ? 'true' : 'false'}
          onKeyPress={(e) => {
            handleKeyPress(e);
            onKeyPress && onKeyPress(e);
          }}
          {...rest}
        />
        {note && <p className="mt-2 text-xs text-body">{note}</p>}
        {error && <p className={`my-2 text-xs text-red-500 ${errorString} text-start`}>{error}</p>}
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
