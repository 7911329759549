export const downloadCSV = (data: { name: string; answer: string }[]) => {
  // Convert the data to CSV string
  const csvRows = [
    // this is your header
    ["Name", "Answer"],
    // your data
    ...data.map((item) => [item.name, item.answer]),
  ];

  // Convert array of strings to a string where each line is separated by a newline character
  const csvData = csvRows.map((row) => row.join(",")).join("\n");

  // Create a Blob that represents the data as a CSV file
  const blob = new Blob([csvData], { type: "text/csv" });

  // Create a temporary link element and trigger the download
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "survey-questions.csv";
  link.click();

  // Clean up by revoking the Blob URL and removing the link element
  URL.revokeObjectURL(url);
  link.remove();
};
