import React from 'react'
import SinglePlateForm from './singlePlateForm';
interface PlateformSectionProps {
    register: any;
    errors: any;
    watch: any;
  }
const InfluencerPlatefroms: React.FC<PlateformSectionProps> = ({
    register,
    errors,
    watch
  }) => {
  return (
    <div
            className={`mt-8 rounded-lg p-6`}
            style={{
              border: "1px solid #FFFFFF85",
              background: "#FFFFFF36",
            }}
          >
            <SinglePlateForm register={register} errors={errors} watch={watch} label='TikTok' plateformName='tiktok' />
            <SinglePlateForm register={register} errors={errors} watch={watch} label='Facebook' plateformName='facebook' />
            <SinglePlateForm register={register} errors={errors} watch={watch} label='Instagram' plateformName='instagram' />
            <SinglePlateForm register={register} errors={errors} watch={watch} label='Youtube' plateformName='youtube' />
          </div> 
  )
}

export default InfluencerPlatefroms