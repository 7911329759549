// export const getIcon = ({ iconList, iconcolor, iconName, ...rest }) => {
//   const TagName = iconList[iconName];
//   return !!TagName ? (
//     <TagName {...rest} iconcolor={iconcolor || "#757575"} />
//   ) : (
//     <p className="text-sm text-red-500">{iconName} is not a valid icon</p>
//   );
// };
import React from 'react';

interface GetIconProps {
  iconList: Record<string, React.ComponentType<any>>;
  iconcolor?: string;
  iconName: string;
  [key: string]: any;
}

export const getIcon: React.FC<GetIconProps> = ({ iconList, iconcolor, iconName, ...rest }) => {
  const TagName = iconList[iconName];
  return !!TagName ? (
    <TagName {...rest} iconcolor={iconcolor || "#757575"} />
  ) : (
    <p className="text-sm text-red-500">{iconName} is not a valid icon</p>
  );
};
