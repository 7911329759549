import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { logoutUser } from "../../redux/userSlice";
import React, { useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { removeTokens } from "../../services/tokenHandler";


const Header: React.FC = () => {
  // const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);

  // const handleLogout = () => {
  //   dispatch(logoutUser());
  //   removeTokens();
  // };

  const userData = {
    name: user.data?.name || "demo",
    email: user.data?.email || "demo@demo.com",
    profileImage: "/assets/images/user-avatar.png",
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-[#FFFFFF1A] h-16 flex items-center justify-end px-4 rounded-lg my-3 mx-5">
      
      {/* <Menu as="div" className="relative z-50 inline-block text-left">
        <Menu.Button */}
        <span
          className="flex items-center justify-start gap-1 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <img
            src={userData?.profileImage}
            alt="Profile"
            className="rounded-full w-10 h-10 mr-2"
            style={{border: '2px solid #E6E5F9'}}
          />
          <div className="flex flex-col justify-start">
            <span className="text-white text-left">{userData?.name}</span>
            <span className="text-gray-300 text-sm text-left">{userData?.email}</span>
          </div>
        </span>
        {/* </Menu.Button> */}

        {/* <Transition
          as={Fragment}
          show={isOpen}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            as="ul"
            static
            className="absolute right-0 z-50 px-4 py-3 w-48 -mt-1 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg outline-none"
          >
           
              

              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-blue-500 text-white" : "text-gray-900"
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                )}
              </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu> */}
    </div>
  );
};

export default Header;
