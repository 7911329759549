import React, { useState } from "react";
import { useViewGet } from "../../services/Api/general";
import TabNavigation from "../../components/Results/ResultTabNavigation";
import GeneralInformationSection from "../../components/Results/ResultGernalInformation";
import CelebrityComparisonSection from "../../components/Results/ResultCelebrityComparison";
import RecommendationInformationSection from "../../components/Results/ResultRecommendationInformation";
import ResultAnalysisSection from "../../components/Results/ResultAnalysisSection";
import RatingInformationSection from "../../components/Results/ResultRatingInformation";

// Define the props interface
interface SelectedScanResultProps {
  selectedScanID: number;
  selectedProfileID: number;
  setSelectedScanID: any;
}



const SelectedScanResult: React.FC<SelectedScanResultProps> = ({
  selectedScanID,
  setSelectedScanID,
  selectedProfileID,
}) => {
  const {
    isLoading,
    data: SingleProfileScanResult,
    refetch,
  } = useViewGet(
    "SingleProfileScanResult",
    `scan/getScanResult/${selectedProfileID}/${selectedScanID}`,
    ""
  );

  const [activeTab, setActiveTab] = useState("General"); // State for active tab
  if (isLoading) {
    return <div>Loading...</div>;
  }

  

  const scoreOverall_value = SingleProfileScanResult?.data.adjusted_score * 10;
  const scoreOverall = parseFloat(scoreOverall_value.toFixed(2));
  const scorePotential = SingleProfileScanResult?.data.potential_score * 10;
  return (
    <div className="p-4 px-2 bg-transparent">
      <div className="flex items-center my-3 mb-5">
        <button
          className="mr-4 px-3 py-2 bg-[#FFFFFF1A]"
          onClick={() => setSelectedScanID(undefined)}
        >
          <svg
            width="11"
            height="19"
            viewBox="0 0 11 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.2273 1.54665C10.3176 1.45913 10.3881 1.35618 10.4349 1.24368C10.4817 1.13118 10.5039 1.01133 10.5002 0.890968C10.4965 0.770608 10.4669 0.652097 10.4133 0.542201C10.3596 0.432306 10.2828 0.333178 10.1873 0.250478C10.0919 0.167778 9.97956 0.103126 9.85684 0.0602114C9.73411 0.0172972 9.60336 -0.00303815 9.47206 0.000366668C9.34076 0.00377149 9.21147 0.0308497 9.09159 0.0800552C8.9717 0.129261 8.86356 0.19963 8.77334 0.287145L0.273343 8.53714C0.0978063 8.70734 0 8.93268 0 9.16689C0 9.40111 0.0978063 9.62645 0.273343 9.79665L8.77334 18.0476C8.86296 18.137 8.97108 18.2092 9.09141 18.26C9.21173 18.3108 9.34187 18.3392 9.47427 18.3435C9.60666 18.3478 9.73867 18.328 9.86263 18.2851C9.98659 18.2423 10.1 18.1773 10.1963 18.0939C10.2927 18.0105 10.3699 17.9104 10.4237 17.7995C10.4775 17.6885 10.5067 17.5688 10.5095 17.4474C10.5124 17.326 10.489 17.2053 10.4405 17.0922C10.3921 16.9792 10.3196 16.8761 10.2273 16.789L2.37534 9.16689L10.2273 1.54665Z"
              fill="white"
            />
          </svg>
        </button>

        <h1 className="text-4xl font-Urbanist font-semibold text-white leading-7">
          Back to Table
        </h1>
      </div>

      <TabNavigation activeTab={activeTab} setActiveTab={setActiveTab} />

      <div>
      <h1 className="text-2xl font-semibold leading-6 pb-2 mt-6 text-left px-2 w-full mb-3 text-white border-b border-[#FFFFFF8A]">
      {activeTab}
      </h1>
        {activeTab === "General" && <div>

        <GeneralInformationSection score={scoreOverall} gender={SingleProfileScanResult?.data?.gender} potential={scorePotential} />
        </div>}
        {activeTab === "Ratings" && <div>

        <RatingInformationSection ratingData={SingleProfileScanResult?.data?.scoresPerMajorFeatures} />
        </div>}
        {activeTab === "Analysis" && <div>

        <ResultAnalysisSection 
        cheekboneShape={SingleProfileScanResult?.data?.cheekbone_shape}
        chinShape={SingleProfileScanResult?.data?.chin_shape}
        eyeColor={SingleProfileScanResult?.data?.eye_color}
        eyeShape={SingleProfileScanResult?.data?.eye_shape}
        faceShape={SingleProfileScanResult?.data?.face_shape}
        jawAngularity={SingleProfileScanResult?.data?.jaw_angularity}
        lipShape={SingleProfileScanResult?.data?.lip_shape}
        race={SingleProfileScanResult?.data?.race}
        age={SingleProfileScanResult?.data?.age}
        hairType={SingleProfileScanResult?.data?.hair_type}
        country={SingleProfileScanResult?.data?.country}
        />
        </div>}
        {activeTab === "Recommendations" && <div>

        <RecommendationInformationSection recommendationData={SingleProfileScanResult?.data?.scanResultsRecommendations} />
        </div>}
        {activeTab === "Celebrity Comparison" && <div>

        <CelebrityComparisonSection celebArray={SingleProfileScanResult?.data?.scanResultsCelebrities} />
        </div>}

        {/* ... other conditional content based on the activeTab ... */}

        
      </div>
    </div>
  );
};

export default SelectedScanResult;
