import React, { useState } from "react";
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import Loading from "../../components/Loading";
import ProfileView from "../../components/Dashboard/profileSectionWithImage";
import { useView } from "../../services/Api/general";
import { useParams } from "react-router-dom";
import { ProfileCard } from "../../components/Common/profileCard";

// User data type definition
interface Profile {
  id: number;
  name: string;
  scan_count: number;
  age?: number;
  fcm_token?: string;
  avatar?: string;
  referral_code?: string;
  is_active: boolean;
  default_profile: boolean;
  incentive_task_streak: number;
  referrals_count: number;
  referral_incentive_streak: number;
  incentive_scans_earned: number;
  created_at: string;
  updated_at: string;
}

interface User {
  id: number;
  name: string;
  subscription_status: string;
  role: string;
  email: string;
  profiles: Profile[];
  // ... other user fields
}

const ViewUserPage: React.FC = () => {
  const { id } = useParams(); // Get the celebrity ID from the URL

  const { isLoading, data: UserData } = useView("user/getUser", id);
  console.log(UserData?.data, "UserData");

  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);

  const handleProfileClick = (profile: Profile) => {
    setSelectedProfile(profile);
  };

  return (
    <div className="p-4 bg-transparent">
      <GenericRowComponent heading="View User Management" showBackButton />
      {isLoading ? (
        <div className=" absolute inset-0 flex items-center justify-center">
        <Loading />
        </div>
      ) : (
        <>
          <div
            className={`mt-4 ${
              UserData?.data?.profiles?.length === 0 && "rounded-lg p-6"
            }`}
            style={{
              border:
                UserData?.data?.profiles?.length === 0
                  ? "1px solid #FFFFFF85"
                  : "none",
              background:
                UserData?.data?.profiles?.length === 0
                  ? "#FFFFFF36"
                  : "transparent",
            }}
          >
            <ProfileView
              imageUrl={
                UserData?.data?.display_picture ||
                "/assets/images/square-user.png"
              }
              name={UserData?.data?.name}
              email={UserData?.data?.email}
              gender={UserData?.data?.gender}
            />
            {UserData?.data?.profiles?.length === 0 && (
              <div className="mt-8 flex flex-row justify-between gap-6">
                <div className="min-w-[193px] px-4 py-2 rounded-lg bg-[#FFFFFF1A] ">
                  <p className="font-Urbanist text-[22px] font-semibold leading-custom text-white">
                    Subscription
                  </p>
                  <p className="font-Urbanist text-base font-medium leading-6 text-white ">
                    {UserData?.data?.subscription_type || "Individual"}
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-row justify-start gap-4 mt-8">
            {UserData?.data?.profiles?.map((profile: any) => (
              <ProfileCard
                key={profile.id}
                name={profile.name}
                email={UserData?.data?.email}
                imageUrl={profile.avatar || "/assets/images/square-user.png"}
                onClick={() => handleProfileClick(profile)}
                selectedUser = {selectedProfile?.id === profile?.id ? true : false}
              />
            ))}
          </div>
          {selectedProfile && (
            <div className="selected-profile-details">
              {/* Render more details of the selected profile */}
              <div
                className={`mt-4 rounded-lg p-6`}
                style={{
                  border: "1px solid #FFFFFF85",
                  background: "#FFFFFF36",
                }}
              >
                <ProfileView
                  imageUrl={
                    selectedProfile?.avatar || "/assets/images/square-user.png"
                  }
                  name={selectedProfile?.name}
                />

                <div className="mt-8 flex flex-row justify-between gap-6">
                  <div className="min-w-[193px] px-4 py-2 rounded-lg bg-[#FFFFFF1A] ">
                    <p className="font-Urbanist text-[22px] font-semibold leading-custom text-white">
                      Subscription
                    </p>
                    <p className="font-Urbanist text-base font-medium leading-6 text-white ">
                      {UserData?.data?.subscription_type || "Individual"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ViewUserPage;
