import React, { useEffect, useRef, useState } from "react";
import { TableColumn } from "react-data-table-component";
import TableComponent from "../../components/Dashboard/TableComponent";
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import Loading from "../../components/Loading";
import { DropdownActions } from "../../components/Dashboard/dropDownTable";
import { useDelete, useViewGet } from "../../services/Api/general";
import moment from "moment";
import Modal from "../../components/Common/Modal";
// Import DropdownActions from "../../components/Dashboard/dropDownTable";
interface Coupon {
  id: number;
  coupon_code: string;
  free_scans: string;
  expiry_date: string;
  is_active: boolean;
  index: number;
}
// Dummy data based on your uploaded image


const CouponManagementPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortedArray, setSortedArray] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCCouponId, setSelectedCCouponId] = useState<number | null>(null);

  const rowsPerPage = 10;
  const couponPageRef = useRef<HTMLDivElement>(null);


  const {
    isLoading,
    data: AllCoupons,
    refetch,
  } = useViewGet("AllCoupons", `coupon/getCoupons?page=${currentPage}&limit=${rowsPerPage}&search=${searchQuery}`, "");



  const { mutateAsync: deleteCoupon } = useDelete();


  const handleDeleteCoupon = async() => {
    if (selectedCCouponId !== null) {
      // Implement your delete logic here, e.g., make an API call
      console.log("Deleting user with ID:", selectedCCouponId);
      try {
        const response = await deleteCoupon({
          route: "coupon/deleteCoupon",
          id: selectedCCouponId,
        });
        
        if (response.error === false) {
          refetch();
        } else if (response.error) {
          console.error("Error posting data:", response);
        } else {
          // ShowNotification("Something bad happened.", "error");
        }
      } catch (error) {
        console.error("Error posting data:", error);
      }
      // After deleting the user, hide the modal and clear the selected user ID
      setShowDeleteModal(false);
      setSelectedCCouponId(null);
      // Refresh the user list or update the state accordingly
    }
  };

  const confirmDelete = (userId: number) => {
    setSelectedCCouponId(userId);
    setShowDeleteModal(true);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (AllCoupons) {
      const initialSort = AllCoupons?.data?.coupons
        ?.map((user: any, index: number) => ({ ...user, index: (currentPage - 1) * 10 + index + 1 }));

      setSortedArray(initialSort);
    }
  }, [AllCoupons]);

  useEffect(() => {
    refetch();
    if (couponPageRef.current) {
      couponPageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [searchQuery, currentPage]);

  const handleSearch = (value: string) => {
    setCurrentPage(1)
    setSearchQuery(value)
  }

  const columns: TableColumn<Coupon>[] = [
    {
      name: "No#",
      selector: (row) => row?.index.toString(),
      sortable: true,
      width: '10%',
    },
    {
      name: "Coupon Code",
      selector: (row) => row?.coupon_code,
      sortable: true,
      width: '30%',
    },
    {
      name: "Free scans",
      selector: (row) => row?.free_scans,
      sortable: true,
      width: '20%',
    },
    {
      name: "Valid Date",
      selector: (row) => moment(row.expiry_date).format("DD/MM/YYYY"),
      sortable: true,
      width: '15%',
    },
    {
      name: "Status",
      cell: (row) => (
        <div
          className={`flex flex-row justify-center items-center px-3 py-1 capitalize text-white font-Urbanist text-base font-normal leading-[18px] rounded-lg ${
            row?.is_active
              ? "bg-[#16a34a]"
              : "bg-[#dc2626]"
          }`}
        >
          {row?.is_active ? 'Active' : 'Inactive'}
        </div>
      ),
      sortable: true,
      width: '15%',
    },
    // ... other columns ...
    {
      name: "Actions",
      width: '10%',
      cell: (row, rowIndex) => (
        <DropdownActions row={row} rowIndex={rowIndex} totalRows={10} actions={"edit,delete"} onConfirmDelete={confirmDelete}/>
      ),
    },
  ];

  return (
    <div className="p-4 bg-transparent" ref={couponPageRef}>
      <GenericRowComponent
        heading="Coupon Code Management"
        showInput
        buttonLabel='+ Add New'
        buttonLink='add'
        onInputChange={handleSearch}

      />
      <div className="w-full h-full">
        {isLoading ? (
          <div className=" absolute inset-0 flex items-center justify-center">
          <Loading />
          </div>
        ) : (
          <TableComponent
            columns={columns}
            data={sortedArray}
            totalRows={AllCoupons?.data?.total}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            progressPending={isLoading}
          />
        )}
      </div>
      {showDeleteModal && selectedCCouponId && (
        <>
        <Modal
        isOpen= {showDeleteModal ? true : false}
          title="Are you sure that you want to delete?"
          // message="You won't be able to revert this!"
          onConfirm={handleDeleteCoupon}
          onCancel={() => setShowDeleteModal(false)}
        />
        </>
      )}
    </div>
  );
};

export default CouponManagementPage;
