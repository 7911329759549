import React from "react";
import { useViewGet } from "../../services/Api/general";
import RadialProgressChart from "../../components/Common/chart/chartForResult";

// Define the props interface
interface SelectedFaceMatchResultProps {
  selectedScanID: number;
  selectedProfileID: number;
  setSelectedScanID: any;
}

const AppColors = {
  greenUpperColor: "rgba(125, 207, 59, 0.9)",
  greenLowerColor: "rgba(182, 202, 66, 0.9)",
};

const SelectedFaceMatchResult: React.FC<SelectedFaceMatchResultProps> = ({
  selectedScanID,
  setSelectedScanID,
  selectedProfileID,
}) => {
  const {
    isLoading,
    data: SingleProfileFaceMatchResult,
  } = useViewGet(
    "SingleProfileFaceMatchResult",
    `faceMatch/getFaceMatchResult/${selectedProfileID}/${selectedScanID}`,
    ""
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const scoreOverall = parseFloat((SingleProfileFaceMatchResult?.data.adjusted_score).toFixed(2));
  const noseScore = SingleProfileFaceMatchResult?.data.nose_match_score;
  const jawlineScore = SingleProfileFaceMatchResult?.data.jawline_match_score;
  // const cheekboneScore = SingleProfileFaceMatchResult?.data.cheekbone_match_score || 0;
  const eyesScore = SingleProfileFaceMatchResult?.data.eyes_match_score;
  const lipsScore = SingleProfileFaceMatchResult?.data.lips_match_score;

  return (
    <div className="p-4 px-2 bg-transparent">
      <div className="flex items-center my-3 mb-5">
        <button
          className="mr-4 px-3 py-2 bg-[#FFFFFF1A]"
          onClick={() => setSelectedScanID(undefined)}
        >
          <svg
            width="11"
            height="19"
            viewBox="0 0 11 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.2273 1.54665C10.3176 1.45913 10.3881 1.35618 10.4349 1.24368C10.4817 1.13118 10.5039 1.01133 10.5002 0.890968C10.4965 0.770608 10.4669 0.652097 10.4133 0.542201C10.3596 0.432306 10.2828 0.333178 10.1873 0.250478C10.0919 0.167778 9.97956 0.103126 9.85684 0.0602114C9.73411 0.0172972 9.60336 -0.00303815 9.47206 0.000366668C9.34076 0.00377149 9.21147 0.0308497 9.09159 0.0800552C8.9717 0.129261 8.86356 0.19963 8.77334 0.287145L0.273343 8.53714C0.0978063 8.70734 0 8.93268 0 9.16689C0 9.40111 0.0978063 9.62645 0.273343 9.79665L8.77334 18.0476C8.86296 18.137 8.97108 18.2092 9.09141 18.26C9.21173 18.3108 9.34187 18.3392 9.47427 18.3435C9.60666 18.3478 9.73867 18.328 9.86263 18.2851C9.98659 18.2423 10.1 18.1773 10.1963 18.0939C10.2927 18.0105 10.3699 17.9104 10.4237 17.7995C10.4775 17.6885 10.5067 17.5688 10.5095 17.4474C10.5124 17.326 10.489 17.2053 10.4405 17.0922C10.3921 16.9792 10.3196 16.8761 10.2273 16.789L2.37534 9.16689L10.2273 1.54665Z"
              fill="white"
            />
          </svg>
        </button>

        <h1 className="text-4xl font-Urbanist font-semibold text-white leading-7">
          Back to Table
        </h1>
      </div>
      <div className="flex flex-col items-center gap-4 bg-[#FFFFFF0F] p-6 rounded-xl">
        <div className="flex items-center gap-8 relative">
          <img
            src={SingleProfileFaceMatchResult?.data.person1_image}
            alt="Person 1"
            className="min-w-[407px] h-auto rounded-lg object-cover"
          />
          <img
            src={SingleProfileFaceMatchResult?.data.person2_image}
            alt="Person 2"
            className="min-w-[407px] h-auto rounded-lg object-cover"
          />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <RadialProgressChart
              score={scoreOverall}
              title="Overall"
              progressGradientColor={[
                AppColors.greenUpperColor,
                AppColors.greenLowerColor,
              ]}
            />
          </div>
        </div>

        <div className="flex justify-between w-full mt-6">
          {[
            { title: "Jawline", score: jawlineScore },
            { title: "Eyes", score: eyesScore },
            { title: "Nose", score: noseScore },
            { title: "Lips", score: lipsScore },
          ].map((feature, index) => (
            <div
              key={index}
              className="flex flex-col items-center bg-[#FFFFFF1A] p-4 rounded-lg w-1/5"
            >
              <RadialProgressChart
                score={feature.score}
                title={feature.title}
                progressGradientColor={[
                  AppColors.greenUpperColor,
                  AppColors.greenLowerColor,
                ]}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectedFaceMatchResult;