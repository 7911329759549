import { useNavigate } from "react-router-dom";
import showToast from "../../../components/Common/toastNotifications";
import LoginPageHeader from "../../../components/Auth/AuthLeftPage";

export const PasswordChangedSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex items-center justify-between gap-4 px-14 py-16 add_backgrounndAtBottom w-full">
      <LoginPageHeader
        leftImageUrl="/assets/images/authpageImages/passwordChangedpageImageLeft.png"
        rightImageUrl="/assets/images/authpageImages/passwordChangedpageImageRight.png"
      />
      <div className="md:w-1/2 py-6 px-10 md:px-24 w-full flex justify-center">
        <div className="max-w-[90%] w-full">
          <div className="ml-4 mb-12 flex items-center justify-center flex-col gap-y-5">
            <span className="flex justify-center items-center font-['Urbanist'] text-[46px] font-bold leading-[55px] text-[#fff] absolute top-[130px] text-center whitespace-nowrap z-10">
              Password Changed
            </span>
            <span className="flex  justify-center items-center font-['Urbanist'] text-[16px] font-light leading-[24px] text-[#fff] absolute top-[193px]  text-center whitespace-nowrap z-[18]">
              Your password has been changed successfully.
            </span>
          </div>

          <div className="space-y-6 bg-transparent ml-8 flex justify-center flex-col gap-y-4 items-center rounded-lg ">
            <div className="space-y-4 text-center">
              <svg
                width="172"
                height="172"
                viewBox="0 0 172 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M102.651 87.4245H115.156C117.482 87.4245 119.518 85.3888 119.518 82.4808C119.518 79.8636 117.482 77.5371 115.156 77.5371H105.269"
                  stroke="white"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M97.417 97.0212H112.539C114.865 97.0212 116.901 94.9856 116.901 92.0776C116.901 89.4603 114.865 87.1339 112.539 87.1339H102.651"
                  stroke="white"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M105.269 77.8282H117.774C120.1 77.8282 122.136 75.7926 122.136 72.8846C122.136 70.2673 120.1 67.9409 117.774 67.9409H88.9839C88.9839 67.9409 99.1621 58.6352 91.6012 41.4777C90.7288 39.7329 88.4023 38.5697 86.3667 39.1513C84.3311 39.7329 82.877 41.7685 83.1678 43.8042C83.1678 47.5846 82.877 53.6915 79.969 59.7984C77.6426 65.0329 72.4081 68.2317 66.8828 68.2317V101.965L71.2449 105.455C72.4081 106.327 73.8621 106.909 75.3161 106.909H107.595C109.922 106.909 111.957 104.873 111.957 101.965C111.957 99.3478 109.922 97.0213 107.595 97.0213H97.7081"
                  stroke="white"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M51.1789 111.562H60.4847C63.9743 111.562 66.8824 108.654 66.8824 105.164V66.778C66.8824 63.2883 63.9743 60.3803 60.4847 60.3803H51.1789C47.6893 60.3803 44.7812 63.2883 44.7812 66.778V105.164C44.7812 108.654 47.6893 111.562 51.1789 111.562Z"
                  stroke="white"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M55.8322 103.419C57.9201 103.419 59.6127 101.726 59.6127 99.6386C59.6127 97.5507 57.9201 95.8582 55.8322 95.8582C53.7443 95.8582 52.0518 97.5507 52.0518 99.6386C52.0518 101.726 53.7443 103.419 55.8322 103.419Z"
                  stroke="white"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M102.651 87.4245H115.156C117.482 87.4245 119.518 85.3888 119.518 82.4808C119.518 79.8636 117.482 77.5371 115.156 77.5371H105.269"
                  stroke="url(#paint0_linear_3469_8448)"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M97.417 97.0212H112.539C114.865 97.0212 116.901 94.9856 116.901 92.0776C116.901 89.4603 114.865 87.1339 112.539 87.1339H102.651"
                  stroke="url(#paint1_linear_3469_8448)"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M105.269 77.8282H117.774C120.1 77.8282 122.136 75.7926 122.136 72.8846C122.136 70.2673 120.1 67.9409 117.774 67.9409H88.9839C88.9839 67.9409 99.1621 58.6352 91.6012 41.4777C90.7288 39.7329 88.4023 38.5697 86.3667 39.1513C84.3311 39.7329 82.877 41.7685 83.1678 43.8042C83.1678 47.5846 82.877 53.6915 79.969 59.7984C77.6426 65.0329 72.4081 68.2317 66.8828 68.2317V101.965L71.2449 105.455C72.4081 106.327 73.8621 106.909 75.3161 106.909H107.595C109.922 106.909 111.957 104.873 111.957 101.965C111.957 99.3478 109.922 97.0213 107.595 97.0213H97.7081"
                  stroke="url(#paint2_linear_3469_8448)"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M51.1789 111.562H60.4847C63.9743 111.562 66.8824 108.654 66.8824 105.164V66.778C66.8824 63.2883 63.9743 60.3803 60.4847 60.3803H51.1789C47.6893 60.3803 44.7812 63.2883 44.7812 66.778V105.164C44.7812 108.654 47.6893 111.562 51.1789 111.562Z"
                  stroke="url(#paint3_linear_3469_8448)"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M55.8322 103.419C57.9201 103.419 59.6127 101.726 59.6127 99.6386C59.6127 97.5507 57.9201 95.8582 55.8322 95.8582C53.7443 95.8582 52.0518 97.5507 52.0518 99.6386C52.0518 101.726 53.7443 103.419 55.8322 103.419Z"
                  stroke="url(#paint4_linear_3469_8448)"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M85.8193 2V16.0903"
                  stroke="#9A9999"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M85.8193 155.548V170"
                  stroke="#9A9999"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M169.639 85.8189H155.549"
                  stroke="#9A9999"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.0903 85.8191H2"
                  stroke="#9A9999"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.2002 127.729L25.4841 120.865"
                  stroke="#9A9999"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M146.155 50.7741L158.439 43.9096"
                  stroke="#9A9999"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M43.9102 13.2003L50.7747 25.4841"
                  stroke="#9A9999"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M120.865 146.155L127.73 158.439"
                  stroke="#9A9999"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M158.439 127.729L146.155 120.865"
                  stroke="#9A9999"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25.4841 50.7741L13.2002 43.9095"
                  stroke="#9A9999"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M43.9102 158.439L50.7747 146.155"
                  stroke="#9A9999"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M120.865 25.4841L127.73 13.2003"
                  stroke="#9A9999"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_3469_8448"
                    x1="118.569"
                    y1="77.5371"
                    x2="103.783"
                    y2="77.9997"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#3FDFA1" />
                    <stop offset="1" stop-color="#FEE591" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_3469_8448"
                    x1="115.805"
                    y1="87.1339"
                    x2="98.7296"
                    y2="87.751"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#3FDFA1" />
                    <stop offset="1" stop-color="#FEE591" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_3469_8448"
                    x1="119.027"
                    y1="39"
                    x2="70.5528"
                    y2="39.7233"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#3FDFA1" />
                    <stop offset="1" stop-color="#FEE591" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_3469_8448"
                    x1="65.6387"
                    y1="60.3803"
                    x2="46.2461"
                    y2="60.5338"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#3FDFA1" />
                    <stop offset="1" stop-color="#FEE591" />
                  </linearGradient>
                  <linearGradient
                    id="paint4_linear_3469_8448"
                    x1="59.1872"
                    y1="95.8582"
                    x2="52.5547"
                    y2="95.9798"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#3FDFA1" />
                    <stop offset="1" stop-color="#FEE591" />
                  </linearGradient>
                </defs>
              </svg>
            </div>

            <button
              type="button"
              onClick={() => navigate("/influencers/login")}
              className="w-full bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600 text-black p-3 rounded-lg  md:mt-5 font-bold text-lg"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
