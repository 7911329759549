// SurveySection.tsx
import React from "react";
interface Question {
  optionId: number;
  optionText: string;
  answerCount: number;
  percentage: number;
}

interface SurveySectionProps {
  surveyTitle: string;
  questions: Question[];
  title: string;
  text: string;
}
const SurveySection: React.FC<SurveySectionProps> = ({
  surveyTitle,
  questions,
  title,
  text,
}) => {
  return (
    <div>
      <p className="font-Urbanist font-semibold text-left text-4xl leading-9 text-white">
        {surveyTitle}
      </p>
      <div className="mt-4 px-4 py-6 bg-[#FFFFFF1A] rounded-lg">
        <div className="w-[585px] max-w-full min-w-auto bg-[#FFFFFF1A] p-3 rounded-lg">
          <p className="font-Urbanist text-[22px] font-semibold leading-custom text-white">
            {title}
          </p>
          <p className="font-Urbanist text-base font-medium leading-6 text-white ">
            {text}
          </p>
        </div>
        <div className="mt-3">
          <p className="font-Urbanist text-[22px] font-semibold leading-custom text-white">
            Answers
          </p>
          <div className="mt-2 flex flex-row justify-between gap-2">
            {questions.map((question, index) => (
              <div key={question.optionId} className="w-full">
                <div
                  className="px-3 py-2 font-Urbanist text-base font-medium leading-5 min-h-[70px] w-full text-white rounded-lg flex items-center justify-center"
                  style={{
                    boxShadow: "0px 4px 31px 0px #B6CA42E5 inset",
                    border: "1px solid #B6CA42E5",
                  }}
                >
                  {question.optionText}
                </div>
                <p className=" font-Urbanist text-[13px] font-medium text-white leading-4">
                  <span className={`text-lg font-extrabold text-[#c7686a]
                  ${index === 0 && '!text-[#c7686a]'} 
                  ${index === 1 && '!text-[#998cb6]'} 
                  ${index === 2 && '!text-[#7591e2]'} 
                  ${index === 3 && '!text-[#95a88f]'} 
                  ${index === 4 && '!text-[#e1ca96]'} 

                  `}>
                    {question.percentage.toFixed(1)}%
                  </span>{" "}
                  Users
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveySection;
