import { useParams } from "react-router-dom";
import GenericRowComponent from "../../../components/Dashboard/genericHeaderRow";
import ProfileView from "../../../components/Dashboard/profileSectionWithImage";
import { useView } from "../../../services/Api/general";
import Loading from "../../../components/Loading";
import { ProfileCard } from "../../../components/Common/profileCard";
import { useState } from "react";
import ProfileBlockHeadingValue from "../../../components/Common/subProfileBlockHeadingValue";
import moment from "moment";

const platformImages: { [key: string]: string } = {
    tiktok: '/assets/images/socialIcon/tiktok_circle.svg',
    youtube: '/assets/images/socialIcon/youtube_circle.svg',
    facebook: '/assets/images/socialIcon/facebook_circle.svg',
    instagram: '/assets/images/socialIcon/instagram_circle.svg',
  };
  
const ViewInfluencerPage = () => {
  const { id } = useParams(); // Get the celebrity ID from the URL

  const { isLoading, data: influData } = useView(
    "/influencer/",
    id
  );
  const [selectedPlateform, setSelectedPlateform] = useState<any | null>(null);

  const handleProfileClick = (profile: any) => {
    setSelectedPlateform(profile);
  };

  return (
    <>
      <div className="p-4 bg-transparent">
        <GenericRowComponent heading="View Influencer" showBackButton />
        <div className="mx-4">
          {isLoading ? (
            <div className=" absolute inset-0 flex items-center justify-center">
            <Loading />
            </div>
          ) : (
            <div className="mt-6">
              <ProfileView
                imageUrl={influData?.data?.influencers[0]?.profile_image}
                name={influData?.data?.name}
                email={influData?.data?.email}
                description={influData?.data?.influencers[0]?.description}
                totalScansForInfluencer={influData?.data?.platforms_free_scans  || "0"}
              />
              <div className="flex flex-row justify-start w-full gap-4 mt-12">
                {influData?.data?.platform_promo_code?.map((plateform: any) => (
                  <ProfileCard
                    key={plateform.id}
                    name={plateform.name}
                    imageUrl={
                        platformImages[plateform.name] || "/assets/images/square-user.png"
                    }
                    onClick={() => handleProfileClick(plateform)}
                    selectedUser={
                      selectedPlateform?.id === plateform?.id ? true : false
                    }
                  />
                ))}
              </div>
              {selectedPlateform && (
                <div className="selected-profile-details mt-4">
                  {/* Render more details of the selected profile */}
                  <div
                    className={`mt-8 rounded-lg p-6`}
                    style={{
                      border: "1px solid #FFFFFF85",
                      background: "#FFFFFF36",
                    }}
                  >
                    <div className=" flex flex-row justify-start gap-12">
                      <ProfileBlockHeadingValue
                        heading="Promo code"
                        value={selectedPlateform?.promoCode || ""}
                      />
                      <ProfileBlockHeadingValue
                        heading="Scans"
                        value={selectedPlateform?.free_scans || "0"}
                      />
                      <ProfileBlockHeadingValue
                        heading="Expiry Date"
                        value={selectedPlateform?.promoCode ? moment(selectedPlateform?.expiryDate).format('DD-MM-YYYY') : ''}
                      />
                      <ProfileBlockHeadingValue
                        heading="Scan Counts"
                        value={selectedPlateform?.use_count || "0"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewInfluencerPage;
