import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ActionButtonsProps {
  isSubmitting: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  handleChangePassword?: () => void;
  submitButtonType?: 'button' | 'submit' | 'reset';
  submitButtonText?: string;
  editInfluencerChangePassword?: boolean
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  isSubmitting,
  onCancel,
  onSubmit,
  handleChangePassword,
  submitButtonType = 'submit',
  submitButtonText = 'Save',
  editInfluencerChangePassword = false
}) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="flex justify-end mt-6 items-center w-full">
      <div className="flex flex-row justify-between gap-4 pr-3">
        <button
          type="button"
          onClick={handleCancel}
          className="px-3 py-2 font-Urbanist text-md font-normal leading-[22px] text-left text-[#76e19c] rounded-md"
          style={{
            border: "1px solid #76e19c",
          }}
        >
          Cancel
        </button>
        {editInfluencerChangePassword && (

        <button
          type="button"
          onClick={handleChangePassword}
          className="px-3 py-2 font-Urbanist text-md font-normal leading-[22px] text-left text-[#76e19c] rounded-md"
          style={{
            border: "1px solid #76e19c",
          }}
        >
          Change password
        </button>
        )}
        <button
          type={submitButtonType} // This should probably be "submit" if it's intended to submit a form
          onClick={onSubmit}
          className="inline-flex justify-center items-center py-2 px-4 font-Urbanist text-md font-normal leading-[22px] text-left border border-transparent shadow-sm rounded-md text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          disabled={isSubmitting}
          style={{
            background: "linear-gradient(268.95deg, #3FDFA1 5.53%, #FEE591 91.7%)",
          }}
        >
          {isSubmitting ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Processing...
              </>
            ) : (
              submitButtonText
            )}
        </button>
      </div>
    </div>
  );
};

export default ActionButtons;
