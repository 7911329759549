import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Navigate, useNavigate } from "react-router-dom"; // Make sure this is imported
import Layout from "./Layout";
import { getAccessToken, getRefreshToken, removeTokens } from "../services/tokenHandler";
import { logoutUser } from "../redux/userSlice";

interface ProtectedRouteProps {
  children: JSX.Element;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    if (!accessToken || !refreshToken) {
      dispatch(logoutUser());
      removeTokens();
      navigate("/login")
    }
  }, []);

  const path = window.location.pathname;

  if (!user?.data || !getAccessToken() || !getRefreshToken()) {
    return <Navigate to="/login" replace />;
  } 

  // Redirect logic for users with the role 'Influencer'
  if (user.data.role === "Influencer") {
    // Allow access only to paths starting with '/influencers/'
    if (!path.startsWith("/influencers/")) {
      return <Navigate to="/influencers/my_profile" replace />;
    }
  } else {
    // For users with roles other than 'Influencer', restrict access to '/influencers/' paths
    if (path.startsWith("/influencers/")) {
      return <Navigate to="/" replace />;
    }
  }

  // If there is a user with the correct role, render the children components
  return <Layout user={user?.data}>{children}</Layout>;
};
