import React from "react";

interface Recommendation {
  id: number;
  title_image: string;
  icon_image: string | null;
  title: string;
  details: string;
  // ... include other properties as needed
}

interface RecommendationInformationSectionProps {
  recommendationData: Recommendation[];
}

const RecommendationInformationSection: React.FC<
  RecommendationInformationSectionProps
> = ({ recommendationData }) => {
  const colors = ['#B6CA42E5', '#E9B20E', '#C03EFE', '#81A3F7'];
  return (
    <div className="my-3">
     
      <div className="w-full pt-2 flex flex-col gap-y-3">
        {recommendationData?.length > 0 ? (
          recommendationData.map((rec) => (
            <div
              key={rec.id}
              className="bg-[#FFFFFF1A] p-4 rounded-lg flex items-center justify-between"
            >
              <div className="flex items-center w-full justify-start gap-5 flex-row">
                <div className="flex flex-row justify-start items-center gap-2 w-2/5">
                  <div className="rounded-lg w-6 h-6" style={{ backgroundColor: colors[Math.floor(Math.random() * colors.length)]}} />
                  <h2 className="text-white text-lg font-medium">
                    {rec.title}
                  </h2>
                </div>
                <div className=" w-3/5">
                  <p
                    className="text-gray-300 w-[380px] max-w-[380px] overflow-auto max-h-12 truncate"
                    dangerouslySetInnerHTML={{ __html: rec.details }}
                  ></p>
                </div>
              </div>
              {/* <button className="text-white rounded-lg px-4 py-2 bg-[#FFFFFF1A]">
                <svg
                  width="4"
                  height="16"
                  viewBox="0 0 4 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2" cy="2" r="2" />
                  <circle cx="2" cy="8" r="2" />
                  <circle cx="2" cy="14" r="2" />
                </svg>
              </button> */}
            </div>
          ))
        ) : (
          <div className="bg-transparent w-full text-center text-white shadow-sm mx-auto p-6">
            No data found
          </div>
        )}
      </div>
    </div>
  );
};

export default RecommendationInformationSection;
