import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ProtectedRoute } from "./components/ProtectedRoute";
import LoginPage from "./pages/Auth/Login";
import UserManagementPage from "./pages/UserManagment/usermanagment";
import InfluencerPage from "./pages/Influencer/influencer";
import RecommndationsPage from "./pages/Recommendations";
import AddRecommendationPage from "./pages/Recommendations/Add";
import EditRecommendationPage from "./pages/Recommendations/Edit";
import ViewRecommendation from "./pages/Recommendations/View";
import CelebritiesManagement from "./pages/Celebrities/celebrities";
import AddCelebrities from "./pages/Celebrities/addCelebrities";
import EditCelebrity from "./pages/Celebrities/editCelebrities";
import ViewCelebrity from "./pages/Celebrities/viewCelebrities";
import SubscriptionPlan from "./pages/Subcription";
import ViewUserPage from "./pages/UserManagment/viewuser";
import CouponManagementPage from "./pages/CouponCode/couponmanagment";
import AddCouponCodes from "./pages/CouponCode/addCouponCode";
import EditCouponCode from "./pages/CouponCode/editCouponCode";
import AddIfluencer from "./pages/Influencer/addInfluecer";
import EditIfluencer from "./pages/Influencer/editInfluencer";
import ViewInfluencer from "./pages/Influencer/viewInfluecer";
import SurveyPage from "./pages/Survey/surveyPage";
import ResultUserListPage from "./pages/Result/resultUserIndex";
import ResultViewSingleUserScans from "./pages/Result/resultSingleUserProfile";
import AuthLayout from "./components/Auth/AuthLayout";
import LoginInfluencer from "./pages/Influencers/(Auth)/Login";
import { ForgetUserEmailinfluencer } from "./pages/Influencers/(Auth)/ForgetUserEmail";
import OtpInfluencer from "./pages/Influencers/(Auth)/Otp";
import { ChangePasswordinfluencer } from "./pages/Influencers/(Auth)/ChangePassword";
import { PasswordChangedSuccess } from "./pages/Influencers/(Auth)/ChangePasswordSuccess";
import ViewMyProfile from "./pages/Influencers/my-profile";
import EditMyProfile from "./pages/Influencers/my-profile/edit";
import InfluencerTable from "./pages/Influencers/influencer";
import ViewInfluencerPage from "./pages/Influencers/influencer/viewInfluecer";
import FaceMatchResultUserListPage from "./pages/FaceMatch/resultUserIndex";
import ResultViewSingleUserFaceMatch from "./pages/FaceMatch/resultSingleUserProfile";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/user_management" />} />
      <Route path="*" element={<Navigate to="/user_management" />} />
      <Route path="/login" element={<AuthLayout><LoginPage /></AuthLayout>} />
      <Route path="/influencers/login" element={<AuthLayout><LoginInfluencer /></AuthLayout>} />
      <Route path="/influencers/forget_email" element={<AuthLayout><ForgetUserEmailinfluencer /></AuthLayout>} />
      <Route path="/influencers/otp" element={<AuthLayout><OtpInfluencer /></AuthLayout>} />
      <Route path="/influencers/change_password" element={<AuthLayout><ChangePasswordinfluencer /></AuthLayout>} />
      <Route path="/influencers/change_password_success" element={<AuthLayout><PasswordChangedSuccess /></AuthLayout>} />

      {/* route for user_management */}
      <Route path="/user_management">
        <Route index element={<ProtectedRoute><UserManagementPage /></ProtectedRoute>} />
        <Route path="view/:id" element={<ProtectedRoute><ViewUserPage /></ProtectedRoute>} />
      </Route>

      {/* route for result */}
      <Route path="/result">
        <Route index element={<ProtectedRoute><ResultUserListPage /></ProtectedRoute>} />
        <Route path="view/:id" element={<ProtectedRoute><ResultViewSingleUserScans /></ProtectedRoute>} />
      </Route>

      {/* route for face match result */}
      <Route path="/face_match_result">
        <Route index element={<ProtectedRoute><FaceMatchResultUserListPage /></ProtectedRoute>} />
        <Route path="view/:id" element={<ProtectedRoute><ResultViewSingleUserFaceMatch /></ProtectedRoute>} />
      </Route>

      {/* recommendation section */}
      <Route path="/recommendations">
        <Route index element={<ProtectedRoute><RecommndationsPage /></ProtectedRoute>} />
        <Route path="add" element={<ProtectedRoute><AddRecommendationPage /></ProtectedRoute>} />
        <Route path="edit/:id" element={<ProtectedRoute><EditRecommendationPage /></ProtectedRoute>} />
        <Route path="view/:id" element={<ProtectedRoute><ViewRecommendation /></ProtectedRoute>} />
      </Route>

      {/* route for celebrities */}
      <Route path="/celebrities">
        <Route index element={<ProtectedRoute><CelebritiesManagement /></ProtectedRoute>} />
        <Route path="add" element={<ProtectedRoute><AddCelebrities /></ProtectedRoute>} />
        <Route path="edit/:id" element={<ProtectedRoute><EditCelebrity /></ProtectedRoute>} />
        <Route path="view/:id" element={<ProtectedRoute><ViewCelebrity /></ProtectedRoute>} />
      </Route>

      <Route path="/subscription" element={<ProtectedRoute><SubscriptionPlan /></ProtectedRoute>} />
      <Route path="/survey" element={<ProtectedRoute><SurveyPage /></ProtectedRoute>} />

      {/* route for coupon */}
      <Route path="/coupon-code">
        <Route index element={<ProtectedRoute><CouponManagementPage /></ProtectedRoute>} />
        <Route path="add" element={<ProtectedRoute><AddCouponCodes /></ProtectedRoute>} />
        <Route path="edit/:id" element={<ProtectedRoute><EditCouponCode /></ProtectedRoute>} />
      </Route>

      {/* route for influencer */}
      <Route path="/influencer">
        <Route index element={<ProtectedRoute><InfluencerPage /></ProtectedRoute>} />
        <Route path="add" element={<ProtectedRoute><AddIfluencer /></ProtectedRoute>} />
        <Route path="edit/:id" element={<ProtectedRoute><EditIfluencer /></ProtectedRoute>} />
        <Route path="view/:id" element={<ProtectedRoute><ViewInfluencer /></ProtectedRoute>} />
      </Route>

      {/* Routes for all Influencers dashboard */}
      <Route path="/influencers/influencer">
        <Route index element={<ProtectedRoute><InfluencerTable /></ProtectedRoute>} />
        <Route path="view/:id" element={<ProtectedRoute><ViewInfluencerPage /></ProtectedRoute>} />
      </Route>
      <Route path="/influencers/my_profile">
        <Route index element={<ProtectedRoute><ViewMyProfile /></ProtectedRoute>} />
        <Route path="edit" element={<ProtectedRoute><EditMyProfile /></ProtectedRoute>} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
