import { useParams } from "react-router-dom";
import { useView } from "../../services/Api/general";
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface RatioType {
  name: string;
  value: string;
  is_numeric: boolean;
  data: string[] | null;
}

interface Recommendation {
  id: number;
  is_general: boolean;
  title_image?: string;
  icon_image?: string;
  title: string;
  intro: string;
  description?: string;
  details: string;
  images?: string;
  has_criterias: boolean;
  is_personalised_tip: boolean;
  is_active?: boolean;
  parent_id?: number | null;
  created_at?: string;
  updated_at?: string;
  children?: Recommendation[];
  criterias?: any[];
}

interface GeneralApiResponse {
  data: RatioType[];
  // other properties...
}

const Feature: React.FC<{ title: string; value: string | number }> = ({
  title,
  value,
}) => {
  return (
    <div
      className="max-w-[320px] px-4 py-2 rounded-lg bg-[#FFFFFF1A]"
      style={{
        boxShadow: "0px 4px 10px 0px #486D9F inset",
      }}
    >
      <p className="font-Urbanist text-[1.375rem] font-semibold leading-[1.65rem] text-left text-white">
        {title}
      </p>
      <p className="font-Urbanist text-[1rem] font-medium leading-[1.5rem] text-left text-white/80 truncate">
        {value}
      </p>
    </div>
  );
};

const ViewRecommendation = () => {
  const { id } = useParams();

  const {
    isLoading,
    data: recommendation,
    refetch,
  } = useView("recommendations", id);
  useEffect(() => {
      refetch();
      window.scrollTo(0, 0)
  }, [id]);

  return (
    <div>
      <GenericRowComponent heading="View Recommendation" showBackButton />
      <div className="space-y-4 my-2 mr-4 mb-7">
        <div className=" mt-4 p-4">
          <div className="flex flex-row items-center justify-start gap-3">
            <label
              htmlFor="is_general"
              className="block  font-Urbanist text-4xl font-semibold leading-[32px] text-left text-white"
            >
              General
            </label>

            <label className="switch">
              <input
                type="checkbox"
                value={recommendation?.data?.is_general}
                readOnly
                disabled
              />
              <span className="slider"></span>
            </label>
          </div>

          <div className="flex flex-row justify-start gap-2 pt-5 mt-3 pr-3">
            <div className="w-1/5">
              <div className=" w-[203px] min-h-[174px] flex items-center justify-center relative rounded-lg">
                <img
                  src={recommendation?.data.title_image}
                  alt="title image"
                  className="rounded-md"
                />
              </div>
            </div>
            <div className="flex-1">
              <p className="font-urbanist text-[2.375rem] font-semibold leading-[2.85rem] text-left text-white">
                {recommendation?.data.title}
              </p>
              <span className="text-white font-urbanist text-[1rem] font-normal leading-[1.125rem] text-left">
                {recommendation?.data.intro}
              </span>
              {recommendation?.data.description && (
                <div className="mt-5">
                  <p className="font-urbanist text-[1.125rem] font-medium leading-[1.35rem] text-left text-white">
                    Description
                  </p>
                  <span className="font-urbanist text-[0.875rem] font-light leading-[1.05rem] text-left text-white/70">
                    {recommendation?.data.description}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className=" bg-[#FFFFFF1A] rounded-xl mt-4 p-4">
            <div>
              <p className="font-urbanist text-[2rem] font-semibold leading-[2.4rem] text-left text-white pb-3">
                Images
              </p>
              <div className="flex flex-wrap gap-4">
                {recommendation?.data.images ? (
                  recommendation.data.images
                    .split(",")
                    .map((url: string, index: number) => (
                      <div
                        key={index}
                        className="w-[103px] h-[101px] relative rounded-lg overflow-hidden"
                      >
                        <img
                          src={url}
                          alt={`Preview ${index}`}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    ))
                ) : (
                  <div className="oulineGradiant cursor-pointer p-2">
                    <button
                      type="button"
                      className="w-[101px] h-[85px] flex items-center justify-center rounded-lg bg-[#E1F0FF] font-roboto text-base leading-tight tracking-none"
                    >
                      No Images
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div>
              <p className="font-urbanist text-[2rem] font-semibold leading-[2.4rem] text-left text-white pt-4 pb-3">
                Detail
              </p>
              <div className="font-urbanist !text-[1.375rem] font-medium !leading-[1.09rem] text-left text-white">
                <ReactQuill
                  value={recommendation?.data.details || ""}
                  readOnly={true}
                  theme="bubble" // Use 'bubble' theme for a better view-only experience
                />
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-start items-center gap-5 my-5">
            <label
              htmlFor="is_personalised_tip"
              className="block text-white font-Urbanist text-2xl font-semibold leading-9 tracking-normal"
            >
              Is Personalized:
            </label>
            <label className="switch">
              <input
                type="checkbox"
                value={recommendation?.data.is_personalised_tip}
                readOnly
                disabled
              />
              <span className="slider"></span>
            </label>
          </div>

          {recommendation?.data.criterias.map((field: any, index: number) => (
            <div
              key={`${index}`}
              className="bg-[#FFFFFF1A] p-3 pb-6 rounded-lg mt-1 relative"
            >
              {" "}
              <h1 className=" text-white font-Urbanist text-[2rem] font-semibold leading-[2.4rem] tracking-normal mt-4">
                Criteria: {index + 1}
              </h1>
              <div className="mt-4 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 gap-y-4">
                <Feature title="Races" value={field?.races} />
                <Feature title="Genders" value={field?.genders} />
                <Feature title="Ratio" value={field?.ratio_name} />
                {field?.ratio_value && (
                  <Feature title="Ratio Value" value={field?.ratio_value} />
                )}
                {(field?.ratio_min_value || field?.ratio_min_value >= 0) && (
                  <Feature
                    title="Minimum Value"
                    value={field?.ratio_min_value}
                  />
                )}
                {field?.ratio_max_value && (
                  <Feature
                    title="Maximum Value"
                    value={field?.ratio_max_value}
                  />
                )}
              </div>
            </div>
          ))}

          <div className="mt-4 flex flex-row justify-start gap-2 flex-wrap gap-y-3">
            {recommendation?.data.children.map(
              (field: Recommendation, index: number) => {
                return (
                  <Link
                    key={`${field.id}/${index}`}
                    to={`/recommendations/view/${field.id}`}
                    className={`flex justify-center items-center gap-3  rounded-md space-x-2 bg-[#FFFFFF0F] cursor-pointer ${
                      field.id && "px-4 py-2"
                    }  font-Urbanist text-lg font-medium leading-6 text-white align-text-left`}
                  >
                    {field.title}
                  </Link>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRecommendation;
