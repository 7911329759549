import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "./AppRoutes";

const App: React.FC = () => {
  return (
    <>
      <ToastContainer />
      <Provider store={store}>
        <Router>
          <AppRoutes />
        </Router>
      </Provider>
    </>
  );
};

export default App;
