import React from "react";
import moment from "moment";
import Input from "../Common/Input";
interface SinglePlateformProps {
  register: any;
  errors: any;
  watch: any;
  label: string;
  plateformName: string;
}
const SinglePlateForm: React.FC<SinglePlateformProps> = ({
  register,
  errors,
  watch,
  label,
  plateformName,
}) => {
  const isPlatformActive = watch(`${plateformName}_is_active`);

  return (
    <div className="mt-4">
      <div className="flex flex-row items-center justify-start py-6 pb-4 gap-7">
        <label
          htmlFor="is_general"
          className="block  font-Urbanist text-4xl font-semibold leading-[32px] text-left text-white"
        >
          {label}
        </label>

        <label className="switch">
          <input type="checkbox" {...register(`${plateformName}_is_active`)} />
          <span className="slider"></span>
        </label>
      </div>

      <div>
        <div className="flex flex-row justify-between gap-3">
          <Input
            label="Promo code:"
            name="code"
            {...register(`${plateformName}_coupon_code`, {
              required: isPlatformActive ? "Code is required." : false,
            })}
            error={
              errors[`${plateformName}_coupon_code`] &&
              errors[`${plateformName}_coupon_code`].message
            }
            className="w-full"
            placeholder="Add Coupon Code"
            errorString="!text-red-300"
          />
          <Input
            label="Scans:"
            name="scans"
            {...register(`${plateformName}_free_scans`, {
              required: isPlatformActive ? "Scan is required." : false,
            })}
            error={
              errors[`${plateformName}_free_scans`] &&
              errors[`${plateformName}_free_scans`].message
            }
            className="w-full"
            type="number"
            min={0}
            placeholder="Add Free scan"
            errorString="!text-red-300"
          />

          <Input
            label="Expiry Date:"
            name="exp_name"
            {...register(`${plateformName}_expiry_date`, {
              required: isPlatformActive ? "Expire date is required." : false,
              min: {
                value: moment().format("YYYY-MM-DD"), // Generates today's date in YYYY-MM-DD format
                message: "Date cannot be before today",
              },
              max: {
                value: "3000-01-01",
                message: "Date cannot be after January 1, 3000",
              },
            })}
            error={
              errors[`${plateformName}_expiry_date`] &&
              errors[`${plateformName}_expiry_date`].message
            }
            type="date"
            placeholder="Add Date"
            className="w-full"
            errorString="!text-red-300"
          />
        </div>
      </div>
    </div>
  );
};

export default SinglePlateForm;
