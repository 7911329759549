import React from "react";

interface TabNavigationProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const tabs = [
  "General",
  "Ratings",
  "Analysis",
  "Recommendations",
  "Celebrity Comparison",
];

const TabNavigation: React.FC<TabNavigationProps> = ({
  activeTab,
  setActiveTab,
}) => {
  return (
    <div className="mt-2 flex flex-row justify-between gap-2">
      {tabs.map((tab) => (
        <button
          key={tab}
          className={`tab-item ${
            activeTab === tab ? "active" : "bg-[#FFFFFF1A]"
          } px-3 py-2 font-Urbanist text-base font-medium leading-5 min-h-[70px] w-full text-white rounded-lg flex items-center justify-center`}
          style={{
            boxShadow:
              activeTab === tab ? "0px 4px 31px 0px #B6CA42E5 inset" : "none",
            border: activeTab === tab ? "1px solid #B6CA42E5" : "none",
          }}
          onClick={() => setActiveTab(tab)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default TabNavigation;
