export const BoxIcon = (props: { iconcolor: string }) => (
  <>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.5 6.27775L9.99997 11M9.99997 11L1.49997 6.27775M9.99997 11L10 20.5M19 15.0586V6.94145C19 6.5988 19 6.42748 18.9495 6.27468C18.9049 6.13951 18.8318 6.01542 18.7354 5.91073C18.6263 5.7924 18.4766 5.7092 18.177 5.54279L10.777 1.43168C10.4934 1.27412 10.3516 1.19535 10.2015 1.16446C10.0685 1.13713 9.93146 1.13713 9.79855 1.16446C9.64838 1.19535 9.50658 1.27413 9.22297 1.43168L1.82297 5.5428C1.52345 5.7092 1.37369 5.7924 1.26463 5.91074C1.16816 6.01543 1.09515 6.13951 1.05048 6.27468C1 6.42748 1 6.5988 1 6.94145V15.0586C1 15.4012 1 15.5725 1.05048 15.7253C1.09515 15.8605 1.16816 15.9846 1.26463 16.0893C1.37369 16.2076 1.52345 16.2908 1.82297 16.4572L9.22297 20.5683C9.50658 20.7259 9.64838 20.8047 9.79855 20.8355C9.93146 20.8629 10.0685 20.8629 10.2015 20.8355C10.3516 20.8047 10.4934 20.7259 10.777 20.5683L18.177 16.4572C18.4766 16.2908 18.6263 16.2076 18.7354 16.0893C18.8318 15.9846 18.9049 15.8605 18.9495 15.7253C19 15.5725 19 15.4012 19 15.0586Z"
        stroke={props.iconcolor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);
