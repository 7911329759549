import React from "react";

interface RatingData {
  cheekbone: number;
  eye_tilt: number;
  eyebrows: number;
  eyes: number;
  harmony: number;
  jawline: number;
  lips: number;
  nose: number;
}

interface RatingInformationSectionProps {
  ratingData: RatingData;
}

const RatingInformationSection: React.FC<RatingInformationSectionProps> = ({
  ratingData,
}) => {
  const colors = ["#B6CA42E5", "#E9B20E", "#C03EFE", "#81A3F7"];

  // Prepare data for rendering
  const ratings = (ratingData) ? Object?.entries(ratingData)
    ?.filter(
      ([key]) =>
        !["id", "scan_result_id", "created_at", "updated_at"].includes(key)
    )
    ?.map(([key, value], index) => ({
      title: key.replace(/_/g, " "),
      value,
      color: colors[index % colors.length],
    })) : undefined;

  return (
    <div className="my-3">
     
      <div className="w-full pt-2 flex flex-col gap-y-3">
        {ratings ? (
          ratings?.map((rating, index) => (
            <div
              key={index}
              className="bg-[#FFFFFF1A] p-4 rounded-lg flex gap-12 items-center justify-between"
            >
              <div className="flex items-center w-full justify-between gap-5">
                <div className="w-2/5 flex flex-row gap-3">
                  <div
                    className="rounded-lg w-6 h-6"
                    style={{ backgroundColor: rating.color }}
                  />
                  <h2 className="text-white text-lg font-medium capitalize">
                    {rating.title}
                  </h2>
                </div>
                <div className="w-2/5 ">
                </div>  
                <div className=" w-2/5 text-center">
                <p className="text-white font-Urbanist text-2xl font-semibold leading-tight text-left">
                  {(rating.value * 10).toFixed(0)}
                </p>
                </div>
              </div>
              {/* <button className="text-white rounded-lg px-4 py-2 bg-[#FFFFFF1A]">
                <svg
                  width="4"
                  height="16"
                  viewBox="0 0 4 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2" cy="2" r="2" />
                  <circle cx="2" cy="8" r="2" />
                  <circle cx="2" cy="14" r="2" />
                </svg>
              </button> */}
            </div>
          ))
        ) : (
          <div className="bg-transparent w-full text-center text-white shadow-sm mx-auto p-6">
            No data found
          </div>
        )}
      </div>
    </div>
  );
};

export default RatingInformationSection;
