import { useForm } from "react-hook-form";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios"
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import { useEdit, useView } from "../../services/Api/general";
import CouponFieldSection from "../../components/CouponManagment/basicForm";
import ActionButtons from "../../components/Common/actionButtons";
import moment from "moment";
import showToast from "../../components/Common/toastNotifications";
import { useEffect } from "react";

type FormData = {
  expiry_date: string;
  free_scans: string;
  coupon_code: string;
};
const EditCouponCode = () => {
  const { id } = useParams(); // Get the celebrity ID from the URL
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const { mutateAsync: edit_coupon } = useEdit();

  const { isLoading, data: couponData } = useView("coupon/getCoupon", id);
  useEffect(() => {
    if (couponData?.data) {
      const { data } = couponData;
      // Populate the form with fetched data
      setValue("expiry_date", moment(data.expiry_date).format('YYYY-MM-DD'));
      setValue("free_scans", parseInt(data.free_scans));
      setValue("coupon_code", data.coupon_code);
     
    }
  }, [couponData]);

  const onSubmit = async (data: any) => {
    // setLoading(true);
    
    try {
      
      const submissionData = {
        ...data,
        expiry_date: moment(data.expiry_date).format('DD-MM-YYYY'),
        free_scans: parseInt(data.free_scans)
      };

      const response = await edit_coupon({
        route: `coupon/editCoupon/${id}`,
        data: submissionData,
      });
      if (!response.error) {
        showToast(response?.message || 'Coupon Edit Successfully!' , 'success');
        navigate("/coupon-code");
      } else {
        showToast(response.message || 'Coupon not Edited, please try again.', 'error');
      }

    
      navigate("/coupon-code"); // Navigate back to the celebrities list
    } catch (error) {
      console.error("Failed to update celebrity", error);
    } finally {
      // setLoading(false);
    }
  };


  return (
    <>
    <div className="p-4 bg-transparent">
      <GenericRowComponent heading="Edit Coupon Code" showBackButton />
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <div
          className={`mt-4 rounded-lg p-6`}
          style={{
            border: "1px solid #FFFFFF85",
            background: "#FFFFFF36",
          }}
        >
          <CouponFieldSection register={register} errors={errors} />
        </div>
        <ActionButtons isSubmitting={isSubmitting} />
      </form>
    </div>
  </>
  );
};

export default EditCouponCode;
