// RadialProgressChart.tsx

import React, { useEffect, useState } from "react";

interface RadialProgressChartProps {
  score: number;
  title: string;
  progressGradientColor: string[];
}

const RadialProgressChart: React.FC<RadialProgressChartProps> = ({
  score,
  title,
  progressGradientColor,
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Animate the progress
    let animationFrameId: number;

    const animateProgress = (start: number) => {
      const duration = 1000; // Animation duration in milliseconds
      const end = score;
      const step = (timestamp: number) => {
        if (!start) start = timestamp;
        const progress = timestamp - start;
        const currentProgress = Math.min(progress / duration, 1) * end;

        setProgress(currentProgress);

        if (progress < duration) {
          animationFrameId = requestAnimationFrame(step);
        }
      };

      animationFrameId = requestAnimationFrame(step);
    };

    animateProgress(0);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [score]);

  const size = 250;
  const strokeWidth = 15;
  const radius = size / 2 - strokeWidth * 2;
  const circumference = radius * 2 * Math.PI;

  const strokeDashoffset = circumference - (progress / 100) * circumference;

  const angleDeg = (progress / 100) * 360 - 223; // Adjust to start from the top (12 o'clock)

  const angleRad = (angleDeg * Math.PI) / 180;

  const iconX = size / 2 + radius * Math.cos(angleRad) - 52 / 2;
  const iconY = size / 2 + radius * Math.sin(angleRad) - 52 / 2;

  // Function to calculate path coordinates based on angle

  return (
    <div
      style={{
        margin: "10px",
        position: "relative",
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <svg width={size} height={size}>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop
              offset="19.65%"
              style={{ stopColor: "#3FDFA1", stopOpacity: "1" }}
            />
            <stop
              offset="94.96%"
              style={{ stopColor: "#FEE591", stopOpacity: "1" }}
            />
          </linearGradient>
        </defs>
        <circle
          stroke="rgba(0,0,0,0.1)"
          strokeWidth={strokeWidth}
          fill="transparent"
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          stroke="url(#gradient)"
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          transform={`rotate(${225 - 90} ${size / 2} ${size / 2})`}
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />

        <foreignObject
          x={iconX}
          y={iconY}
          width="52"
          height="52"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <svg
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            strokeLinecap="round"
            r={radius}
            // transform={`rotate(${0} ${size / 2} ${size / 2})`}
            style={{ transform: `rotate(${score + 15}deg)` }}
          >
            <circle
              cx="25.9998"
              cy="26"
              r="19"
              transform="rotate(29.2399 25.9998 26)"
              fill="#D4D1D1"
              fill-opacity="0.26"
            />
            <circle
              cx="25.9997"
              cy="26"
              r="17"
              transform="rotate(29.2399 25.9997 26)"
              fill="url(#paint0_linear_1682_4605)"
            />
            <circle
              cx="25.9997"
              cy="26"
              r="17"
              transform="rotate(29.2399 25.9997 26)"
              fill="#3BCB81"
              fill-opacity="0.9"
            />
            {/* <path
              d="M24.9863 30.2186L31.7916 28.298L29.871 21.4928"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.5971 27.2018L26.4024 25.2812L24.4818 18.476"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            /> */}

            <defs>
              <linearGradient
                id="paint0_linear_1682_4605"
                x1="41.0865"
                y1="9.00002"
                x2="11.2614"
                y2="9.54701"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#3FDFA1" />
                <stop offset="1" stop-color="#FEE591" />
              </linearGradient>
            </defs>
          </svg>
        </foreignObject>
      </svg>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <div
          className="text-white leading-9"
          style={{ fontSize: "52px", fontWeight: "bold" }}
        >
          {Math.round(progress)}
        </div>
        <div
          className="text-white  leading-9"
          style={{ fontSize: "18px", fontWeight: "normal" }}
        >
          Score
        </div>
        <div
          className="font-urbanist text-[28px] font-extrabold leading-tight text-center"
          style={{
            // background: "linear-gradient(180deg, #FEE591 0%, #4CDFA0 100%)",
            background:
              "linear-gradient(340.87deg, #3FDFA1 -3.85%, #FEE591 104.79%)",
            WebkitBackgroundClip: "text", // For Safari compatibility
            color: "transparent",
            WebkitTextFillColor: "transparent", // For Safari compatibility
          }}
        >
          {title}
        </div>
      </div>
    </div>
  );
};

export default RadialProgressChart;
