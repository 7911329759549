export const SettingIcon = (props: { iconcolor: string }) => (
  <>
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8064 6.62355L18.184 5.54346C17.6574 4.62954 16.4905 4.31426 15.5753 4.83866V4.83866C15.1397 5.09528 14.6198 5.16809 14.1305 5.04103C13.6411 4.91396 13.2224 4.59746 12.9666 4.16131C12.8021 3.88409 12.7137 3.56833 12.7103 3.24598V3.24598C12.7251 2.72916 12.5302 2.22834 12.1698 1.85761C11.8094 1.48688 11.3143 1.2778 10.7973 1.27802H9.54326C9.03672 1.27801 8.55107 1.47985 8.19376 1.83888C7.83644 2.19791 7.63693 2.68453 7.63937 3.19106V3.19106C7.62435 4.23686 6.77224 5.07675 5.72632 5.07664C5.40397 5.07329 5.08821 4.98488 4.81099 4.82035V4.82035C3.89582 4.29595 2.72887 4.61123 2.20229 5.52516L1.5341 6.62355C1.00817 7.53633 1.31916 8.70255 2.22975 9.23225V9.23225C2.82166 9.57398 3.18629 10.2055 3.18629 10.889C3.18629 11.5725 2.82166 12.204 2.22975 12.5457V12.5457C1.32031 13.0719 1.00898 14.2353 1.5341 15.1453V15.1453L2.16568 16.2345C2.4124 16.6797 2.82636 17.0082 3.31595 17.1474C3.80554 17.2865 4.3304 17.2248 4.77438 16.976V16.976C5.21084 16.7213 5.73094 16.6515 6.2191 16.7821C6.70725 16.9128 7.12299 17.233 7.37392 17.6716C7.53845 17.9488 7.62686 18.2646 7.63021 18.5869V18.5869C7.63021 19.6435 8.48671 20.5 9.54326 20.5H10.7973C11.8502 20.5 12.7053 19.6491 12.7103 18.5961V18.5961C12.7079 18.088 12.9086 17.6 13.2679 17.2407C13.6272 16.8814 14.1152 16.6806 14.6233 16.6831C14.9449 16.6917 15.2594 16.7797 15.5387 16.9393V16.9393C16.4515 17.4653 17.6177 17.1543 18.1474 16.2437V16.2437L18.8064 15.1453C19.0615 14.7074 19.1315 14.1859 19.001 13.6963C18.8704 13.2067 18.55 12.7893 18.1108 12.5366V12.5366C17.6715 12.2839 17.3511 11.8665 17.2206 11.3769C17.09 10.8872 17.16 10.3658 17.4151 9.9279C17.581 9.63827 17.8211 9.39814 18.1108 9.23225V9.23225C19.0159 8.70283 19.3262 7.54343 18.8064 6.63271V6.63271V6.62355Z"
        // stroke="#B8C0CC"
        stroke={props.iconcolor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="10.1747"
        cy="10.889"
        r="2.63616"
        // stroke="#B8C0CC"
        stroke={props.iconcolor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);
