import React, { useState } from "react";
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import moment from "moment";
import { useViewGet } from "../../services/Api/general";
import showToast from "../../components/Common/toastNotifications";
import SurveySection from "../../components/Survey/singleSurvey";
import { downloadCSV } from "../../components/Survey/surveyCSVgenerator";

interface SurveyAnalyticsData {
  surveyId: number;
  analytics: {
    optionId: number;
    optionText: string;
    answerCount: number;
    percentage: number;
  }[];
}
const SurveyPage: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(
    moment().add(-1, "months").toDate()
  ); // Set to today's date
  const [endDate, setEndDate] = useState<Date>(moment().toDate()); // Set to one month from today

  const searchParams = `from=${moment(startDate).format(
    "YYYY-MM-DD"
  )}&to=${moment(endDate).format("YYYY-MM-DD")}`;

  const {
    isLoading,
    data: SurveyAnalytics,
    refetch,
  } = useViewGet("SurveyAnalytics", "survey/getSurveyAnalytics", searchParams);

  const {
    isLoading: questionsLoading,
    data: SurveyQuestions,
  } = useViewGet("SurveyQuestions", "survey/getSurveyAnswers", "");

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;

    // Ensure that the end date is not before the start date
    if (end && start && end < start) {
      showToast("End date cannot be before start date", "error");
      return;
    }

    // Update your state with the new dates
    setStartDate(start);
    setEndDate(end);

    // Your logic to refetch the survey analytics, etc.
  };

  const handleDownloadCSV = () => {
    if (SurveyQuestions?.data) {
      downloadCSV(SurveyQuestions.data);
    } else {
      showToast('No data available to download', 'error');
    }
  };

  return (
    <div>
      <GenericRowComponent
        heading="Survey Management"
        showDateRange
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        onDateRangeChange={handleDateChange} // Pass the function here
      />
      <div className="mt-6 p-5 bg-[#FFFFFF1A] flex flex-col gap-y-6 rounded-lg">
        {SurveyAnalytics?.data.map((survey: SurveyAnalyticsData) => (
          <SurveySection
            surveyTitle={
              survey.surveyId === 1
                ? "App Satisfaction"
                : "Results Satisfaction"
            }
            title="Title"
            text={
              survey.surveyId === 1
                ? "How much you satisfied form application"
                : "How accurate the results were"
            }
            questions={survey.analytics}
          />
        ))}

        <div>
          <p className="font-Urbanist font-semibold text-left text-4xl leading-9 text-white">
            Questions
          </p>
          <div className="mt-4 px-4 py-6 bg-[#FFFFFF1A] rounded-lg">
            <div className="w-[585px] max-w-full min-w-auto bg-[#FFFFFF1A] p-3 rounded-lg">
              <p className="font-Urbanist text-[22px] font-semibold leading-custom text-white">
                Title
              </p>
              <p className="font-Urbanist text-base font-medium leading-6 text-white ">
                Did your recommendations help?
              </p>
            </div>
            <div className="mt-3">
              
              <div className="mt-2 flex flex-row justify-between gap-2">
                <button onClick={handleDownloadCSV} type="button" className="bg-[#B723F5] p-1.5 px-4 rounded-md font-Urbanist text-white font-medium">
                  Download CSV
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyPage;
