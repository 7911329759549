import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import "tailwindcss/tailwind.css";
import CelebFieldSection from "../../components/CelebManagmet/basicForm";
import GenericRowComponent from "../../components/Dashboard/genericHeaderRow";
import { useImageUpload, useView } from "../../services/Api/general";
import ImageUpload from "../../components/Common/ImageUpload";
import Cookies from "js-cookie";

const genderOptions = [
  { value: "female", label: "Female" },
  { value: "male", label: "Male" },
  { value: "other", label: "Other" },
];

const EditCelebrity = () => {
  const { id } = useParams(); // Get the celebrity ID from the URL
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  
  const { mutateAsync: uploadImage } = useImageUpload();

  const { isLoading, data: celebData } = useView("celebrity", id);
  useEffect(() => {
    if (celebData?.data) {
      const { data } = celebData;
      // Populate the form with fetched data
      setValue("name", data.name);
      setValue("display_picture", data.display_picture);
      setValue("profession", data.profession);
      setValue("min_score", data.min_score);
      setValue("max_score", data.max_score);
      setValue("gender",  genderOptions?.find((option) => option?.value === data.gender));

      // setPreviewUrl(data.display_picture);
    }
  }, [celebData]);

  const onSubmit = async (data: any) => {
    // setLoading(true);
    if (!data.display_picture) {
      setError("display_picture", {
        type: "manual",
        message: "Please select an image to upload.",
      });
      return;
    }
    try {
      const file = data.display_picture[0];

      const submissionData = {
        ...data,
        display_picture:
          typeof data.display_picture === "string"
            ? data.display_picture
            : data.display_picture[0]
            ? await uploadImage({
                file,
                route: "celebrity/getPreSignedUrl",
              })
            : data.display_picture,
        min_score: parseFloat(data?.min_score.toString()),
        max_score: parseFloat(data?.max_score.toString()),
        gender: data?.gender?.value
      };

      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/celebrity/${id}`,
        submissionData,{
          headers: {
            Authorization: `${Cookies.get("facebloom-dashboard")}`
          }
        }
      );
      navigate("/celebrities"); // Navigate back to the celebrities list
    } catch (error) {
      console.error("Failed to update celebrity", error);
    } finally {
      // setLoading(false);
    }
  };

  const displayPicture = watch("display_picture");

  return (
    <>
      <div className="p-4 bg-transparent">
        <GenericRowComponent heading="Edit Celebrities" showBackButton />

        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <div className="mb-4 mt-2">
            <ImageUpload
              setValue={setValue}
              watch={watch}
              name="display_picture"
              defaultImage={displayPicture}
              formEdit
            />
          </div>
          <CelebFieldSection
            register={register}
            errors={errors}
            control={control}
            genderOptions={genderOptions}
          />

          <button
            type="submit"
            className={`p-2 mt-6 text-black inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md ${
              isSubmitting ? "bg-blue-300" : ""
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
            disabled={isSubmitting}
            style={{
              background:
                "linear-gradient(268.95deg, #3FDFA1 5.53%, #FEE591 91.7%)",
            }}
          >
            {isSubmitting ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Processing...
              </>
            ) : (
              "Edit Celebrity"
            )}
          </button>
        </form>
      </div>
    </>
  );
};

export default EditCelebrity;
