import React from 'react';

type ProfileBlockHeadingValueProps = {
  value?: string;
  heading?: string;
};

const ProfileBlockHeadingValue: React.FC<ProfileBlockHeadingValueProps> = ({ value, heading }) => {
  return (
    <div className="min-w-[193px] max-w-[300px] px-4 py-2 rounded-lg bg-[#FFFFFF1A]">
      <p className="font-Urbanist text-[22px] font-semibold leading-custom text-white">
        {heading}
      </p>
      <p className="font-Urbanist text-base font-medium leading-6 text-white">
        {value}
      </p>
    </div>
  );
};

export default ProfileBlockHeadingValue;
