// import DataTable from "react-data-table-component";
import DataTable, { IDataTableProps } from "react-data-table-component";

interface RowData {
  [key: string]: any;
}
// Define types for CustomPagination props
interface CustomPaginationProps {
  rowsPerPage: number;
  rowCount: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  totalPages: number;
}

interface TableComponentProps extends Omit<IDataTableProps<RowData>, "data"> {
  data: RowData[]; // Original full dataset
  totalRows: number;
  currentPage: number;
  handlePageChange: (page: number) => void;
  progressPending: boolean;
  columns: any[];
}

const customStyles = {
  headRow: {
    style: {
      borderRadius: "10px",
      color: "#FFFFFF",
      fontWeight: "700",
      backgroundColor: "transparent",
    },
  },
  table: {
    style: {
      backgroundColor: "transparent",
      marginTop: "10px",
      marginBottom: "10px",
    },
  },
  rows: {
    style: {
      marginTop: "10px",
      marginBottom: "10px",
      border: "1px solid #9A999980 !important",
      borderRadius: "10px !important",
      padding: "0.75rem 0",
      backgroundColor: "#FFFFFF1A",
    },
  },
  cells: {
    style: {
      // backgroundColor: "#30384b",
      color: "#FFFFFF",
      fontFamily: "Urbanist",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "18px",
      letterSpacing: "0px",
      // text-align: left;
    },
  },
  pagination: {
    style: {
      backgroundColor: "#transparent !important",
      color: "#FFFFFF !important",
    },
    pageButtonsStyle: {
      color: "#FFFFFF !important",
    },
  },
};

const CustomPagination: React.FC<CustomPaginationProps> = ({
  rowsPerPage,
  rowCount,
  currentPage,
  onPageChange,
  totalPages,
}) => {
  const firstRow = rowsPerPage * (currentPage - 1) + 1;
  const lastRow = Math.min(rowsPerPage * currentPage, rowCount);


  // Calculate the range of pages to show
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    if (i <= 2 || i > totalPages - 2) {
      pageNumbers.push(i);
    } else if (i >= currentPage - 1 && i <= currentPage + 1) {
      pageNumbers.push(i);
    } else if (pageNumbers[pageNumbers.length - 1] !== "...") {
      pageNumbers.push("...");
    }
  }

  return (
    <div className="flex justify-between items-center mt-8">
      <p className="text-gray-400">
        Showing results {firstRow} to {lastRow} of {rowCount}
      </p>
      <div className="flex gap-2 items-center">
        {/* Previous Page Button */}
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`${
            currentPage === 1 ? "opacity-50" : "hover:bg-light-gray text-white"
          }  text-[#404B52] font-bold py-1.5 px-3 rounded`}
          style={{
            background:
              currentPage === 1
                ? "linear-gradient(0deg, #EEEEEE, #EEEEEE),linear-gradient(0deg, #F5F5F5, #F5F5F5)"
                : "linear-gradient(127.67deg, #73ACF9 -6.73%, #AD85F0 109.94%),linear-gradient(0deg, #7D7D7D, #7D7D7D)",
            border: "1px solid #EEEEEE",
          }}
        >
          &lt;
        </button>

        {pageNumbers.map((page: any) =>
          page === "..." ? (
            <span key={page} className="text-gray-400 mx-2">
              {page}
            </span>
          ) : (
            <button
              key={page}
              onClick={() => onPageChange(page)}
              className={`font-bold py-1.5 px-3 rounded ${
                currentPage === page
                  ? "text-white"
                  : "text-[#404B52] opacity-50"
              }`}
              style={{
                border: "1px solid #7D7D7D",
                background:
                  currentPage === page
                    ? "linear-gradient(127.67deg, #73ACF9 -6.73%, #AD85F0 109.94%),linear-gradient(0deg, #7D7D7D, #7D7D7D)"
                    : "linear-gradient(0deg, #EEEEEE, #EEEEEE),linear-gradient(0deg, #F5F5F5, #F5F5F5)",
              }}
            >
              {page}
            </button>
          )
        )}
        {/* Next Page Button */}
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`${
            currentPage === totalPages
              ? " text-[#404B52] opacity-50"
              : "text-white "
          } font-bold py-1.5 px-3 rounded`}
          style={{
            border: "1px solid #7D7D7D",
            background:
              currentPage !== totalPages
                ? "linear-gradient(127.67deg, #73ACF9 -6.73%, #AD85F0 109.94%),linear-gradient(0deg, #7D7D7D, #7D7D7D)"
                : "linear-gradient(0deg, #EEEEEE, #EEEEEE),linear-gradient(0deg, #F5F5F5, #F5F5F5)",
          }}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

// Define the TableComponent
const TableComponent: React.FC<TableComponentProps> = ({
  columns,
  data,
  totalRows,
  currentPage,
  progressPending,
  handlePageChange,
}) => {
  // Calculate total pages
  const rowsPerPage = 10;
  const totalPages = Math.ceil(totalRows / rowsPerPage);

 

  return (
    <DataTable
      columns={columns}
      customStyles={customStyles}
      data={data}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      paginationComponentOptions={{
        noRowsPerPage: true,
      }}
      progressPending={progressPending}
      progressComponent={
        <div className=" !bg-transparent w-full border-2 text-center border-[#FFFFFF1A] text-white shadow-sm mx-auto p-6">
          Loading...
        </div>
      }
      noDataComponent={
        <div className=" !bg-transparent w-full text-center border-2 border-[#FFFFFF1A] text-white shadow-sm mx-auto p-6">
          No data found
        </div>
      }
      onChangePage={handlePageChange}
      paginationComponent={() => (
        <CustomPagination
          rowsPerPage={rowsPerPage}
          rowCount={totalRows}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      )}
    />
  );
};

export default TableComponent;
