import React from "react";

interface ProfileViewProps {
  imageUrl?: string;
  name?: string;
  profession?: string;
  gender?: string;
  min_score?: number;
  max_score?: number;
  country?: string;
  email?: string;
  description?: string;
  totalScansForInfluencer?:string | number;
}

const ProfileView: React.FC<ProfileViewProps> = ({
  imageUrl,
  name,
  profession,
  gender,
  min_score,
  max_score,
  country,
  email,
  description,
  totalScansForInfluencer
}) => {
  const genderColor = gender === "male" ? "bg-[#34B0FF]" : "bg-[#FE4E80]";

  return (
    <div className="flex space-x-4 cursor-pointer">
      {imageUrl && (
        <div className="max-w-52">

        <img
          src={imageUrl}
          alt="Profile"
          className="h-auto w-full object-cover rounded-2xl"
          style={{ border: "0.5px solid #D9D9D9" }}
        />
        </div>
      )}
      <div className="flex  flex-col">
        <div className="flex flex-row justify-start mt-3 gap-x-4">
          <div className=" min-w-48">
            {name && (
              <h1 className="text-white text-4xl font-Urbanist  max-w-[350px] truncate font-semibold leading-tight">
                {name}
              </h1>
            )}
            {profession && (
              <span className="text-white text-xl font-Urbanist font-normal mt-1">
                {profession}
              </span>
            )}
            {email && (
              <div className="flex flex-row justify-start items-center mt-1 gap-3">
                <img src="/assets/images/basicIcons/enveloper.svg" alt="mail" />
                <span className="text-white text-xl font-Urbanist font-normal">
                  {email}
                </span>
              </div>
            )}
            {country && (
              <div className="text-white font-Urbanist font-normal mt-1">
                {country}
              </div>
            )}
            {description && (
              <div className="text-white font-Urbanist font-normal mt-4 max-w-96">
                <p className="font-medium text-lg">About me</p>
                <p className=" font-light opacity-70 max-h-[150px] w-full overflow-auto scroll_bar text-clip">{description}</p>
              </div>
            )}
          </div>
          <div>
            {totalScansForInfluencer && (
              <span
                className={`inline-flex px-5 py-2 mt-2 text-white text-center text-md rounded-lg items-center flex-row bg-[#FFFFFF1A]`}
              >
               <img
                    src="/assets/images/basicIcons/scan_icon.svg"
                    className=" w-[20px] h-auto mr-2"
                  /> : &nbsp;

                {totalScansForInfluencer}
              </span>
            )}
            {gender && (
              <span
                className={`inline-block px-5 py-2 mt-2 text-white text-sm rounded-full ${genderColor}`}
              >
                {gender.charAt(0).toUpperCase() + gender.slice(1)}
              </span>
            )}
            {min_score && max_score && (
              <div className="text-white mt-1 font-Urbanist font-semibold">
                <span>
                  ⭐ {min_score.toFixed(1)} - {max_score.toFixed(1)}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileView;
